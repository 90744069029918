import React, { useEffect } from "react";
import { compose } from "redux";
import Helmet from "react-helmet";
import { connect, useSelector } from "react-redux";
import { Typography, Button, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";

import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportMemberUsers, clear } from "../../actions";
import Paper from "../Paper";
import { formatFilters, removeColumns, formatPhoneNumber } from "../../utility";
import {
  Actions,
  ActionGroup,
  FilterMenu,
  ButtonGroup,
  Col,
  Cols,
} from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import { memberAffiliationsSelectors } from "features/Taxonomies/taxonomiesSlice";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const formatCommaEscapedStringIntoArray = (arr) => {
  try {
    return arr.match(/(?:\\,|[^,])+/g).map((item) => item.replace("\\,", ","));
  } catch {
    return [];
  }
};

const formatUsersCustomers = (names, paths) => {
  return formatCommaEscapedStringIntoArray(names).map((name, index) =>
    name ? (
      <div>
        <LinkHeader to={paths[index]} size="small" color="default">
          {name}
        </LinkHeader>
      </div>
    ) : null
  );
};

const columnSettings = {
  first_name: { minWidth: 200 },
  last_name: { minWidth: 200 },
  title_position: { minWidth: 400 },
  name: { minWidth: 200 },
  role_names: { minWidth: 200 },
  address_line1: { minWidth: 200, label: "Street" },
  address_line2: { minWidth: 200, label: "Street 2" },
  city: { minWidth: 200 },
  state: { minWidth: 50 },
  postal_code: { minWidth: 100, label: "Zip" },
  phone: { minWidth: 200 },
  cell_phone: { minWidth: 200 },
  mail: { minWidth: 200 },
  member_names: { minWidth: 200 },
  member_name: { minWidth: 300 },
  office_ids: { minWidth: 50, label: "Office IDs" },
  member_affiliations: { minWidth: 200 },
  member_for: { minWidth: 200 },
  created: { minWidth: 200 },
  changed: { minWidth: 200 },
  member_last_updated: { minWidth: 200 },
  member_last_updated_by_names: { minWidth: 200 },
  access: { minWidth: 200 },
  status_proper: { minWidth: 100 },
  user_creator: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  first_name,
  last_name,
  title_position,
  name,
  role_names,
  address_line1,
  address_line2,
  city,
  state,
  postal_code,
  phone,
  cell_phone,
  mail,
  member_name,
  member_names,
  office_ids,
  member_affiliations,
  member_for,
  created,
  changed,
  member_last_updated,
  member_last_updated_by_names,
  access,
  status_proper,
  user_creator,
  paths
) => {
  return {
    first_name,
    last_name,
    title_position,
    name: name ? (
      <LinkHeader to={paths.user} size="small" color="default">
        {name}
      </LinkHeader>
    ) : null,
    role_names,
    address_line1,
    address_line2,
    city,
    state,
    postal_code,
    phone: formatPhoneNumber(phone),
    cell_phone: formatPhoneNumber(cell_phone),
    mail,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    member_names,
    member_for,
    office_ids,
    member_affiliations,
    created: created ? (
      <div>
        <Typography variant="body1">
          {moment.unix(created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(created).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    changed: changed ? (
      <div>
        <Typography variant="body1">
          {moment.unix(changed).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(changed).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    member_last_updated,
    member_last_updated_by_names,
    access: access ? (
      <div>
        <Typography variant="body1">
          {moment.unix(access).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(access).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    status_proper,
    user_creator: user_creator ? (
      <LinkHeader to={paths.user_creator} size="small" color="default">
        {user_creator}
      </LinkHeader>
    ) : null,
    collapsible: (
      <Cols>
        <Col>
          <Typography variant="subtitle1">Members</Typography>
          {formatUsersCustomers(member_names, paths.members)}
        </Col>
        <Col>
          <Typography variant="subtitle1">Office IDs</Typography>
          {formatCommaEscapedStringIntoArray(office_ids).map((id) => (
            <Typography variant="body1">{id}</Typography>
          ))}
        </Col>
      </Cols>
    ),
  };
};

const initialFormData = {
  status_proper: null,
  member_affiliation_tids: [],
};

const ReportMemberUsers = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const affiliations = useSelector(memberAffiliationsSelectors.selectAll);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nids]": org,
      };
    }

    await exportReport("/rest/report/member/users", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(
      [
        "office_ids",
        "member_names",
        "member_last_updated",
        "member_last_updated_by_names",
      ],
      temp
    );
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.first_name,
        row.last_name,
        row.title_position,
        row.name,
        row.role_names,
        row.address_line1,
        row.address_line2,
        row.city,
        row.state,
        row.postal_code,
        row.phone,
        row.cell_phone,
        row.mail,
        row.member_name,
        row.member_names,
        row.office_ids,
        row.member_affiliations,
        row.member_for,
        row.created,
        row.changed,
        row.member_last_updated,
        row.member_last_updated_by_names,
        row.access,
        row.status_proper,
        row.user_creator,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Member Users Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Member Users</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={[
                    { label: "Yes", value: "active" },
                    { label: "No", value: "blocked" },
                  ]}
                  fullWidth
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Active"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status_proper");
                  }}
                  value={formData.status_proper}
                />
                <Autocomplete
                  options={affiliations.map((affiliation) => ({
                    label: affiliation.name,
                    value: affiliation.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Affiliations"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "member_affiliation_tids");
                  }}
                  value={formData.member_affiliation_tids}
                />
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          clientFilter={"phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="access"
          originalOrder="desc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportMemberUsers.propTypes = {};

ReportMemberUsers.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  affiliations: [],
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  affiliations: state.app.affiliations.data,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportMemberUsers(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportMemberUsers);
