import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import {
  Typography,
  IconButton,
  Popover,
  Tooltip,
  ListItemIcon,
  MenuItem,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import Role from "../Role";
import ConfirmationDialog from "../ConfirmationDialog";
import Alert from "common/Alert";
import Table from "../Table";
import Status from "../Status";
import MemberComplianceAdd from "./MemberComplianceAdd";
import MemberComplianceEdit from "./MemberComplianceEdit";
import { memberComplianceColumns } from "../../schemas/tables/compliance";
import {
  getMemberCompliance,
  liftComplianceActivity,
  deleteComplianceActivity,
  getChildMembers,
} from "../../actions";
import {
  formatError,
  formatErrorResponseString,
} from "../../utility";
import { CellHeading } from "../Table/Styled";
import { Toolbar } from "./Styled";
import { memberStatusSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import TimeDisplayCell from "components/TimeDisplayCell";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const getStatusLabel = (statuses, status) => {
  const statusObj = statuses.find((s) => s.machine_name === status);
  if (statusObj && statusObj.label) {
    return statusObj.label;
  } else {
    return status;
  }
};

function createData(
  date,
  reason,
  method,
  liftDate,
  status,
  description,
  statuses,
  actions
) {
  return {
    start_date: <TimeDisplayCell time={date} dateOnly offSet />,
    compliance_change_reason: <CellHeading>{reason}</CellHeading>,
    lift_method: <CellHeading>{method}</CellHeading>,
    lift_date: (
      <div>
        {liftDate ? (
          <TimeDisplayCell time={liftDate} dateOnly offSet />
        ) : (
          <CellHeading>No Date</CellHeading>
        )}
      </div>
    ),
    compliance_status: (
      <Status type={status} indicator>
        {getStatusLabel(statuses, status)}
      </Status>
    ),
    description: <Typography variant="body1">{description}</Typography>,
    actions,
  };
}

const Actions = ({
  nid,
  onEdit,
  openConfirmation,
  setActivityNid,
  openLiftConfirmation,
  liftDate,
  roles,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Role
      userRoles={roles}
      intendedRoles={["admin", "phx_sub_admin", "compliance_admin"]}
    >
      <>
        <Tooltip title="Actions">
          <IconButton
            aria-label="Row Actions"
            onClick={handleClick}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => onEdit(nid)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </MenuItem>
          {!liftDate && (
            <>
              <MenuItem
                onClick={() => {
                  openLiftConfirmation(true);
                  setActivityNid(nid);
                }}
              >
                <ListItemIcon>
                  <AssignmentTurnedInIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Lift</Typography>
              </MenuItem>
            </>
          )}
          <MenuItem
            onClick={() => {
              openConfirmation(true);
              setActivityNid(nid);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
        </Popover>
      </>
    </Role>
  );
};

const MemberCompliance = ({
  nid,
  loadMemberCompliance,
  memberLoading,
  memberName,
  loadingCompliance,
  compliances,
  error,
  pagination,
  removeComplianceActivity,
  showSnackbar,
  lift,
  roles,
  memberDivisionTypes,
  childMembers,
  loadingChildMembers,
  loadChildMembers,
  refreshMember,
}) => {
  const classes = useStyles();
  const memberStatus = useSelector(memberStatusSelectors.selectAll);
  const [openEdit, setOpenEdit] = React.useState(null);
  const [openAdd, setOpenAdd] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openLift, setOpenLift] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [activityNid, setActivityNid] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  useEffect(() => {
    if (nid) {
      loadChildMembers(nid);
    }
  }, [nid, loadChildMembers]);

  const rows = React.useMemo(() => {
    return compliances.map((row) =>
      createData(
        row.start_date,
        row.compliance_change_reason,
        row.lift_method,
        row.lift_date,
        row.compliance_status,
        row.description,
        memberStatus,
        <Actions
          nid={row.compliance_nid}
          onEdit={setOpenEdit}
          openConfirmation={setOpenDialog}
          setActivityNid={setActivityNid}
          openLiftConfirmation={setOpenLift}
          liftDate={row.lift_date}
          roles={roles}
        />
      )
    );
  }, [compliances, memberStatus, roles]);

  const handleDelete = async () => {
    setIsSubmitting(true);
    if (!activityNid) return;
    const response = await removeComplianceActivity(activityNid);
    if (response.status === 204) {
      await loadMemberCompliance(nid);
      showSnackbar({
        msg: "Successfully removed compliance activity.",
        kind: "positive",
      });
      setOpenDialog(false);
      setActivityNid(null);
    } else {
      setIsSubmitting(false);
      const errorMessage = formatError(response);
      const newMessage = formatErrorResponseString(errorMessage.msg);
      errorMessage.msg = newMessage;
      setMessage(errorMessage);
    }
  };

  const handleLift = async () => {
    setIsSubmitting(true);
    if (!activityNid) return;
    const response = await lift(activityNid);
    if (response.status === 200) {
      await loadMemberCompliance(nid);
      setIsSubmitting(false);
      showSnackbar({
        msg: "Successfully lifted compliance status.",
        kind: "positive",
      });
      setOpenLift(false);
      setActivityNid(null);
      refreshMember();
    } else {
      setIsSubmitting(false);
      setActivityNid(null);
      const errorMessage = formatError(response);
      const newMessage = formatErrorResponseString(errorMessage.msg);
      errorMessage.msg = newMessage;
      setMessage(errorMessage);
    }
  };

  return (
    <>
      <Role
        userRoles={roles}
        intendedRoles={["admin", "phx_sub_admin", "compliance_admin"]}
      >
        <Toolbar style={{ padding: "1.25rem" }}>
          <Button
            onClick={() => setOpenAdd(true)}
            size="small"
            startIcon={<Add />}
            className={classes.button}
          >
            Add
          </Button>
        </Toolbar>
      </Role>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <Table
        columns={memberComplianceColumns}
        rows={rows}
        stickyHeader
        param={nid}
        hasParam={true}
        originalOrderBy="start_date"
        pageRequest={loadMemberCompliance}
        loading={loadingCompliance || memberLoading}
        pagination={pagination}
      />
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this item?"
        isSubmitting={isSubmitting}
        message={message}
        submitButton="Delete"
      />
      <ConfirmationDialog
        open={openLift}
        onClose={() => setOpenLift(false)}
        onSubmit={handleLift}
        title="Are you sure?"
        body="Are you sure you want to lift this compliance status?"
        isSubmitting={isSubmitting}
        message={message}
        submitButton="Lift"
      />
      <MemberComplianceEdit
        open={Boolean(openEdit)}
        onClose={() => setOpenEdit(null)}
        nid={openEdit}
        mnid={nid}
        loadMemberCompliance={() => loadMemberCompliance(nid)}
        showSnackbar={showSnackbar}
        refreshMember={refreshMember}
      />
      <MemberComplianceAdd
        open={Boolean(openAdd)}
        onClose={() => setOpenAdd(null)}
        mnid={nid}
        childMembers={childMembers}
        memberName={memberName}
        loadMemberCompliance={loadMemberCompliance}
        showSnackbar={showSnackbar}
        memberDivisionTypes={memberDivisionTypes}
        refreshMember={refreshMember}
      />
    </>
  );
};

MemberCompliance.propTypes = {};

MemberCompliance.defaultProps = {
  compliances: [],
  pagination: { count: 0, current_page: 0, total_pages: 0 },
  memberDivisionTypes: [],
};

const mapStateToProps = (state) => ({
  loadingCompliance: state.app.memberCompliance.loading,
  error: state.app.memberCompliance.error,
  compliances: state.app.memberCompliance.data.data,
  pagination: state.app.memberCompliance.data.pagination,
  loadingChildMembers: state.app.childMembers.loading,
  childMembers: state.app.childMembers.data.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadMemberCompliance: (nid, params) =>
    dispatch(getMemberCompliance(nid, params)),
  lift: (nid) => dispatch(liftComplianceActivity(nid)),
  removeComplianceActivity: (nid) => dispatch(deleteComplianceActivity(nid)),
  loadChildMembers: (nid, params) => dispatch(getChildMembers(nid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberCompliance);
