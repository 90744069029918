import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { Container, Success, Negative } from "./Styled";

const icons = {
  pdf: `${process.env.PUBLIC_URL}/images/icons/icon-pdf.svg`,
  doc: `${process.env.PUBLIC_URL}/images/icons/icon-doc.svg`,
  png: `${process.env.PUBLIC_URL}/images/icons/icon-png.svg`,
  xls: `${process.env.PUBLIC_URL}/images/icons/icon-xls.svg`,
  zip: `${process.env.PUBLIC_URL}/images/icons/icon-zip.svg`,
  webloc: `${process.env.PUBLIC_URL}/images/icons/icon-webloc.svg`,
  esx: `${process.env.PUBLIC_URL}/images/icons/icon-esx.svg`,
  mp4: `${process.env.PUBLIC_URL}/images/icons/icon-mp4.svg`,
  photo: `${process.env.PUBLIC_URL}/images/icons/icon-photo.svg`,
  default: `${process.env.PUBLIC_URL}/images/icons/icon-file--default.svg`,
};

const getIconUrl = (filename) => {
  if (!filename) return "";
  const ext = filename.split(".").pop().toLowerCase();
  let icon = "";
  switch (ext) {
    case "pdf":
      icon = icons.pdf;
      break;
    case "doc":
    case "docx":
      icon = icons.doc;
      break;
    case "xls":
    case "xlsx":
      icon = icons.xls;
      break;
    case "zip":
      icon = icons.zip;
      break;
    // case "png":
    //   icon = icons.png;
    //   break;
    case "webloc":
      icon = icons.webloc;
      break;
    case "esx":
      icon = icons.esx;
      break;
    case "mp4":
      icon = icons.mp4;
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "heic":
      icon = icons.photo;
      break;
    default:
      icon = icons.default;
  }

  return icon;
};

const FileIcon = ({ filename, status, size }) => {
  var _size = size === "small" ? "24px" : "41px";
  return (
    <Container theme={{ width: _size,  height: _size }}>
      {status === 2 && (
        <Success>
          <CheckCircleOutlineIcon color="inherit" />
        </Success>
      )}
      {status === 0 && (
        <Negative>
          <ErrorOutlineIcon color="inherit" />
        </Negative>
      )}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "var(--color-gray-dark)",
          mask: `url(${getIconUrl(filename)}) no-repeat center`,
          maskSize: "contain",
         }}
      ></div>
    </Container>
  );
};

FileIcon.propTypes = {};

FileIcon.defaultProps = {
  size: "default",
};

export default FileIcon;
