import React, { useEffect } from "react";
import { compose } from "redux";
import Helmet from "react-helmet";
import { useSelector, connect } from "react-redux";
import { Typography, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import { includes, isArray, size } from "lodash";
import Button from "common/Button";
import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import {
  exportBatch,
  getReportJobDivisions,
  clear,
  getMembers,
  getCustomers,
} from "../../actions";
import Paper from "../Paper";
import {
  formatFilters,
  removeColumns,
  hasPermission,
  getUtcOffset,
  formatMoney,
} from "../../utility";
import {
  Actions,
  ActionGroup,
  FilterMenu,
  ButtonGroup,
  Col,
  Cols,
} from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import { BarChart } from "../Visualizations";
import constants from "../constants";
import { useDebounce } from "../../hooks";
import {
  countiesSelectors,
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
  lossCategoriesSelectors,
  statesSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import { parseJobSource } from "features/Job/utils";

const offSet = getUtcOffset(); // Remember, do not use this for times, (date only)

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const getCountiesByState = (states, counties) => {
  if (states && isArray(states) && size(states) > 0) {
    return counties.filter((county) => {
      const stateCodes = states.map((state) => state.value);
      return includes(stateCodes, county.state_code);
    });
  } else {
    return counties;
  }
};

const columnSettings = {
  member_ufoc: { minWidth: 150 },
  title: { minWidth: 150 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 300 },
  customer_location_address: { minWidth: 300 },
  customer_location_state: { minWidth: 100 },
  customer_location_county: { minWidth: 100 },
  member_name: { minWidth: 300 },
  last_updated: { minWidth: 150 },
  status_proper: { minWidth: 200 },
  job_source_proper: { minWidth: 150 },
  on_hold: { minWidth: 125 },
  category_proper: { minWidth: 75 },
  job_division_type: { minWidth: 150 },
  date_received: { minWidth: 200 },
  start_date: { minWidth: 150 },
  completion_date: { minWidth: 200 },
  sources_of_loss: { minWidth: 100 },
  reported_by: { minWidth: 220 },
  phx_client_rep_name: { minWidth: 200 },
  pm_name: { minWidth: 200 },
  estimator_name: { minWidth: 200 },
  est_arrival: { minWidth: 150 },
  nte_total: { minWidth: 100 },
  reference_num: { minWidth: 250 },
  assigned_user_names: { minWidth: 200 },
  last_updated_by_name: { minWidth: 200 },
  customer_parent_name: { minWidth: 300 },
  created: { minWidth: 200 },
  followup_date: { minWidth: 200 },
  caller_info: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    if (column.index === "reported_by_name") {
      column.index = "reported_by";
    }
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  member_ufoc,
  title,
  customer_name,
  customer_location_name,
  customer_location_address,
  customer_location_state,
  customer_location_county,
  member_name,
  last_updated,
  status_proper,
  job_source_proper,
  on_hold,
  category_proper,
  job_division_type,
  created,
  date_received,
  start_date,
  completion_date,
  sources_of_loss,
  reported_by,
  phx_client_rep_name,
  pm_name,
  estimator_name,
  est_arrival,
  nte_total,
  reference_num,
  assigned_user_names,
  last_updated_by_name,
  customer_parent_name,
  followup_date,
  caller_info,
  paths
) => {
  return {
    member_ufoc,
    title: title ? (
      <LinkHeader to={paths.division} size="small" color="default">
        {title}
      </LinkHeader>
    ) : null,
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    customer_location_address,
    customer_location_state,
    customer_location_county,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    last_updated: (
      <div>
        <Typography variant="body1">
          {moment
            .unix(last_updated) /*.utcOffset(offSet)*/
            .format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment
            .unix(last_updated) /*.utcOffset(offSet)*/
            .format("h:mm A")}
        </Typography>
      </div>
    ),
    status_proper,
    job_source_proper,
    on_hold,
    category_proper,
    job_division_type,
    created: (
      <Typography variant="body1">
        {created
          ? moment.unix(created).utcOffset(offSet).format("MMM Do, YYYY")
          : null}
      </Typography>
    ),
    date_received: (
      <Typography variant="body1">
        {date_received
          ? moment.unix(date_received).utcOffset(offSet).format("MMM Do, YYYY")
          : null}
      </Typography>
    ),
    start_date: (
      <Typography variant="body1">
        {start_date
          ? moment.unix(start_date).utcOffset(offSet).format("MMM Do, YYYY")
          : null}
      </Typography>
    ),
    completion_date: (
      <Typography variant="body1">
        {completion_date
          ? moment
              .unix(completion_date)
              .utcOffset(offSet)
              .format("MMM Do, YYYY")
          : null}
      </Typography>
    ),
    sources_of_loss,
    reported_by,
    phx_client_rep_name: phx_client_rep_name ? (
      <LinkHeader to={paths.phx_client_rep} size="small" color="default">
        {phx_client_rep_name}
      </LinkHeader>
    ) : null,
    pm_name: pm_name ? (
      <LinkHeader to={paths.coordinator} size="small" color="default">
        {pm_name}
      </LinkHeader>
    ) : null,
    estimator_name: estimator_name ? (
      <LinkHeader to={paths.estimator} size="small" color="default">
        {estimator_name}
      </LinkHeader>
    ) : null,
    est_arrival: est_arrival ? (
      <div>
        <Typography variant="body1">
          {moment
            .unix(est_arrival) /*.utcOffset(offSet)*/
            .format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment
            .unix(est_arrival) /*.utcOffset(offSet)*/
            .format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    nte_total: nte_total ? formatMoney(nte_total) : null,
    reference_num,
    assigned_user_names,
    last_updated_by_name: last_updated_by_name ? (
      <LinkHeader to={paths.last_updated_by} size="small" color="default">
        {last_updated_by_name}
      </LinkHeader>
    ) : null,
    customer_parent_name: customer_parent_name ? (
      <LinkHeader to={paths.customer_parent} size="small" color="default">
        {customer_parent_name}
      </LinkHeader>
    ) : null,
    followup_date: followup_date ? (
      <div>
        <Typography variant="body1">
          {moment(followup_date) /*.utcOffset(offSet)*/
            .format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment(followup_date) /*.utcOffset(offSet)*/
            .format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    caller_info,
    collapsible: (
      <Cols>
        <Col>
          <Typography variant="subtitle1">Assigned Users</Typography>
          {assigned_user_names && typeof assigned_user_names === "string"
            ? assigned_user_names.split(",").map((name, index) => (
                <Typography key={index} variant="body1">
                  {name}
                </Typography>
              ))
            : "No Value"}
        </Col>
      </Cols>
    ),
  };
};

const getTotals = (meta) => {
  const data = {
    data: [],
    labels: [],
  };
  try {
    const totalsObject = meta.grouped_totals[0].totals;

    for (let key in totalsObject) {
      if (totalsObject[key] !== "0") {
        data.data.push(totalsObject[key]);
        data.labels.push(key);
      }
    }

    return data;
  } catch (e) {
    return data;
  }
};

const initialFormData = {
  customer_location_state: [],
  customer_location_county_tid: [],
  status_tid: [],
  job_source: [],
  job_division_type_tid: [],
  category: [],
  source_of_loss_tid: [],
  date_received: {
    type: "date-range",
    value: { from: null, through: null },
  },
  created: {
    type: "date-range",
    value: { from: null, through: null },
  },
  start_date: { value: null },
  completion_date: { value: null },
  omit_status: { type: "omit", label: "status_tid", value: [] },
  member_nid: { type: "omit", label: "member_nid", value: [] },
  customer_nid: { type: "omit", label: "customer_nid", value: [] },
};

const ReportJobDivisions = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  cancelSource,
  getFile,
  roles,
  meta,
  members,
  loadingMembers,
  loadMembers,
  memberError,
  customerError,
  loadCustomers,
  loadingCustomers,
  customers,
  org,
}) => {
  const classes = useStyles();
  const counties = useSelector(countiesSelectors.selectAll);
  const states = useSelector(statesSelectors.selectAll);
  const categories = useSelector(lossCategoriesSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const divisions = useSelector(jobDivisionTypesSelectors.selectAll);
  const [state, setState] = React.useState(null);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const [memberOptions, setMemberOptions] = React.useState([]);
  const [memberQuery, setMemberQuery] = React.useState("");
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [customerQuery, setCustomerQuery] = React.useState("");
  const debouncedCustomer = useDebounce(customerQuery, 250);
  const debouncedMember = useDebounce(memberQuery, 250);
  const _jobSourceOptions =  useSelector(
    (state) => state.auth.user.data._processed.job_source_options
  );
  let jobSourceOptions = parseJobSource(_jobSourceOptions, null, true);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  React.useEffect(() => {
    if (debouncedMember) {
      loadMembers({
        keywords: debouncedMember,
      });
    }
  }, [debouncedMember, loadMembers]);

  React.useEffect(() => {
    if (!memberError) {
      const u = members.map((obj) => ({
        label: obj.member_name,
        value: obj.member_nid,
      }));
      setMemberOptions([...u]);
    }
  }, [memberError, members]);

  React.useEffect(() => {
    if (debouncedCustomer) {
      loadCustomers({
        keywords: debouncedCustomer,
      });
    }
  }, [debouncedCustomer, loadCustomers]);

  React.useEffect(() => {
    if (!customerError) {
      const u = customers.map((obj) => ({
        label: obj.customer_name,
        value: obj.customer_nid,
      }));
      setCustomerOptions([...u]);
    }
  }, [customerError, customers]);

  const handleMemberInputChange = (e) => {
    if (e) {
      setMemberQuery(e.target.value);
    }
  };

  const handleCustomerInputChange = (e) => {
    if (e) {
      setCustomerQuery(e.target.value);
    }
  };

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/job-divisions", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(["assigned_user_names"], temp);
    if(jobSourceOptions.length < 2) {
      removeColumns(["job_source_proper"], temp);
    }
    return temp;
  }, [pagination, loading, jobSourceOptions]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.member_ufoc,
        row.title,
        row.customer_name,
        row.customer_location_name,
        row.customer_location_address,
        row.customer_location_state,
        row.customer_location_county,
        row.member_name,
        row.last_updated,
        row.status_proper,
        row.job_source_proper,
        row.on_hold,
        row.category_proper,
        row.job_division_type,
        row.created,
        row.date_received,
        row.start_date,
        row.completion_date,
        row.sources_of_loss,
        row.reported_by,
        row.phx_client_rep_name,
        row.pm_name,
        row.estimator_name,
        row.est_arrival,
        row.nte_total,
        row.reference_number,
        row.assigned_user_names,
        row.last_updated_by_name,
        row.customer_parent_name,
        row.followup_date,
        row.caller_info,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    if (name === "customer_location_state") {
      if (value) {
        setState(value);
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleExcludeChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: { ...formData[name], value },
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      date_received: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  const totals = getTotals(meta);

  return (
    <div>
      <Helmet>
        <title>Job Divisions Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Job Divisions</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "customer_location_state");
                  }}
                  value={formData.customer_location_state}
                />
                <Autocomplete
                  options={getCountiesByState(state, counties).map(
                    (county) => ({
                      label: county.name,
                      value: county.tid,
                    })
                  )}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="County"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "customer_location_county_tid");
                  }}
                  value={formData.customer_location_county_tid}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.name,
                    value: status.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status_tid");
                  }}
                  value={formData.status_tid}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.name,
                    value: status.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exclude Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleExcludeChange(value, "omit_status");
                  }}
                  value={formData.omit_status.value}
                />
                <Autocomplete
                  options={divisions.map((division) => ({
                    label: division.name,
                    value: division.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "job_division_type_tid");
                  }}
                  value={formData.job_division_type_tid}
                />
                {jobSourceOptions.length > 1 &&
                  <Autocomplete
                    options={jobSourceOptions}
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    size="small"
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job Source"
                        variant="outlined"
                        margin="normal"
                        size="small"
                      />
                    )}
                    onChange={(e, value) => {
                      handleChange(value, "job_source");
                    }}
                    value={formData.job_source}
                  />
                }
                <Autocomplete
                  options={categories
                    .filter((cat) => {
                      return hasPermission(roles, [
                        "phx_client_admin",
                        "operations",
                        "accounting",
                        "compliance_admin",
                      ]) && cat.machine_name === "d2d"
                        ? false
                        : true;
                    })
                    .map((category) => ({
                      label: category.label,
                      value: category.machine_name,
                    }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  groupBy={(option) => option.category}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "category");
                  }}
                  value={formData.category}
                />
                <Autocomplete
                  onInputChange={handleMemberInputChange}
                  getOptionLabel={(option) => option.label}
                  size="small"
                  options={memberOptions}
                  loading={loadingMembers}
                  onChange={(e, value) => {
                    handleExcludeChange(value, "member_nid");
                  }}
                  value={formData.member_nid.value}
                  multiple
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exclude Member"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                />
                <Autocomplete
                  onInputChange={handleCustomerInputChange}
                  getOptionLabel={(option) => option.label}
                  size="small"
                  options={customerOptions}
                  loading={loadingCustomers}
                  onChange={(e, value) => {
                    handleExcludeChange(value, "customer_nid");
                  }}
                  value={formData.customer_nid.value}
                  multiple
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exclude Customer"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Received
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.date_received.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "date_received", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.date_received.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "date_received",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "created", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(value, "created", "through")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={formData.start_date.value}
                    onChange={(value) =>
                      handleChange(
                        {
                          value: value
                            ? moment(value)
                            : null,
                        },
                        "start_date"
                      )
                    }
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    label="Start Date"
                    margin="normal"
                    inputVariant="outlined"
                    size="small"
                    KeyboardButtonProps={{
                      "aria-label": "change start date",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={formData.completion_date.value}
                    onChange={(value) =>
                      handleChange(
                        {
                          value: value
                            ? moment(value)
                            : null,
                        },
                        "completion_date"
                      )
                    }
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    label="Completion Date"
                    margin="normal"
                    inputVariant="outlined"
                    size="small"
                    KeyboardButtonProps={{
                      "aria-label": "change completion date",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <div
          style={{
            height: "200px",
            padding: "1.25rem",
            margin: "0 1.25rem 1.25rem 1.25rem",
            background: constants.colorGrayLighter,
            borderRadius: constants.borderRadius,
          }}
        >
          <BarChart chartData={totals} showLegend={false} loading={loading} />
        </div>
        <CollapsibleTable
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="last_updated"
          originalOrder="desc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
          source={cancelSource}
        />
      </Paper>
    </div>
  );
};

ReportJobDivisions.propTypes = {};

ReportJobDivisions.defaultProps = {
  report: [],
  members: [],
  customers: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  meta: {
    grouped_totals: [{ label: "", totals: {} }],
  },
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  cancelSource: state.app.report.cancel,
  meta: state.app.report.data.meta,
  members: state.app.members.data.data,
  loadingMembers: state.app.members.loading,
  memberError: state.app.members.error,
  customers: state.app.customers.data.data,
  loadingCustomers: state.app.customers.loading,
  customerError: state.app.customers.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadMembers: (params) => dispatch(getMembers(params)),
  loadCustomers: (params) => dispatch(getCustomers(params)),
  loadReport: (params) => dispatch(getReportJobDivisions(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportJobDivisions);
