import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { useDebounce } from "hooks";
import Helmet from "react-helmet";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  Typography,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { ArrowForward } from "@mui/icons-material";

import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportWipDetail, clear } from "../../actions";
import Paper from "../Paper";
import {
  formatFilters,
  removeColumns,
  formatMoney,
  removeCurrencyFormatting,
} from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import {
  fetchCustomers,
  getAutocompleteCustomersSelector,
  getAutocompleteCustomersLoadingSelector,
  getAutocompleteCustomersErrorSelector,
} from "features/Autocomplete/autocompleteSlice";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  reference_number: { minWidth: 200 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 300 },
  member_name: { minWidth: 300 },
  title: { minWidth: 200 },
  job_division_type: { minWidth: 200 },
  created: { minWidth: 200 },
  completion_date: { minWidth: 200 },
  start_date: { minWidth: 200 },
  status_proper: { minWidth: 200 },
  nte_total: { minWidth: 150 },
  estimate: { minWidth: 100 },
  billed: { minWidth: 100 },
  total_job_amount: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  reference_number,
  customer_name,
  customer_location_name,
  member_name,
  title,
  job_division_type,
  created,
  completion_date,
  start_date,
  status_proper,
  nte_total,
  estimate,
  billed,
  total_job_amount,
  paths
) => {
  return {
    reference_number,
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    title: title ? (
      <LinkHeader to={paths.division} size="small" color="default">
        {title}
      </LinkHeader>
    ) : null,
    job_division_type,
    created: created ? (
      <div>
        <Typography variant="body1">
          {moment.unix(created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(created).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    completion_date: completion_date ? (
      <div>
        <Typography variant="body1">
          {moment.unix(completion_date).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(completion_date).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    start_date: start_date ? (
      <div>
        <Typography variant="body1">
          {moment.unix(start_date).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(start_date).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    status_proper,
    nte_total: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(nte_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(nte_total)}
      </Typography>
    ),
    estimate: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(estimate) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(estimate)}
      </Typography>
    ),
    billed: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(billed) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(billed)}
      </Typography>
    ),
    total_job_amount: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(total_job_amount) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(total_job_amount)}
      </Typography>
    ),
  };
};

const initialFormData = {
  status: [],
  job_division_type_tid: [],
  customer_nid: [],
  created: {
    type: "date-range",
    value: {
      from: "",
      through: "",
    },
  },
};

const ReportWipDetail = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const divisions = useSelector(jobDivisionTypesSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  const allCustomers = useSelector(getAutocompleteCustomersSelector);
  const customerLoading = useSelector(getAutocompleteCustomersLoadingSelector);
  const customerError = useSelector(getAutocompleteCustomersErrorSelector);
  const [options, setOptions] = useState([]);
  const [customerQuery, setCustomerQuery] = useState("");
  const debouncedCustomer = useDebounce(customerQuery, 250);
  const working = /*open &&*/ customerLoading;

  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  useEffect(() => {
    if (debouncedCustomer /*&& open*/) {
      const promise = dispatch(
        fetchCustomers({
          keywords: debouncedCustomer,
        })
      );

      return () => {
        promise.abort();
      };
    }
  }, [debouncedCustomer, /*open,*/ dispatch]);

  useEffect(() => {
    if (!customerError) {
      const custs = allCustomers.map((obj) => ({
        // ...obj,
        name: obj.customer_name,
        value: obj.customer_nid,
      }));
      setOptions([...custs]);
    }
  }, [customerError, allCustomers]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/job/wip-detail", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns([], temp);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.reference_number,
        row.customer_name,
        row.customer_location_name,
        row.member_name,
        row.title,
        row.job_division_type,
        row.created,
        row.completion_date,
        row.start_date,
        row.status_proper,
        row.nte_total,
        row.estimate,
        row.billed,
        row.total_job_amount,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
    });
  };

  const handleCustomerInputChange = (e) => {
    if (e) {
      setCustomerQuery(e.target.value);
    }
  };

  return (
    <div>
      <Helmet>
        <title>WIP Detail Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">WIP Detail</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={divisions.map((division) => ({
                    label: division.name,
                    value: division.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, value) => {
                    handleChange(value, "job_division_type_tid");
                  }}
                  value={formData.job_division_type_tid}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.name,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <Autocomplete
                  fullWidth
                  autoComplete={false}
                  multiple
                  freeSolo
                  clearOnBlur
                  onInputChange={handleCustomerInputChange}
                  onChange={(e, value) => {
                    handleChange(value, "customer_nid");
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={formData.customer_nid}
                  getOptionLabel={(option) => option.name}
                  options={options}
                  loading={working}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customers"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {customerLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <InputLabel style={{ marginBottom: "0" }}>
                  Created
                </InputLabel>
                <DateRangePicker
                  startDate={formData.created.value.from}
                  startDateId="event_add_start_date_id"
                  endDate={formData.created.value.through}
                  endDateId="event_add_end_date_id"
                  onDatesChange={({ startDate, endDate }) => {
                    handleChange({
                      type: "date-range",
                      value: {
                        from: startDate,
                        through: endDate,
                      },
                    }, 'created');
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  withPortal
                  appendToBody
                  minimumNights={0}
                  isOutsideRange={() => false}
                  customArrowIcon={<ArrowForward fontSize="small" />}
                  startDatePlaceholderText="Earliest"
                  endDatePlaceholderText="Latest"
                />
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="created"
          originalOrder="desc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportWipDetail.propTypes = {};

ReportWipDetail.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportWipDetail(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportWipDetail);
