
import React, { useEffect, useState, useCallback, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Typography,
  Card,
} from "@mui/material";
import { DefaultLoader } from "common/Loader";
import Empty from "@phx/common/Empty";
import {
  fetchTimeClock,
  postTimeClock,
  getTimeClockLoadingSelector,
  getTimeClockErrorSelector,
  getTimeClockSelector,
} from "./timeClockSlice";
import { parseTimeClockParams, getParamTime} from "./utils";
import "./TimeClock.scss";
import TimeClockCustomer from './components/TimeClockCustomer';
import TimeClockChange from './components/TimeClockChange';
import TimeClockUser, { timeClockValidateUser } from './components/TimeClockUser';
import TimeClockTimer, { timeClockValidateTimer } from './components/TimeClockTimer';
import TimeClockSignature, { timeClockValidateSignature } from './components/TimeClockSignature';
import TimeClockLaborType, { TimeClockLaborTypeInline, timeClockValidateLaborType } from './components/TimeClockLaborType';
import TimeClockServiceActivity, { TimeClockServiceActivityInline, timeClockValidateServiceActivity } from './components/TimeClockServiceActivity';
import TimeClockTravel from "./components/TimeClockTravel";
import TimeClockLog from "./components/TimeClockLog";
import { GiArchiveResearch } from "react-icons/gi";
import Layout from "common/Layout";
import { BsClockHistory } from "react-icons/bs";
import { Formik } from "formik";
import Alert from "common/Alert";
import moment from "moment-timezone";
import { last, size } from "lodash";
// import constants from "components/constants";
import { getIsAuthenticatedSelector } from "features/Auth/authSlice";


const TimeClock = ({divisionHash, serviceHash, setServiceHash, newTimeCard, timeCardOnly }) => {
  const dispatch = useDispatch();
  const { hash, service_hash } = useParams();
  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const currentUser = useSelector((state) => state.auth.user.data);
  const timeClock = useSelector(getTimeClockSelector);
  const loading = useSelector(getTimeClockLoadingSelector);
  const error = useSelector(getTimeClockErrorSelector);
  const [message, setMessage] = useState(null);
  const [subMessage, setSubMessage] = useState(null);

  useEffect(() => {
    if(divisionHash){
      if(newTimeCard){
        getNewTimeClock();
      }
      else{
        getTimeClock(divisionHash);
      }
    }
    else if(hash){
      if(service_hash){
        localStorage.setItem("time_clock_service", service_hash);
        localStorage.removeItem("time_clock_temp_user");
        localStorage.removeItem("time_clock_user_account");
        history.push(`/time-clock/${hash}`);
      }
      getTimeClock(hash);
    }
  }, [divisionHash, newTimeCard, hash]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTimeClock = (hash, newCard, reset) => {
    const params = {
      ...parseTimeClockParams({service: serviceHash})
    };
    if(newCard){
      params.new_card = true;
      if(isAuthenticated){
        params.user_account = currentUser.uid;
      }
    }
    dispatch(fetchTimeClock({hash, manualService: Boolean(serviceHash), params}))
      .then((resp) => {
        if(!reset && resp?.error && (resp?.payload?.message === 'Invalid Service' || resp?.payload?.message === 'Invalid Temp User')){
          if(resp?.payload?.message === 'Invalid Service'){
            localStorage.removeItem("time_clock_service");
          }
          if(resp?.payload?.message === 'Invalid Temp User'){
            localStorage.removeItem("time_clock_temp_user");
          }
          getTimeClock(hash, newCard, true);
        }
      })
  };

  const getNewTimeClock = () => {
    localStorage.removeItem("time_clock_service");
    // if(isAuthenticated){
      localStorage.removeItem("time_clock_temp_user");
      localStorage.removeItem("time_clock_user_account");
    // }
    getTimeClock((divisionHash ? divisionHash : hash), true);
  };

  const changeTimeCard = (uid, tempUserHash) => {
    localStorage.removeItem("time_clock_service");
    if(tempUserHash){
      localStorage.setItem("time_clock_temp_user", tempUserHash);
    }
    if(uid){
      localStorage.setItem("time_clock_user_account", uid);
    }
    getTimeClock(divisionHash ? divisionHash : hash);
  };

  const onSubmit = useCallback( (data, { setSubmitting, setValues }) => {
    setSubmitting(true);

    const serviceDate = data.service_date;
    const params = {
      ...parseTimeClockParams({service: serviceHash}),
      service_date: serviceDate.format(`YYYY-MM-DD`),
      service_end: '',
      service_travel: data.service_travel,
      location: data.location,
    };

    if(data.service_start){
      const startTime = getParamTime(data, "service_start");
      params.service_start = startTime;
      data.service_start = startTime; // this helps get correct times later
    }

    if(data.service_end){
      const endTime = getParamTime(data, "service_end");
      params.service_end = endTime;
      data.service_end = endTime; // this helps get correct times later
    }

    if(data.select_time_card !== ''){
      params.select_time_card =  data.select_time_card;
    }

    if(data.service_type !== ''){
      params.service_type =  data.service_type;
    }

    if(data.service_activity_selector !== '' && data.service_activity_selector !== 'Other'){
      params.service_activity =  data.service_activity_selector;
    }
    else if(data.service_activity !== ''){
      params.service_activity =  data.service_activity;
    }

    if(data.selected_user !== ''){
      params.selected_user =  data.selected_user;
    }
    else if(data.temp_user_name && (!params.temp_user || data.temp_user_name !== timeClock.temp_user_name)){
      params.temp_user_name =  data.temp_user_name;
      params.temp_user = null;
    }

    if(data.temp_user_email !== ''){
      params.temp_user_email =  data.temp_user_email;
    }

    if(data.submitting_button === 'break_start'){
      params.service_break_start = getParamTime(data, "service_break_start", size(data.service_breaks));
    }
    else if(data.submitting_button === 'break_end'){
      params.service_break_hash = last(timeClock.service_breaks).hash;
      params.service_break_end = getParamTime(data, "service_break_end", size(data.service_breaks) - 1); // Check against start time
    }
    else if(data.service_breaks){
      params.service_breaks = data.service_breaks.map((breakTime, i) => {
        const _startTime = getParamTime(data, "service_break_start", i);
        data.service_breaks[i] = {...data.service_breaks[i], start: _startTime}; // this helps get correct times later
        const _endTime = (breakTime.end ? getParamTime(data, "service_break_end", i) : '');
        data.service_breaks[i] = {...data.service_breaks[i], end: _endTime}; // this helps get correct times later

        return {
          'start': _startTime,
          'end': _endTime,
          'hash': breakTime.hash,
        };
      });
    }

    if(data.clock_in_photo && !data.clock_in_photo_hash){
      params.clock_in_photo = data.clock_in_photo;
    }

    if(data.clock_out_photo && !data.clock_out_photo_hash){
      params.clock_out_photo = data.clock_out_photo;
    }

    if(data.submitting_button === 'signature'){
      params.signature = data.signature;
      params.signature_name = data.signature_name;
      params.signature_date = data.signature_date.format(`YYYY-MM-DD`);
    }

    if(size(data.log_entries)){
      params.log_entries = data.log_entries;
    }

    const _hash = (divisionHash ? divisionHash : hash);
    dispatch(postTimeClock({
      hash: _hash,
      manualService: Boolean(serviceHash),
      params
    }))
      .then((resp) => {
        setSubmitting(false);
        if(newTimeCard && setServiceHash){
          setServiceHash(resp.payload.service);
        }
        if(resp.payload?.service_breaks){
          setValues(setInitialValues(resp.payload));
        }
      });
  }, [timeClock]); // eslint-disable-line react-hooks/exhaustive-deps

  const setInitialValues = (values) => {
    return {
      temp_user_name: values?.temp_user_name ? values.temp_user_name : '',
      selected_user: values?.user_id ? 'uid-' + values.user_id : '',
      service_date: values?.service_date ? moment(values.service_date) : moment(),
      service_start: values?.service_start ? moment.parseZone(values.service_start) : null,
      service_end: values?.service_end ? moment.parseZone(values.service_end) : null,
      service_type: values?.service_type ? values.service_type : '',
      service_activity_selector: values?.service_activity ?
          (!values.service_activity_other ? values.service_activity : 'Other')
        : '',
      service_activity: values?.service_activity ? values.service_activity : '',
      temp_user_email: '',
      service_breaks: values?.service_breaks ? [...values.service_breaks] : null,
      clock_in_photo: values?.service_clock_in_photo ? values.service_clock_in_photo : null,
      clock_in_photo_hash: values?.service_clock_in_photo_hash ? values.service_clock_in_photo_hash : null,
      clock_out_photo: values?.service_clock_out_photo ? values.service_clock_out_photo : null,
      clock_out_photo_hash: values?.service_clock_out_photo_hash ? values.service_clock_out_photo_hash : null,
      service_travel: values?.service_travel ? values.service_travel : 0,
      signature: null,
      signature_name: '',
      signature_date: moment(),
      submitting_button: null,
      select_time_card: '',
      selecting_time_card: false,
      log_entries: [],
      warnings: values?.warnings && size(values.warnings) ? values.warnings : null,
      location: null,
    };
  }

  const _timeClock = (
    <div className="time-clock">
      {!timeCardOnly &&
        <TimeClockCustomer
          timeClock={timeClock}
        />
      }
      <Formik
        initialValues={setInitialValues(timeClock)}
        onSubmit={onSubmit}
        validate={(values, buttonName, props) => {
          if(values.warnings){
            setMessage({
              id: 'warning',
              msg: values.warnings.join("<br />"),
            })
          }
          else{
            setMessage(null);
          }
          setSubMessage(null);
          let errors = {};

          errors = timeClockValidateUser(errors, values, setMessage, isAuthenticated);

          errors = timeClockValidateLaborType(errors, timeClock, values, setSubMessage);

          errors = timeClockValidateServiceActivity(errors, timeClock, values, setSubMessage);

          errors = timeClockValidateTimer(errors, timeClock, values, setSubMessage);

          errors = timeClockValidateSignature(errors, values, setSubMessage);

          return errors;
        }}
      >
        {({ values, errors, isSubmitting, submitForm, setFieldValue, setSubmitting, validateForm }) => {
          const handleSubmit = (buttonName) => {
            setFieldValue('submitting_button', buttonName);
            setSubmitting(true);

            const _onGetCurrentLocation  = () => {
              const options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0
              };
              navigator.geolocation.getCurrentPosition((position) => {
              //  Success
                setFieldValue('location', {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                });
                setTimeout(submitForm, 1000);
              }, (err) => {
              //  Error
                setFieldValue('location', {
                  error: err && err?.message ? err.message : "Geolocation unavailable",
                });
                setTimeout(submitForm, 1000);
              }, options);
            }

          //  Get location peremissions/info
            if ( navigator.permissions && navigator.permissions.query) {
              //try permissions APIs first
              navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                  // Will return ['granted', 'prompt', 'denied']
                  const permission = result.state;
                  if ( permission === 'granted' || permission === 'prompt' ) {
                      _onGetCurrentLocation();
                  }
              });
            } else if (navigator.geolocation) {
              //then Navigation APIs
              _onGetCurrentLocation();
            }
            else {
            //  Error
              setFieldValue('location', {
                error: "Geolocation not supported",
              });
              setTimeout(submitForm, 1000);
            }
          };

        return (
          <>
          {isSubmitting &&
            <div className="submitting"><DefaultLoader propClass="loader" /></div>
          }
          {message && <Alert kind={message.id}>{message.msg}</Alert>}
          {subMessage && <Alert kind={subMessage.id}>{subMessage.msg}</Alert>}
          {(!serviceHash && !newTimeCard) &&
            <TimeClockChange
              timeClock={timeClock}
              isSubmitting={isSubmitting}
              getNewTimeClock={getNewTimeClock}
              changeTimeCard={changeTimeCard}
              setFieldValue={setFieldValue}
              values={values}
              submitForm={handleSubmit}
            />
          }
          <Card
            variant="outlined"
            className={timeClock.service_status === 'complete' ? 'complete' : ''}
          >
            <TimeClockUser
              timeClock={timeClock}
              isSubmitting={isSubmitting}
              values={values}
              submitForm={handleSubmit}
              getNewTimeClock={getNewTimeClock}
              timeCardOnly={timeCardOnly}
              setFieldValue={setFieldValue}
              message={subMessage}
              errors={errors}
            />

            <TimeClockSignature
              timeClock={timeClock}
              values={values}
              isSubmitting={isSubmitting}
              submitForm={handleSubmit}
            >
              <TimeClockLaborTypeInline
                timeClock={timeClock}
                isSubmitting={isSubmitting}
                message={subMessage}
                errors={errors}
                inTimeClock
              />
              <TimeClockServiceActivityInline
                timeClock={timeClock}
                isSubmitting={isSubmitting}
                values={values}
                message={subMessage}
                errors={errors}
                inTimeClock
              />
              <TimeClockTimer
                timeClock={timeClock}
                submitForm={handleSubmit}
                isSubmitting={isSubmitting}
                values={values}
                setFieldValue={setFieldValue}
                message={subMessage}
                errors={errors}
                validateForm={validateForm}
              />
            </TimeClockSignature>

            <TimeClockLaborType
              timeClock={timeClock}
              submitForm={handleSubmit}
              isSubmitting={isSubmitting}
              values={values}
              message={subMessage}
              errors={errors}
            />
            <TimeClockServiceActivity
              timeClock={timeClock}
              submitForm={handleSubmit}
              isSubmitting={isSubmitting}
              values={values}
              message={subMessage}
              errors={errors}
            />
            <TimeClockTravel
              timeClock={timeClock}
              submitForm={handleSubmit}
              isSubmitting={isSubmitting}
              values={values}
              message={subMessage}
              errors={errors}
            />
            <TimeClockLog
              timeClock={timeClock}
            />
          </Card>
          </>
        )}}
      </Formik>
    </div>
  );

  return (
    loading ? (
      <DefaultLoader style={{padding: "2rem"}} />
    ) : (
      !error ? (
        !timeCardOnly ? (
          <Layout
            name={(<div className="ml-2">
              <Typography variant="h5">Timeclock</Typography>
              <Typography className="normal-case">
                {timeClock.division_title}
                {timeClock.division_type && ` (${timeClock.division_type})`}
              </Typography>
            </div>)}
            icon={<BsClockHistory />}
            pageHeaderProps={{slim: true}}
          >
            {_timeClock}
          </Layout>
        ) : (
          _timeClock
        )
      ) : (
        <Empty message={error} style={{"color": "var(--color-primary)"}}>
          <GiArchiveResearch />
        </Empty>
      )
    )
  );
};

TimeClock.propTypes = {};

export default TimeClock;
