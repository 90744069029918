import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";

import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import { SelectFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { BsPencilSquare, BsTools } from "react-icons/bs";

const TimeClockServiceActivity = ({
  // open,
  onClose,
  message,
  submitForm,
  isSubmitting,
  submitButton,
  timeClock,
  values,
  errors,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
    {timeClock?.service_activity &&
      <div className="service-activity">
        <BsTools className="edit-icon medium" />
        <span className="label">Work Performing:</span> {timeClock?.service_activity}
        {timeClock.service_status !== 'complete' &&
          <Tooltip title="Update Work Performing">
            <><BsPencilSquare className="edit-icon" onClick={() => setOpen(true)} /></>
          </Tooltip>
        }
      </div>
    }
    <Dialog
      open={open || (Boolean(timeClock?.service_activity_needed) && !Boolean(timeClock?.labor_type_needed))}
      onClose={onClose}
      aria-labelledby="time-clock-service-activity-dialog-title"
      aria-describedby="time-clock-service-activity-dialog-description"
      fullWidth
      maxWidth="xs"
      className="time-clock time-clock-dialog"
    >
      <DialogTitle id="time-clock-service-activity-dialog-title" className="dialog-title"><BsTools className="edit-icon large" /> Please select the work performed</DialogTitle>
      <DialogContent>
        {message && <Alert kind={message.id}>{message.msg}</Alert>}
        <div id="time-clock-service-activity-dialog-description" className="dialog-content">
          <TimeClockServiceActivityInline
            timeClock={timeClock}
            isSubmitting={isSubmitting}
            values={values}
            errors={errors}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {open &&
          <Button onClick={() => setOpen(false)} disabled={isSubmitting}>
            Cancel
          </Button>
        }
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          disableElevation
          size="small"
          onClick={() => {
            submitForm('select_service_activity');
            setOpen(false);
          }}
        >
          Select
        </ButtonLoader>
      </DialogActions>
    </Dialog>
    </>
  );
};
export const TimeClockServiceActivityInline = ({
  // open,
  onClose,
  message,
  submitForm,
  isSubmitting,
  submitButton,
  timeClock,
  values,
  errors,
  inTimeClock
}) => {

  return (
    (!inTimeClock || (!timeClock?.service_activity && timeClock.service_status !== "complete")) &&
      <div className="service-activity inline-field">
        <BsTools className="edit-icon large" />
        <div className="wrapper">
          <SelectFormField
            fullWidth
            name="service_activity_selector"
            label="Work Performing"
            disabled={isSubmitting}
            options={timeClock?.service_activities_options ? timeClock.service_activities_options.map((name, i) => ({value: name, label: name})) : []}
            variant="filled"
            // size="small"
            required={!inTimeClock}
            error={errors?.service_activity_selector}
          />
          {values.service_activity_selector === 'Other' &&
            <TextFormField
              fullWidth
              htmlFor="service_activity"
              name="service_activity"
              label="Description"
              variant="filled"
              size="small"
              disabled={isSubmitting}
              required={!inTimeClock}
              error={errors?.service_activity}
              inputProps={{ maxLength: 150 }}
            />
          }
        </div>
      </div>
  );
};

TimeClockServiceActivity.propTypes = {};

export default TimeClockServiceActivity;


export const timeClockValidateServiceActivity = (errors, timeClock, values, setSubMessage) => {
  if(timeClock?.service_activity_needed && !timeClock?.labor_type_needed){
    if(!values.service_activity_selector || values.service_activity_selector === ''){
      errors.service_activity_selector = 'Please select the work performed';
      setSubMessage({
        id: "negative",
        msg: errors.service_activity_selector,
      });
    }
    else if(values.service_activity_selector === 'Other' && values.service_activity === ''){
      errors.service_activity = 'Please describe work performed';
      setSubMessage({
        id: "negative",
        msg: errors.service_activity,
      });
    }
  }

  return errors;
}
