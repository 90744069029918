import React from "react";
import PropTypes from "prop-types";
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import Empty from "../Empty";
import { TableSkeleton } from "../Skeletons";
import { LoaderContainer } from "./Styled";

import "./Table.scss";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = ({ order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              minWidth: headCell.minWidth ? headCell.minWidth : "auto",
              width: headCell.width ? headCell.width : "auto",
            }}
          >
            {!headCell.notSortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className="visually-hidden">
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const { func, oneOf, string } = PropTypes;
EnhancedTableHead.propTypes = {
  onRequestSort: func.isRequired,
  order: oneOf(["asc", "desc"]).isRequired,
  orderBy: string.isRequired,
};

const InlineTable = ({
  columns,
  rows,
  size,
  stickyHeader,
  noPagination,
  totals,
  emptyMessage,
  loading,
  defaultSort,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(defaultSort ? defaultSort : "");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  React.useEffect(() => {
    if (noPagination) {
      setRowsPerPage(rows.length);
    }
  }, [noPagination, rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <section className="table">
      <TableContainer
        className={`table__wrapper${
          noPagination ? "table__wrapper--nopagination" : ""
        }`}
      >
        {loading ? (
          <LoaderContainer>
            <TableSkeleton />
          </LoaderContainer>
        ) : !rows.length ? (
          <Empty
            large
            msg={emptyMessage ? emptyMessage : "No data to display."}
          />
        ) : (
          <MaterialTable
            aria-label="Table"
            size={size}
            stickyHeader={stickyHeader}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className="table__row"
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            padding={column.disablePadding ? "none" : "normal"}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {totals ? (
                <TableRow>
                  {columns.map((column) => {
                    const value = totals[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        padding={column.disablePadding ? "none" : "normal"}
                      >
                        {column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : (
                          <strong>{value}</strong>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
            </TableBody>
          </MaterialTable>
        )}
      </TableContainer>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={(handleChangeRowsPerPage ? [25, 50, 100, 200] : [])}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table__pagination"
        />
      )}
    </section>
  );
};

const { array } = PropTypes;
InlineTable.propTypes = {
  columns: array.isRequired,
  rows: array.isRequired,
  originalOrder: string,
  orginalOrderBy: string,
};

InlineTable.defaultProps = {
  originalOrder: "asc",
};

export default React.memo(InlineTable);
