import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { tokenConfig } from "actions/authActions";

// import { tokenConfig } from "../../actions/authActions";
import { processTimeClock } from "./utils";
import getQueryParams from "utility/getQueryParams";
import config from "config";
import { size, first } from "lodash";

const namespace = "time_clock";

export const fetchTimeClock = createAsyncThunk(
  `${namespace}/fetchTimeClock`,
  async ({hash, manualService, params}, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/ds-time-clock/${hash}${getQueryParams(params)}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      if(!manualService){
        return processTimeClock(response.data);
      }
      else{
        return response.data;
      }
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const postTimeClock = createAsyncThunk(
  `${namespace}/postTimeClock`,
  async ({hash, manualService, params}, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/job/ds-time-clock/${hash}`,
        params,
        tokenConfig(getState)
      );

      if(!manualService){
        return processTimeClock(response.data);
      }
      else{
        return response.data;
      }
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTimeCards = createAsyncThunk(
  `${namespace}/fetchTimeCards`,
  async ({ id, updateCardID, params }, { getState, signal, rejectWithValue }) => {
    try {
      if(updateCardID){
        params.page_to_id = updateCardID;
        params.page_to_index = "daily_sheet_nid";
      }
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/${id}/time_card_listing/${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        cards: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTimeCardUsers = createAsyncThunk(
  `${namespace}/fetchTimeCardUsers`,
  async ({ id, updateUserID, params }, { getState, signal, rejectWithValue }) => {
    try {
      if(updateUserID){
        params.page_to_id = updateUserID;
        params.page_to_index = "user_id";
      }
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/${id}/time_card_overview${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserTimeCards = createAsyncThunk(
  `${namespace}/fetchUserTimeCards`,
  async ({ id, uid }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams({
        "filter[ds_user_uid][0]": uid,
      });
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/${id}/time_card_overview${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const checkTimeClockAlias = createAsyncThunk(
  `${namespace}/checkTimeClockAlias`,
  async ({job_name, params}, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/tc/${job_name}${getQueryParams(params)}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const timeCardsAdapter = createEntityAdapter({
  selectId: (card) => card.daily_sheet_nid,
});

const timeCardUsersAdapter = createEntityAdapter({
  selectId: (card) => card.user_id,
});

const timeClockSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    error: null,
    data: {},
    user: {},
    timeCards:  timeCardsAdapter.getInitialState({
      loading: true,
      loaded: false,
      error: null,
    }),
    timeCardUsers:  timeCardUsersAdapter.getInitialState({
      loading: true,
      loaded: false,
      error: null,
    }),
    userTimeCards: {
      loading: true,
      loaded: false,
      error: null,
      data: null
    },
  },
  reducers: {
    setUser: (state, {payload}) => {
      state.user = payload;
    },
    clearLoaded: (state) => {
      state.timeCardUsers.loaded = false;
      state.userTimeCards.loaded = false;
    },
  },
  extraReducers: {
    [fetchTimeClock.pending](state) {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
    [fetchTimeClock.fulfilled](state, { payload: timeClock }) {
      state.loading = false;
      state.data = timeClock;
    },
    [fetchTimeClock.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postTimeClock.pending](state) {
      state.error = null;
    },
    [postTimeClock.fulfilled](state, { payload: timeClock }) {
      state.data = timeClock;
    },
    [postTimeClock.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchTimeCards.pending](state, action) {
      if(!action.meta.arg.updateCardID){
        state.timeCards.loading = true;
      }
      state.timeCards.error = null;
    },
    [fetchTimeCards.fulfilled](state, { payload: { cards, pagination }, meta }) {
      state.timeCards.loading = false;
      state.timeCards.loaded = true;
      state.timeCards.error = null;
      state.timeCards.pagination = pagination;
      if(!meta.arg.updateCardID){
        timeCardsAdapter.setAll(state.timeCards, cards);
      }
      else{
        const _card = cards.find((card) => Number(card.daily_sheet_nid) === Number(meta.arg.updateCardID));
        timeCardsAdapter.updateOne(state.timeCards, {
          id: meta.arg.updateCardID,
          changes: _card,
        });
      }
    },
    [fetchTimeCards.rejected](state, action) {
      if (!action.meta.aborted) {
        state.timeCards.loading = false;
        if (action.payload) {
          state.timeCards.error = action.payload.message;
        } else {
          state.timeCards.error = action.error.message;
        }
      }
    },
    [fetchTimeCardUsers.pending](state, action) {
      if(!action.meta.arg.updateUserID){
        state.timeCardUsers.loading = true;
      }
      state.timeCardUsers.error = null;
    },
    [fetchTimeCardUsers.fulfilled](state, { payload: { users, pagination }, meta }) {
      state.timeCardUsers.loading = false;
      state.timeCardUsers.loaded = true;
      state.timeCardUsers.error = null;
      state.timeCardUsers.pagination = pagination;
      if(!meta.arg.updateUserID){
        timeCardUsersAdapter.setAll(state.timeCardUsers, users);
      }
      else{
        const _user = users.find((user) => user.user_id === meta.arg.updateUserID);
        timeCardUsersAdapter.updateOne(state.timeCardUsers, {
          id: meta.arg.updateUserID,
          changes: _user,
        });
      }
    },
    [fetchTimeCardUsers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.timeCardUsers.loading = false;
        if (action.payload) {
          state.timeCardUsers.error = action.payload.message;
        } else {
          state.timeCardUsers.error = action.error.message;
        }
      }
    },
    [fetchUserTimeCards.pending](state, action) {
      state.userTimeCards.loading = true;
      state.userTimeCards.error = null;
    },
    [fetchUserTimeCards.fulfilled](state, { payload: { users, pagination }, meta }) {
      state.userTimeCards.loading = false;
      state.userTimeCards.loaded = true;
      state.userTimeCards.error = null;
      state.userTimeCards.data = size(users) ? first(users) : null;
    },
    [fetchUserTimeCards.rejected](state, action) {
      if (!action.meta.aborted) {
        state.userTimeCards.loading = false;
        if (action.payload) {
          state.userTimeCards.error = action.payload.message;
        } else {
          state.userTimeCards.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;

export const getTimeClockSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.data
);

export const getTimeClockLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.loading
);

export const getTimeClockErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.error
);

export const getTimeClockUserSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.user
);

export const getTimeCardsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.timeCards.loading
);

export const getTimeCardsLoadedSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.timeCards.loaded
);

export const getTimeCardsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.timeCards.pagination
);

export const timeCardsSelectors = timeCardsAdapter.getSelectors(
  (state) => state.time_clock.timeCards
);

export const getTimeCardUsersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.timeCardUsers.loading
);

export const getTimeCardUsersLoadedSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.timeCardUsers.loaded
);

export const getTimeCardUsersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.timeCardUsers.pagination
);

export const timeCardUsersSelectors = timeCardUsersAdapter.getSelectors(
  (state) => state.time_clock.timeCardUsers
);

export const getUserTimeCardLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.userTimeCards.loading
);

export const getUserTimeCardLoadedSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.userTimeCards.loaded
);

export const userTimeCardsSelectors = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.userTimeCards.data
);


export const { setUser, clearLoaded } = timeClockSlice.actions;

export default timeClockSlice.reducer;
