import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Popper,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  GroupAdd,
  PostAdd,
  Subject,
  NotificationsActive,
} from "@mui/icons-material";
import { RRule } from "rrule";
import { useParams } from "react-router-dom";

import { DialogForm } from "../Form";
import { RouteDialog } from "../Dialog";
import { useDebounce } from "../../hooks";
import config from "../../config";
import AutocompleteFormField from "../AutocompleteFormField";
import { SelectFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { reminderHours } from "./utils";
import { times } from "../../utility";
import { postTask, getUsers, getJobs } from "../../actions";
import { Dates, Times } from "./Styled";

const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const Form = ({
  url,
  addTask,
  users,
  loadUsers,
  userError,
  loadingUsers,
  jobs,
  loadingJobs,
  loadJobs,
  jobError,
  job,
  handleRefetchEvents,
  member,
}) => {
  const userFields = useSelector((state) => state.auth.user.data);
  const { start, end } = useParams();
  const [hasReminder, setHasReminder] = React.useState(false);
  const [hasTimes, setHasTimes] = React.useState(false);
  const [hasUsers, setHasUsers] = React.useState(false);
  const [hasJob, setHasJob] = React.useState(false);
  const [hasDesc, setHasDesc] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState([]);
  const [userQuery, setUserQuery] = React.useState("");
  const [openJob, setOpenJob] = React.useState(false);
  const [jobOptions, setJobOptions] = React.useState([]);
  const [jobQuery, setJobQuery] = React.useState("");
  const userWorking = openUser && loadingUsers;
  const debouncedUser = useDebounce(userQuery, 250);
  const jobWorking = openJob && loadingJobs;
  const debouncedJob = useDebounce(jobQuery, 250);
  const userName =
    userFields.first && userFields.last
      ? `${userFields.first} ${userFields.last}`
      : userFields.name
      ? userFields.name
      : "NA";

  useEffect(() => {
    if (!userError) {
      const u = users.map((obj) => ({
        name: obj.name,
        uid: obj.uid,
      }));
      setUserOptions([...u]);
    }
  }, [userError, users]);

  useEffect(() => {
    if (!jobError) {
      const u = jobs.map((obj) => ({
        name: obj.title,
        nid: obj.division_nid,
      }));
      setJobOptions([...u]);
    }
  }, [jobError, jobs]);

  useEffect(() => {
    if (debouncedUser && openUser) {
      loadUsers({ keywords: debouncedUser });
    }
  }, [debouncedUser, openUser, loadUsers]);

  useEffect(() => {
    if (debouncedJob && openJob) {
      loadJobs({ keywords: debouncedJob });
    }
  }, [debouncedJob, openJob, loadJobs]);

  const getUserOptions = () => {
    setUserOptions([]);
    setOpenUser(true);
  };

  const clearUserOptions = () => {
    setOpenUser(false);
    setUserOptions([]);
  };

  const handleUserInputChange = (e) => {
    if (e) {
      setUserQuery(e.target.value);
    }
  };

  const getJobOptions = () => {
    setJobOptions([]);
    setOpenJob(true);
  };

  const clearJobOptions = () => {
    setOpenJob(false);
    setJobOptions([]);
  };

  const handleJobInputChange = (e) => {
    if (e) {
      setJobQuery(e.target.value);
    }
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    const start = moment(
      moment(data["start-date"]).format("YYYY-MM-DD") +
        "T" +
        data["start-time"].value
    ).format();
    const end = moment(
      moment(data["end-date"]).format("YYYY-MM-DD") +
        "T" +
        data["end-time"].value
    ).format();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const allDay = !hasTimes;
    let rrule = "";
    if (data.repeats !== "no-repeat") {
      const rule = new RRule({
        freq: RRule[data.repeats],
        interval: 1,
        until: moment(data.until).format(),
      });

      rrule = rule.toString().substring(6);
    }

    const params = {
      _links: {
        type: {
          href: `${config.api_url}/rest/type/node/task`,
        },
      },
      title: [{ value: data.title }],
      field_task_all_day: [{ value: allDay }],
      body: [{ value: data.description }],
      field_task_users: data.user.map((u) => ({ target_id: u.uid })),
      field_task_date: [
        {
          value: start,
          end_value: end,
          rrule,
          timezone,
          infinite: true,
        },
      ],
    };

    if (data.job) {
      params.field_task_job_number = [{ target_id: data.job.nid }];
    }

    if (member) {
      params.field_task_parent_node = [{ target_id: member }];
    }

    if (hasReminder) {
      params.field_task_reminder = [
        { value: `${data["reminder-time"]} ${data["reminder-type"]}` },
      ];
    }

    const response = await addTask(params);
    if (response.status === 201) {
      handleRefetchEvents();
      successCallback("Task added successfully.");
    } else {
      errorCallback(setMessage, response, []);
    }
  };

  return (
    <DialogForm
      submitText="Update"
      url={url}
      path="add"
      initialValues={{
        title: "",
        "start-date": moment(start),
        "end-date": moment(end),
        "start-time": {
          name: round(moment(), moment.duration(15, "minutes"), "ceil").format(
            "h:mm A"
          ),
          value: round(moment(), moment.duration(15, "minutes"), "ceil").format(
            "HH:mm"
          ),
        },
        "end-time": {
          name: round(moment(), moment.duration(15, "minutes"), "ceil")
            .add(1, "h")
            .format("h:mm A"),
          value: round(moment(), moment.duration(15, "minutes"), "ceil")
            .add(1, "h")
            .format("HH:mm"),
        },
        repeats: "no-repeat",
        description: "",
        user: [{ uid: userFields.uid, name: userName }],
        job: job ? job : null,
        "reminder-type": "hours",
        "reminder-time": "1",
        until: moment(),
      }}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <>
          <TextFormField
            fullWidth
            htmlFor="title"
            name="title"
            label="Title"
            margin="normal"
            disabled={isSubmitting}
            type="text"
            labelwidth={70}
          />
          <Dates>
            <DateFormField
              disableToolbar
              variant="inline"
              format="MMM D, YYYY"
              id="start-date"
              margin="normal"
              name="start-date"
              size="small"
              datePicker
            />
            {!hasTimes && <span>-</span>}
            {hasTimes && (
              <Times>
                <AutocompleteFormField
                  name="start-time"
                  htmlFor="start-time"
                  margin="normal"
                  disabled={isSubmitting}
                  disableClearable
                  options={times}
                  PopperComponent={CustomPopper}
                />
                <span>-</span>
                <AutocompleteFormField
                  name="end-time"
                  htmlFor="end-time"
                  margin="normal"
                  disabled={isSubmitting}
                  disableClearable
                  PopperComponent={CustomPopper}
                  options={times}
                />
              </Times>
            )}
            <DateFormField
              disableToolbar
              variant="inline"
              format="MMM D, YYYY"
              id="end-date"
              margin="normal"
              name="end-date"
              size="small"
              datePicker
            />
          </Dates>
          <FormControlLabel
            control={
              <Checkbox
                checked={!hasTimes}
                onChange={() => setHasTimes(!hasTimes)}
                color="secondary"
              />
            }
            label="All Day"
          />
          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            <Grid item xxs={6}>
              <SelectFormField
                fullWidth
                name="repeats"
                label="Repeats"
                margin="normal"
                disabled={isSubmitting}
                options={[
                  { value: "no-repeat", label: "Does not repeat" },
                  { value: "DAILY", label: "Daily" },
                  { value: "WEEKLY", label: "Weekly" },
                  { value: "MONTHLY", label: "Monthly" },
                  { value: "YEARLY", label: "Annually" },
                ]}
              />
            </Grid>
            <Grid item xxs={6}>
              {values.repeats !== "no-repeat" && (
                <DateFormField
                  label="Repeat To"
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="MMM D, YYYY"
                  id="until"
                  margin="normal"
                  name="until"
                  size="small"
                  datePicker
                />
              )}
            </Grid>
          </Grid>
          {hasReminder ? (
            <>
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xxs={3}>
                  <SelectFormField
                    fullWidth
                    name="reminder-time"
                    label=""
                    disabled={isSubmitting}
                    options={reminderHours()}
                  />
                </Grid>
                <Grid item xxs={9}>
                  <SelectFormField
                    fullWidth
                    name="reminder-type"
                    label=""
                    disabled={isSubmitting}
                    options={[
                      { value: "hours", label: "Hour(s) before" },
                      { value: "days", label: "Day(s) before" },
                      { value: "weeks", label: "Week(s) before" },
                    ]}
                  />
                </Grid>
              </Grid>
              <Typography
                onClick={() => setHasReminder(false)}
                style={{
                  marginTop: "0.5rem",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Remove Reminder
              </Typography>
            </>
          ) : (
            <Button
              fullWidth
              size="small"
              component="a"
              onClick={() => setHasReminder(true)}
              style={{ justifyContent: "flex-start", marginTop: "1rem" }}
              startIcon={<NotificationsActive />}
            >
              Add Reminder
            </Button>
          )}
          {hasUsers ? (
            <AutocompleteFormField
              multiple
              name="user"
              label="User"
              htmlFor="user"
              margin="normal"
              disabled={isSubmitting}
              onInputChange={handleUserInputChange}
              open={openUser}
              onOpen={getUserOptions}
              onClose={clearUserOptions}
              options={userOptions}
              loading={userWorking}
            />
          ) : (
            <Button
              fullWidth
              size="small"
              component="a"
              onClick={() => setHasUsers(true)}
              style={{ justifyContent: "flex-start", marginTop: "1rem" }}
              startIcon={<GroupAdd />}
            >
              Add Users
            </Button>
          )}
          {hasJob || values.job ? (
            <AutocompleteFormField
              name="job"
              label="Job"
              htmlFor="job"
              margin="normal"
              disabled={isSubmitting}
              onInputChange={handleJobInputChange}
              open={openJob}
              onOpen={getJobOptions}
              onClose={clearJobOptions}
              options={jobOptions}
              loading={jobWorking}
            />
          ) : (
            <Button
              fullWidth
              size="small"
              component="a"
              onClick={() => setHasJob(true)}
              style={{ justifyContent: "flex-start", marginTop: "1rem" }}
              startIcon={<PostAdd />}
            >
              Add Job
            </Button>
          )}
          {hasDesc ? (
            <TextFormField
              fullWidth
              htmlFor="description"
              name="description"
              label="Description"
              margin="normal"
              disabled={isSubmitting}
              type="text"
              labelwidth={70}
            />
          ) : (
            <Button
              fullWidth
              size="small"
              component="a"
              onClick={() => setHasDesc(true)}
              style={{ justifyContent: "flex-start", marginTop: "1rem" }}
              startIcon={<Subject />}
            >
              Add Description
            </Button>
          )}
        </>
      )}
    </DialogForm>
  );
};

const TaskAdd = ({ url, ...rest }) => {
  return (
    <RouteDialog
      route="add/:start/:end"
      path="add"
      url={url}
      maxWidth="xs"
      title="Add Task"
    >
      <Form url={url} {...rest} />
    </RouteDialog>
  );
};

const { string, func, object } = PropTypes;
TaskAdd.propTypes = {
  id: string,
  anchorEl: object,
  onClose: func,
  data: object,
};

TaskAdd.defaultProps = {
  users: [],
  jobs: [],
};

const mapStateToProps = (state) => ({
  users: state.app.users.data.data,
  loadingUsers: state.app.users.loading,
  userError: state.app.users.error,
  jobs: state.app.jobs.data.data,
  loadingJobs: state.app.jobs.loading,
  jobError: state.app.jobs.error,
});

const mapDispatchToProps = (dispatch) => ({
  addTask: (params) => dispatch(postTask(params)),
  loadUsers: (params) => dispatch(getUsers(params)),
  loadJobs: (params) => dispatch(getJobs(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskAdd);
