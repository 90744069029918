import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { DialogContent, Button, Chip, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

import Dialog from "../Dialog";
import AutocompleteFormField from "../AutocompleteFormField";
import { SelectFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import { FormContainer, FormActions } from "./Styled";
import { formatError, formatErrorResponseString } from "../../utility";
import { FormSkeleton } from "../Skeletons";
import {
  getComplianceActivity,
  postComplianceActivity,
  getComplianceChangeReasons,
  getLiftMethods,
} from "../../actions";
import { addMemberCompliance } from "../../schemas/forms/member";
import {
  jobDivisionTypesSelectors,
  memberStatusSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import { first } from "lodash";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "16px",
  },
  chip: {
    margin: 2,
  },
}));

const findLabel = (data, tid) => {
  if (!data || !tid) return null;
  const item = data.find((obj) => obj.tid === tid);
  return item?.name;
};

const formatDivisionsForSubmit = (d) => {
  const params = {};
  if (!d) return params;
  d.forEach((tid) => {
    params[tid] = tid;
  });

  return params;
};

const formatMembersForSubmit = (m, c) => {
  const params = {
    [m]: m,
  };

  c.forEach((child) => {
    params[child.nid] = child.nid;
  });

  return params;
};

const MemberComplianceAdd = ({
  onClose,
  open,
  mnid,
  memberName,
  loadChangeReasons,
  loadingReasons,
  changeReasons,
  loadLiftMethods,
  loadingLiftMethods,
  liftMethods,
  addComplianceActivity,
  loadMemberCompliance,
  showSnackbar,
  memberDivisionTypes,
  childMembers,
  refreshMember,
}) => {
  const statuses = useSelector(memberStatusSelectors.selectAll);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const classes = useStyles();
  const loading = loadingReasons || loadingLiftMethods;
  const [message, setMessage] = React.useState(null);
  const [openChildAc, setOpenChildAc] = React.useState(false);
  useEffect(() => {
    if (open) {
      loadChangeReasons();
      loadLiftMethods();
    }
  }, [loadChangeReasons, loadLiftMethods, open]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      title="Add Compliance"
    >
      {loading ? (
        <FormSkeleton />
      ) : (
        <>
          <DialogContent>
            <Formik
              initialValues={{
                "start-date": moment(),
                description: "",
                status: "",
                reason: "",
                divisions: memberDivisionTypes
                  ? memberDivisionTypes.map((type) => `${type.target_id}`)
                  : [],
                method: "Manual",
                "lift-date": null,
                members: [
                  { name: memberName, nid: mnid },
                  ...(childMembers
                    ? childMembers
                        .filter(
                          (m) =>
                            m.affiliation !== "County Agreement" &&
                            m.affiliation !== "Secondary Provider"
                        )
                        .map((child) => ({
                          name: child.member_name,
                          nid: child.member_nid,
                        }))
                    : []),
                ],
              }}
              validationSchema={addMemberCompliance}
              onSubmit={async (data, { setSubmitting, setSuccess }) => {
                setSubmitting(true);
                const mem = first(data.members);

                const params = {
                  compliance_member: mem?.nid,
                  compliance_members: [
                    formatMembersForSubmit(mem?.nid, data.members),
                  ],
                  compliance_start_date: moment(data["start-date"]).format(
                    "YYYY-MM-DD"
                  ),
                  compliance_change_reason: data.reason,
                  status: data.status,
                  compliance_lift_method: data.method,
                  compliance_lift_date: "",
                  divisions: formatDivisionsForSubmit(data.divisions),
                  compliance_description: data.description,
                };

                if (data["lift-date"]) {
                  params.compliance_lift_date = data["lift-date"]
                    ? moment(data["lift-date"]).format("YYYY-MM-DD")
                    : null;
                }

                const response = await addComplianceActivity(params);
                if (response.status === 201) {
                  await loadMemberCompliance(mnid);
                  showSnackbar({
                    msg: "Successfully added compliance activity.",
                    kind: "positive",
                  });
                  refreshMember();
                } else {
                  const errorMessage = formatError(response);
                  const newMessage = formatErrorResponseString(
                    errorMessage.msg
                  );
                  errorMessage.msg = newMessage;
                  setMessage(errorMessage);
                }
              }}
            >
              {({ values, errors, isSubmitting, setFieldValue }) => (
                <FormContainer>
                  {message && <Alert kind={message.id}>{message.msg}</Alert>}
                  <AutocompleteFormField
                    name="members"
                    label="Providers"
                    htmlFor="members"
                    margin="normal"
                    open={openChildAc}
                    onOpen={() => setOpenChildAc(true)}
                    onClose={() => setOpenChildAc(false)}
                    disabled={isSubmitting}
                    options={[
                      { name: memberName, nid: mnid },
                      ...childMembers.map((child) => ({
                        name: child.member_name,
                        nid: child.member_nid,
                      })),
                    ]}
                    multiple
                    size="small"
                  />
                  <DateFormField
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="start-date"
                    label="Start Date"
                    margin="normal"
                    name="start-date"
                    KeyboardButtonProps={{
                      "aria-label": "change start date",
                    }}
                  />
                  <SelectFormField
                    fullWidth
                    name="reason"
                    label="Change Reason"
                    margin="normal"
                    required
                    disabled={isSubmitting}
                    options={changeReasons.map((reason) => ({
                      label: reason.name,
                      value: reason.tid,
                    }))}
                  />
                  <SelectFormField
                    fullWidth
                    name="status"
                    label="Compliance Status"
                    margin="normal"
                    required
                    disabled={isSubmitting}
                    options={statuses
                      .filter((status) => status.label !== "Archived")
                      .map((status) => ({
                        label: status.label,
                        value: status.machine_name,
                      }))}
                  />
                  <Grid container spacing={3}>
                    <Grid item xxs={values.method === "Timed" ? 6 : 12}>
                      <SelectFormField
                        fullWidth
                        name="method"
                        label="Lift Method"
                        margin="normal"
                        required
                        disabled={isSubmitting}
                        options={liftMethods.map((method) => ({
                          label: method.label,
                          value: method.machine_name,
                        }))}
                      />
                    </Grid>
                    {values.method === "Timed" && (
                      <Grid item xxs={6}>
                        <DateFormField
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="MM/DD/YYYY"
                          id="lift-date"
                          label="Lift Date"
                          margin="normal"
                          name="lift-date"
                          KeyboardButtonProps={{
                            "aria-label": "change lift date",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <SelectFormField
                    fullWidth
                    name="divisions"
                    label="Affected Divisions"
                    margin="normal"
                    disabled={isSubmitting}
                    options={types.map((type) => ({
                      label: type.name,
                      value: type.tid,
                    }))}
                    multiple
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            size="small"
                            key={value}
                            label={findLabel(types, value)}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  />
                  <TextFormField
                    fullWidth
                    htmlFor="description"
                    name="description"
                    label="Description"
                    margin="normal"
                    disabled={isSubmitting}
                    labelwidth={70}
                    multiline
                  />
                  <FormActions>
                    <Button size="small" onClick={onClose} component="a">
                      Cancel
                    </Button>
                    <ButtonLoader
                      variant="contained"
                      color="primary"
                      type="submit"
                      isSubmitting={isSubmitting}
                      disableElevation
                      size="small"
                    >
                      Update
                    </ButtonLoader>
                  </FormActions>
                </FormContainer>
              )}
            </Formik>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const { func, bool } = PropTypes;
MemberComplianceAdd.propTypes = {
  onClose: func,
  open: bool,
};

MemberComplianceAdd.defaultProps = {
  memberDivisionTypes: [],
};

const mapStateToProps = (state) => ({
  loadingData: state.app.complianceActivity.loading,
  data: state.app.complianceActivity.data,
  changeReasons: state.app.complianceChangeReasons.data,
  loadingReasons: state.app.complianceChangeReasons.loading,
  liftMethods: state.app.liftMethods.data,
  loadingLiftMethods: state.app.liftMethods.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadComplianceActivity: (nid) => dispatch(getComplianceActivity(nid)),
  loadChangeReasons: () => dispatch(getComplianceChangeReasons()),
  loadLiftMethods: () => dispatch(getLiftMethods()),
  addComplianceActivity: (params) => dispatch(postComplianceActivity(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberComplianceAdd);
