import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";

import PageHeaderNav from "./PageHeaderNav";

import "./PageHeader.scss";

const PageHeader = ({
  title,
  hasTitleMenu,
  titleMenu: TitleMenu,
  nav: Nav,
  icon,
  children,
  media,
  hamburger: Hamburger,
}) => {
  const _icon = icon && (
    icon?.src ? (
      <img src={icon.src} alt={icon.alt} className="page-header__icon" />
    ) : (
      React.cloneElement(icon, {
        ...icon.props,
        className: `icon header-icon ${icon.props.className || ""}`,
      })
    )
  );

  return (
    <header className="page-header__wrapper">
      <div className="page-header">
        <div className="page-header__title-wrapper">
          {Hamburger && <Hamburger />}
          {!hasTitleMenu && (
            <Typography variant="h6" className="page-header__title">
              {_icon} {title}
            </Typography>
          )}
          {hasTitleMenu && <>{_icon}<TitleMenu /></>}
        </div>
        {Nav && (
          <PageHeaderNav media={media}>
            <Nav />
          </PageHeaderNav>
        )}
        {children && <div className="page-header__actions">{children}</div>}
      </div>
    </header>
  );
};

const { string, element, object } = PropTypes;
PageHeader.propTypes = {
  title: string.isRequired,
  Nav: element,
  icon: object,
};

PageHeader.defaultProps = {
  Nav: null,
  icon: null,
};

const mapStateToProps = (state) => ({
  isSmall: state.browser.lessThan.small,
});

export default connect(mapStateToProps)(PageHeader);
