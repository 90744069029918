import React, { useCallback, useEffect, useMemo, useState } from "react";
import linkifyIt from "linkify-it";
import { Link, Typography } from "@mui/material";
import tlds from "tlds";
import styled from "styled-components";
import { AiFillYoutube } from "react-icons/ai";
import { FaVimeoSquare, FaGoogle, FaTwitterSquare } from "react-icons/fa";
import { SiImgur } from "react-icons/si";

import {
  getVimeoSrc,
  getYoutubeSrc,
  isVimeo,
  isYoutube,
} from "../../TextEditor/utils";
// import { parser } from "html-metadata-parser";

const icons = {
  YouTube: <AiFillYoutube />,
  Vimeo: <FaVimeoSquare />,
  Google: <FaGoogle />,
  Twitter: <FaTwitterSquare />,
  Imgur: <SiImgur />,
};

const Styles = styled.div`
  .preview {
    border-left: 3px solid var(--color-gray-medium);
    padding: 0.5rem;
    border-radius: 3px;
    margin: 0.25rem 0;

    iframe {
      border-radius: 3px;
    }
  }

  .sitename {
    display: flex;
    align-items: center;
    color: var(--color-secondary);

    & > svg {
      margin-right: 1rem;
    }
  }
`;

const YOUTUBE_PREFIX = "https://www.youtube.com/embed/";
const VIMEO_PREFIX = "https://player.vimeo.com/video/";

const linkify = linkifyIt();
linkify.tlds(tlds);

const Preview = ({ link }) => {
  const meta = {
    title: "",
    url: "",
    description: "",
  };
  const [og, setOg] = useState({
    site_name: "",
    url: "",
    title: "",
    image: "",
    description: "",
    type: "",
  });

  useEffect(() => {
    async function temp() {
      // // try {
      // //   const result = await parser(link);
      // //   console.log(result);
      // //   setMeta(result.meta);
      // //   if (link.indexOf("giphy") >= 0) {
      // //     result.og.image = link;
      // //   }
      // //   setOg(result.og);
      // // } catch (err) {
      // //   console.log(err);
      // // }
    }

    temp();
  }, [link]);

  const extractVideoLink = useCallback((src) => {
    if (isYoutube(src)) {
      const { srcID } = getYoutubeSrc(src);
      setOg((og) => ({
        ...og,
        site_name: "YouTube",
      }));
      return (
        <iframe
          title="Youtube"
          frameBorder="0"
          allowfullscreen
          src={`${YOUTUBE_PREFIX}${srcID}`}
          style={{
            width: "100%",
            maxWidth: "350px",
            height: "250px",
          }}
        />
      );
    }
    if (isVimeo(src)) {
      const { srcID } = getVimeoSrc(src);
      setOg((og) => ({
        ...og,
        site_name: "Vimeo",
      }));
      return (
        <iframe
          title="Vimeo"
          frameBorder="0"
          allowfullscreen
          src={`${VIMEO_PREFIX}${srcID}`}
          style={{
            width: "100%",
            maxWidth: "350px",
            height: "250px",
          }}
        />
      );
    }
    return null;
  }, []);

  const extractedVideo = useMemo(
    () => extractVideoLink(link),
    [link, extractVideoLink]
  );
  if (!extractedVideo) return null;
  return (
    <div className="preview">
      <div className="sitename">
        {icons?.[og?.site_name]}
        <Typography>{og?.site_name}</Typography>
      </div>
      {extractedVideo ? (
        <div>
          <Typography>{meta?.title}</Typography>
          {extractedVideo}
        </div>
      ) : (
        <div className="sitepreview">
          <Link href={link} target="_blank">
            {meta?.title}
          </Link>
          <Typography>{og?.description}</Typography>
          {og?.image && (
            <img
              src={og?.image}
              alt={og.title ? og.title : "Preview Image"}
              style={{
                maxWidth: "350px",
                width: "100%",
                height: "200px",
                borderRadius: "3px",
                marginTop: "0.25rem",
                objectFit: "cover",
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const Previews = ({ data }) => {
  const extractedLinks = useMemo(() => {
    return linkify.match(data);
  }, [data]);

  const getPreviews = useCallback((previews) => {
    const links = [];
    for (let i = 0; i < previews.length; i++) {
      if (i % 2 === 0) {
        links.push(previews[i]);
      }
    }

    return links;
  }, []);

  if (!extractedLinks) return null;
  return (
    <Styles>
      {getPreviews(extractedLinks).map((link, i) => (
        <Preview link={link.url} key={i} />
      ))}
    </Styles>
  );
};

Previews.propTypes = {};

export default Previews;
