import React, { useState, useEffect } from "react";
import { pull, findIndex, size } from "lodash";
import { Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import Button from "common/Button";
import Dialog from "../Dialog";
import { useQuery } from "../../utility";

const TableConfigure = ({ columns, hideColumns, currentColumns }) => {
  const query = useQuery();
  const history = useHistory();
  const [hiddenColumns, setHiddenColumns] = useState(currentColumns);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    checkFragment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (name) => (event) => {
    const currentState = hiddenColumns;
    if (findIndex(currentState, (c) => c === name) !== -1) {
      pull(currentState, name);
    } else {
      currentState.push(name);
    }

    if (size(currentState) === 0) {
      query.delete("hide");
    } else if (query.has("hide")) {
      query.set("hide", currentState);
    } else {
      query.append("hide", currentState);
    }

    history.push(`?${query.toString()}`);

    setHiddenColumns(currentState);
    hideColumns([...currentState]);
  };

  const checkFragment = () => {
    const columnsQuery = query.get("hide");

    if (typeof columnsQuery === "string") {
      const presetColumns = columnsQuery.split(",");
      setHiddenColumns(presetColumns);
      hideColumns([...presetColumns]);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        size="small"
        disableElevation
        disabled={size(columns) === 0}
      >
        Configure
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title="Configure Columns"
        fullWidth={false}
      >
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ marginLeft: "1.25rem" }}
        >
          These will not effect columns on your export
        </Typography>
        <div style={{ padding: "1.25rem" }}>
          <Grid container spacing={1}>
            {columns.map((column) => {
              if (column.id === "changed") return null;
              return (
                <Grid item xxs={6} sm={4} md={3} key={column.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hiddenColumns.indexOf(column.id) === -1}
                        onChange={handleChange(column.id)}
                        value={column.id}
                      />
                    }
                    label={column.label}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

TableConfigure.propTypes = {};

TableConfigure.defaultProps = {
  columns: [],
  currentColumns: [],
};

export default TableConfigure;
