import React, { useEffect } from "react";
import { compose } from "redux";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";

import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportAccountingDKI, clear } from "../../actions";
import Paper from "../Paper";
import {
  formatFilters,
  removeColumns,
  formatMoney,
  removeCurrencyFormatting,
} from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import { jobDivisionTypesSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import Button from "common/Button";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  job_division_name: { minWidth: 160 },
  pushed_to_sage: { minWidth: 175 },
  job_date_recieved: { minWidth: 175 },
  job_created: { minWidth: 175 },
  voided: { minWidth: 50 },
  sage_user_name: { minWidth: 200 },
  provider_fee_invoice_num: { minWidth: 300 },
  subcontractor_fee_invoice_num: { minWidth: 300 },
  tech_fee_invoice_num: { minWidth: 300 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 400 },
  customer_location_city_state: { minWidth: 200 },
  parent_customer_location_name: { minWidth: 300 },
  member_name: { minWidth: 300 },
  affiliation: { minWidth: 175 },
  office_id: { minWidth: 125 },
  pre_tax_job_cost: { minWidth: 165 },
  sage_billable_total: { minWidth: 125 },
  subcontractor: { minWidth: 125 },
  subcontractor_markup: { minWidth: 250 },
  subcontractor_markup_fee_percent: { minWidth: 250 },
  subcontractor_markup_fee_total: { minWidth: 250 },
  tax: { minWidth: 50 },
  provider_fee_percent: { minWidth: 175 },
  provider_fee: { minWidth: 150 },
  subcontractor_fee: { minWidth: 175 },
  tech_fee: { minWidth: 100 },
  file_fee: { minWidth: 100 },
  other: { minWidth: 50 },
  sub_total: { minWidth: 100 },
  subtotal_minus_fees: { minWidth: 200 },
  third_party_expenses: { minWidth: 200 },
  customer_referred_member_name: { minWidth: 350 },
  third_party: { minWidth: 150 },
  other_revenue: { minWidth: 150 },
  total_tech_fee: { minWidth: 150 },
  tech_fee_diff: { minWidth: 150 },
  total_invoiced: { minWidth: 150 },
  billed_amount: { minWidth: 150 },
  variance: { minWidth: 150 },
  referral_mitigation: { minWidth: 200 },
  referred_amount: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  job_division_name,
  pushed_to_sage,
  job_date_recieved,
  job_created,
  voided,
  sage_user_name,
  provider_fee_invoice_num,
  subcontractor_fee_invoice_num,
  tech_fee_invoice_num,
  customer_name,
  customer_location_name,
  customer_location_city_state,
  parent_customer_location_name,
  member_name,
  affiliation,
  office_id,
  pre_tax_job_cost,
  sage_billable_total,
  subcontractor,
  subcontractor_markup,
  subcontractor_markup_fee_percent,
  tax,
  provider_fee_percent,
  provider_fee,
  subcontractor_fee,
  tech_fee,
  file_fee,
  subcontractor_markup_fee_total,
  other,
  sub_total,
  subtotal_minus_fees,
  third_party_expenses,
  third_party,
  other_revenue,
  total_tech_fee,
  tech_fee_diff,
  total_invoiced,
  billed_amount,
  variance,
  customer_referred_member_name,
  referral_mitigation,
  referred_amount,
  paths
) => {
  return {
    job_division_name: job_division_name ? (
      <LinkHeader to={paths.job_division} size="small" color="default">
        {job_division_name}
      </LinkHeader>
    ) : null,
    pushed_to_sage: pushed_to_sage ? (
      <div>
        <Typography variant="body1">
          {moment.unix(pushed_to_sage).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(pushed_to_sage).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    job_date_recieved: job_date_recieved ? (
      <div>
        <Typography variant="body1">
          {moment.unix(job_date_recieved).format("MMM Do, YYYY")}
        </Typography>
      </div>
    ) : null,
    job_created: job_created ? (
      <div>
        <Typography variant="body1">
          {moment.unix(job_created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(job_created).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    voided,
    sage_user_name: sage_user_name ? (
      <LinkHeader to={paths.sage_user} size="small" color="default">
        {sage_user_name}
      </LinkHeader>
    ) : null,
    provider_fee_invoice_num,
    subcontractor_fee_invoice_num,
    tech_fee_invoice_num,
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    customer_location_city_state,
    parent_customer_location_name: parent_customer_location_name ? (
      <LinkHeader
        to={paths.parent_customer_location}
        size="small"
        color="default"
      >
        {parent_customer_location_name}
      </LinkHeader>
    ) : null,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    affiliation,
    office_id,
    pre_tax_job_cost: pre_tax_job_cost ? formatMoney(pre_tax_job_cost) : null,
    sage_billable_total: sage_billable_total
      ? formatMoney(sage_billable_total)
      : null,
    subcontractor: subcontractor ? formatMoney(subcontractor) : null,
    subcontractor_markup: subcontractor_markup
      ? formatMoney(subcontractor_markup)
      : null,
    subcontractor_markup_fee_percent: subcontractor_markup_fee_percent
      ? `${subcontractor_markup_fee_percent}%`
      : null,
    tax: tax ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tax) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tax)}
      </Typography>
    ) : null,
    provider_fee_percent: provider_fee_percent
      ? `${provider_fee_percent}%`
      : null,
    provider_fee: provider_fee ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(provider_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(provider_fee)}
      </Typography>
    ) : null,
    subcontractor_fee: subcontractor_fee ? (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subcontractor_fee)}
      </Typography>
    ) : null,
    tech_fee: tech_fee ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tech_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tech_fee)}
      </Typography>
    ) : null,
    file_fee: file_fee ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(file_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(file_fee)}
      </Typography>
    ) : null,
    subcontractor_markup_fee_total: subcontractor_markup_fee_total ? (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_markup_fee_total) < 0
              ? "red"
              : "black",
        }}
      >
        {formatMoney(subcontractor_markup_fee_total)}
      </Typography>
    ) : null,
    other: other ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other)}
      </Typography>
    ) : null,
    sub_total: sub_total ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(sub_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(sub_total)}
      </Typography>
    ) : null,
    subtotal_minus_fees: subtotal_minus_fees ? (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subtotal_minus_fees) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subtotal_minus_fees)}
      </Typography>
    ) : null,
    third_party_expenses,
    third_party: third_party ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(third_party) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(third_party)}
      </Typography>
    ) : null,
    other_revenue: other_revenue ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other_revenue) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other_revenue)}
      </Typography>
    ) : null,
    total_tech_fee: total_tech_fee ? formatMoney(total_tech_fee) : null,
    tech_fee_diff: tech_fee_diff ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tech_fee_diff) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tech_fee_diff)}
      </Typography>
    ) : null,
    total_invoiced: total_invoiced ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(total_invoiced) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(total_invoiced)}
      </Typography>
    ) : null,
    billed_amount: billed_amount ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(billed_amount) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(billed_amount)}
      </Typography>
    ) : null,
    variance: variance ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(variance) > 0 ? "red" : "black",
        }}
      >
        {formatMoney(variance)}
      </Typography>
    ) : null,
    customer_referred_member_name: customer_referred_member_name ? (
      <LinkHeader
        to={paths.customer_referred_member}
        size="small"
        color="default"
      >
        {customer_referred_member_name}
      </LinkHeader>
    ) : null,
    referral_mitigation: referral_mitigation ? `${referral_mitigation}%` : null,
    referred_amount: referred_amount ? (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(referred_amount) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(referred_amount)}
      </Typography>
    ) : null,
  };
};

const initialFormData = {
  job_division_type_tid: [],
  accounting_created: {
    type: "date-range",
    value: { from: null, through: null },
  },
  pushed_to_sage: {
    type: "date-range",
    value: { from: null, through: null },
  },
  job_date_recieved: {
    type: "date-range",
    value: { from: null, through: null },
  },
  job_created: {
    type: "date-range",
    value: { from: null, through: null },
  },
};

const ReportAccountingDKI = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const divisions = useSelector(jobDivisionTypesSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([
    "tax",
    "sub_total",
    "other",
    "subcontractor_markup_fee",
    "consulting",
    "third_party",
    "other_revenue",
    "billed_amount",
    "voided",
  ]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/job-accountings", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(
      [
        "customer_names",
        "customer_last_updated",
        "customer_last_updated_by_names",
      ],
      temp
    );
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.job_division_name,
        row.pushed_to_sage,
        row.job_date_recieved,
        row.job_created,
        row.voided,
        row.sage_user_name,
        row.provider_fee_invoice_num,
        row.subcontractor_fee_invoice_num,
        row.tech_fee_invoice_num,
        row.customer_name,
        row.customer_location_name,
        row.customer_location_city_state,
        row.parent_customer_location_name,
        row.member_name,
        row.affiliation,
        row.office_id,
        row.pre_tax_job_cost,
        row.sage_billable_total,
        row.subcontractor,
        row.subcontractor_markup,
        row.subcontractor_markup_fee_percent,
        row.tax,
        row.provider_fee_percent,
        row.provider_fee,
        row.subcontractor_fee,
        row.tech_fee,
        row.file_fee,
        row.subcontractor_markup_fee_total,
        row.other,
        row.sub_total,
        row.subtotal_minus_fees,
        row.third_party_expenses,
        row.third_party,
        row.other_revenue,
        row.total_tech_fee,
        row.tech_fee_diff,
        row.total_invoiced,
        row.billed_amount,
        row.variance,
        row.customer_referred_member_name,
        row.referral_mitigation,
        row.referred_amount,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      accounting_created: {
        type: "date-range",
        value: { from: null, through: null },
      },
      pushed_to_sage: {
        type: "date-range",
        value: { from: null, through: null },
      },
      job_date_recieved: {
        type: "date-range",
        value: { from: null, through: null },
      },
      job_created: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Accounting Report (DKI) | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Accounting (DKI)</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={divisions.map((division) => ({
                    label: division.name,
                    value: division.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "job_division_type_tid");
                  }}
                  value={formData.job_division_type_tid}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.accounting_created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "accounting_created",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.accounting_created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "accounting_created",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Pushed
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.pushed_to_sage.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "pushed_to_sage", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.pushed_to_sage.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "pushed_to_sage",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  File Received
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_date_recieved.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "job_date_recieved", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_date_recieved.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "job_date_recieved",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  File Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "job_created", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "job_created",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="job_division_name"
          originalOrder="asc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportAccountingDKI.propTypes = {};

ReportAccountingDKI.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportAccountingDKI(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportAccountingDKI);
