import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add } from "@mui/icons-material";
import { size } from "lodash";

import Alert from "common/Alert";
import Role from "../Role";
import Empty from "../Empty";
import { ListSkeleton } from "../Skeletons";
import { ProfileFile } from "../File";
import { formatField, formatError, hasPermission, getPhoenixClients } from "../../utility";
import { DocActions } from "./Styled";
import MemberDocsAdd from "./MemberDocsAdd";
import MemberDocsEdit from "./MemberDocsEdit";
import { connect } from "react-redux";
import { deleteParagraph } from "../../actions/paragraphs";
import { getMemberFiles } from "../../actions";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { clientsSelectors } from "features/Clients/clientsSlice";
import { filterMemberClients } from "features/Member/utils";
import constants from "components/constants";
import { memberFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { specialMemberFileCategories } from "./utils";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const MemberDocs = ({
  files,
  loading,
  links,
  nid,
  showSnackbar,
  roles,
  canAdd,
  reloadMember,
  archive,
  loadMemberFiles,
  loadingFiles,
  categoryTid,
}) => {
  const classes = useStyles();
  const [openAdd, setOpenAdd] = React.useState(false);
  const [editing, setEditing] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const member = useSelector(getMemberDataSelector);
  const org = useSelector((state) => state.auth.current_client);
  const userClients = useSelector(clientsSelectors.selectAll);
  const categories = useSelector(memberFileCategoriesSelectors.selectAll);
  const categoryName = (categoryTid && categories ? categories.find(( category ) => category.tid === categoryTid)?.name : "");
  let filteredCategories = [];
  if(categoryTid){
    filteredCategories = categories.filter(( category ) => category.tid === categoryTid);
  }
  else{
    filteredCategories = categories.filter(( category ) => !specialMemberFileCategories.includes(category.name));
  }
  const omitExtraInfo = Boolean(specialMemberFileCategories.find(( category ) => category === categoryName)?.length);

  const refresh = (nid, loadMemberFiles, categories, categoryTid) => {
    if (nid) {
      const params = {};
      if(categoryTid){
        params[`_filter_field`] = 'field_member_doc_category';
        params[`_filter_values[0]`] = categoryTid;
      }
      else if(categories){
        params[`_filter_field`] = 'field_member_doc_category';
        categories.forEach((category, i) => {
          if(specialMemberFileCategories.includes(category.name)){
            params[`_filter_excludes[${i}]`] = category.tid;
          }
        });
      }
      loadMemberFiles("node", "field_member_docs", nid, params);
    }
  }

  useEffect(() => {
    refresh(nid, loadMemberFiles, categories, categoryTid);
  }, [nid, loadMemberFiles, categories, categoryTid]);

  const toggleEdit = (file) => {
    setEditing(file);
  };

  const handleDelete = async (id) => {
    setSubmitting(true);
    const response = await archive(id);
    if (response.status === 204) {
      await loadMemberFiles("node", "field_member_docs", nid);
      showSnackbar({
        msg: "Files removed successfully.",
        kind: "positive",
      });
    } else {
      const errorMessage = formatError(response);
      setMessage(errorMessage);
    }
    setSubmitting(false);
  };

  return (
    <>
      {message && <Alert kind={message.id}>{message.msg}</Alert>}
      {canAdd && (
        <Role
          userRoles={roles}
          intendedRoles={[
            "admin",
            "phx_sub_admin",
            "phx_client_admin",
            "operations",
            "accounting",
            "compliance_admin",
          ]}
        >
          <DocActions>
            <Button
              className={classes.button}
              size="small"
              startIcon={<Add />}
              onClick={() => setOpenAdd(true)}
            >
              Add Files
            </Button>
          </DocActions>
        </Role>
      )}
      {loading || loadingFiles ? (
        <ListSkeleton />
      ) : !files.length ? (
        <Empty msg="No files have been added." large />
      ) : (
        files.map((file) => {
          if (!size(file.field_file)) return null;
          const id = formatField(file, "id");
          const name = formatField(file.field_file[0], "filename");
          const category = formatField(
            file.field_member_doc_category[0],
            "name"
          );
          const downloadToken = file.field_file[0]._download_token;
          const fid = formatField(file.field_file[0], "fid");

          let type = category;

        //  Gather Phoenix Client info, omit the clients that this user shouldn't know about.
          const fileClients = getPhoenixClients(file.field_phoenix_clients);

          if (org && !size(fileClients.find((client) => client.nid === org)))
              return null;
          if(member?.field_phoenix_clients && size(member?.field_phoenix_clients) > 1 && size(userClients) > 1){
            let availableClients = filterMemberClients(member?.field_phoenix_clients, fileClients);
            availableClients = filterMemberClients(availableClients, userClients);
            const phxClients = availableClients.map((client) => client.title);
            if(size(phxClients)){
              type = `${category} - (${phxClients.join(", ")})`;
            }
          }

          return (
            <ProfileFile
              key={id}
              name={name}
              url={`/rest/file/${fid}/download`}
              type={type}
              viewUrl={`/rest/file/${fid}/view`}
              editable={hasPermission(roles, [
                "admin",
                "phx_sub_admin",
                "phx_client_admin",
                "operations",
                "accounting",
                "compliance_admin",
              ])}
              onEdit={() => toggleEdit(file)}
              onDelete={
                hasPermission(roles, [
                  "admin",
                  "phx_sub_admin",
                  "phx_client_admin",
                  "operations",
                  "accounting",
                  "compliance_admin",
                ])
                  ? () => handleDelete(id)
                  : null
              }
              isSubmitting={submitting}
              downloadToken={downloadToken}
            />
          );
        })
      )}
      <MemberDocsEdit
        open={Boolean(editing)}
        onClose={() => setEditing(null)}
        file={editing}
        showSnackbar={showSnackbar}
        refresh={() => refresh(nid, loadMemberFiles, categories, categoryTid)}
        categories={filteredCategories}
        omitExtraInfo={omitExtraInfo}
      />
      <MemberDocsAdd
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        links={links}
        nid={nid}
        files={files}
        showSnackbar={showSnackbar}
        refresh={() => refresh(nid, loadMemberFiles, categories, categoryTid)}
        categories={filteredCategories}
        omitExtraInfo={omitExtraInfo}
      />
    </>
  );
};

MemberDocs.propTypes = {};

MemberDocs.defaultProps = {
  files: [],
};

const mapStateToProps = (state) => ({
  files: state.app.memberFiles.data.field_member_docs,
  loadingFiles: state.app.memberFiles.loading,
});

const mapDispatchToProps = (dispatch) => ({
  archive: (pid) => dispatch(deleteParagraph(pid)),
  loadMemberFiles: (entity, field, nid, params) =>
    dispatch(getMemberFiles(entity, field, nid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDocs);
