import React from "react";
import { Link } from "react-router-dom";
import { Tab/*, Tooltip*/ } from "@mui/material";

const StyledTab = (props) => {
  const tab = (
    <Tab
      {...props}
      sx={{
        backgroundColor: props.selected
          ? "var(--color-secondary)"
          : "transparent",
        borderRadius: "3px",
        padding: "0.5rem",
        minHeight: "0",
        color: props.selected ? "white" : "var(--color-secondary)",
        zIndex: 1,
        transition: "background-color 0.25s, opacity 0.25s",
        "&:focus": {},
        "&:hover": {
          opacity: 1,
        },
      }}
    />
  );

// // Can't get it to work
//   if(props?.tooltip){
//     <Tooltip title={props.tooltip}>{tab}</Tooltip>
//   }

  return tab;
};

const LinkTab = ({ nested, ...props }) => {
  return nested ? (
    <StyledTab component={Link} {...props} />
  ) : (
    <Tab component={Link} {...props} />
  );
};

export default LinkTab;
