import React, { useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card, { CardContent, CardHeader } from "common/Card";
import {
  fetchUserTimeCards,
  getUserTimeCardLoadingSelector,
  getUserTimeCardLoadedSelector,
  userTimeCardsSelectors,
  clearLoaded,
} from "./timeClockSlice";

import { getDivisionDataSelector, } from "features/Job/jobSlice";
import "./TimeClock.scss";
import { TimeClockMenuItem, } from "components/TimeClock";
import TimeCardUser from "./components/TimeCardUser";
import { DefaultLoader } from "common/Loader";
import { GiArchiveResearch } from "react-icons/gi";
import Empty from "@phx/common/Empty";

const TimeCardsUserSummary = () => {
  const dispatch = useDispatch();
  const userTimeCards = useSelector(userTimeCardsSelectors);
  const loaded = useSelector(getUserTimeCardLoadedSelector);
  const loading = useSelector(getUserTimeCardLoadingSelector);
  const division = useSelector(getDivisionDataSelector);
  const currentUser = useSelector((state) => state.auth.user.data);

  useEffect(() => {
    if(division?.nid && !loaded){
      const promise = dispatch(
        fetchUserTimeCards({
          id: division?.nid,
          uid: currentUser.uid,
        })
      );

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, division?.nid, loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if(!division?._processed?.has_daily_sheets){
    return;
  }

  return (
    <>
    <Card className="user-time-cards">
      <CardHeader
        className="heading"
        title="Your Time Cards"
        rightActions={
          <TimeClockMenuItem iconOnly menuProps={{className: "button"}} onClose={() => dispatch(clearLoaded())} />
        }
      />
      <CardContent className="content">
        {!userTimeCards ? (
          (loading ? (
            <DefaultLoader />
          ) : (
            <Empty message="No time cards were found">
              <GiArchiveResearch />
            </Empty>
          ))
        ) : (
          <TimeCardUser
            user={userTimeCards}
            onTimeCardClose={() => dispatch(clearLoaded())}
            summary
            isUserSummary
          />
        )}
      </CardContent>
    </Card>
    </>
  );
};

TimeCardsUserSummary.propTypes = {};

export default TimeCardsUserSummary;
