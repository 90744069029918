import React, { useEffect } from "react";
import { compose } from "redux";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";

import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportAccounting, clear } from "../../actions";
import Paper from "../Paper";
import {
  formatFilters,
  removeColumns,
  formatMoney,
  removeCurrencyFormatting,
} from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import { jobDivisionTypesSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import Button from "common/Button";
import { AccountingStatus, accountingStatusOptions } from "components/Accounting/components/AccountingStatus";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  job_division_name: { minWidth: 160 },
  job_division_accounting_title: { minWidth: 160 },
  job_date_recieved: { minWidth: 175 },
  job_created: { minWidth: 175 },
  status_proper: { minWidth: 200 },
  voided: { minWidth: 50 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 400 },
  customer_location_city_state: { minWidth: 200 },
  parent_customer_location_name: { minWidth: 300 },
  member_name: { minWidth: 300 },
  pre_tax_job_cost: { minWidth: 165 },
  tax: { minWidth: 50 },
  provider_fee_percent: { minWidth: 175 },
  provider_fee: { minWidth: 150 },
  tech_fee: { minWidth: 100 },
  file_fee: { minWidth: 100 },
  other: { minWidth: 50 },
  sub_total: { minWidth: 100 },
  subtotal_minus_fees: { minWidth: 200 },
  member_back_charges: { minWidth: 200 },
  back_charge: { minWidth: 200 },
  third_party_expenses: { minWidth: 200 },
  third_party: { minWidth: 150 },
  other_revenue: { minWidth: 150 },
  billed_amount: { minWidth: 150 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  job_division_name,
  job_division_accounting_title,
  job_division_accounting_nid,
  job_date_recieved,
  job_created,
  voided,
  customer_name,
  customer_location_name,
  customer_location_city_state,
  parent_customer_location_name,
  member_name,
  damage_area_group,
  damage_area_group_nid,
  pre_tax_job_cost,
  tax,
  provider_fee_percent,
  provider_fee,
  tech_fee,
  file_fee,
  other,
  sub_total,
  subtotal_minus_fees,
  member_back_charges,
  back_charge,
  third_party_expenses,
  third_party,
  other_revenue,
  third_party_expenses_markup,
  revenue_markup,
  billed_amount,
  profit,
  costs,
  nte,
  nte_exceeded,
  nte_accuracy,
  status,
  progress_created,
  progress_invoiced,
  progress_payment_received,
  progress_member_paid,
  progress_tpes_paid,
  progress_completed,
  progress_voided,
  paths
) => {
  return {
    job_division_name: job_division_name ? (
      <LinkHeader to={paths.job_division} size="small" color="default">
        {job_division_name}
      </LinkHeader>
    ) : null,
    job_division_accounting_title: job_division_name ? (
      <LinkHeader to={`${paths.job_division}/accounting/${job_division_accounting_nid}`} size="small" color="default">
        {job_division_accounting_title}
      </LinkHeader>
    ) : null,
    job_date_recieved: job_date_recieved ? (
      <div>
        <Typography variant="body1">
          {moment.unix(job_date_recieved).format("MMM Do, YYYY")}
        </Typography>
      </div>
    ) : null,
    job_created: job_created ? (
      <div>
        <Typography variant="body1">
          {moment.unix(job_created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(job_created).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    voided,
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    customer_location_city_state,
    parent_customer_location_name: parent_customer_location_name ? (
      <LinkHeader
        to={paths.parent_customer_location}
        size="small"
        color="default"
      >
        {parent_customer_location_name}
      </LinkHeader>
    ) : null,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    damage_area_group: damage_area_group ? (
      <LinkHeader to={`${paths.job_division}/areas/${damage_area_group_nid}`} size="small" color="default">
        {damage_area_group}
      </LinkHeader>
    ) : null,
    pre_tax_job_cost: pre_tax_job_cost ? formatMoney(pre_tax_job_cost) : null,
    tax: tax ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tax) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tax)}
      </Typography>
    ) : null,
    provider_fee_percent: provider_fee_percent
      ? `${provider_fee_percent}%`
      : null,
    provider_fee: provider_fee ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(provider_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(provider_fee)}
      </Typography>
    ) : null,
    tech_fee: tech_fee ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tech_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tech_fee)}
      </Typography>
    ) : null,
    file_fee: file_fee ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(file_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(file_fee)}
      </Typography>
    ) : null,
    other: other ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other)}
      </Typography>
    ) : null,
    sub_total: sub_total ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(sub_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(sub_total)}
      </Typography>
    ) : null,
    subtotal_minus_fees: subtotal_minus_fees ? (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subtotal_minus_fees) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subtotal_minus_fees)}
      </Typography>
    ) : null,
    member_back_charges: member_back_charges ? member_back_charges.split("\n").map((mbc) => <div>{mbc}</div>) : '',
    back_charge: back_charge ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(back_charge) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(back_charge)}
      </Typography>
    ) : null,
    third_party_expenses: third_party_expenses ? third_party_expenses.split("\n").map((tpe) => <div>{tpe}</div>) : '',
    third_party: third_party ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(third_party) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(third_party)}
      </Typography>
    ) : null,
    other_revenue: other_revenue ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other_revenue) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other_revenue)}
      </Typography>
    ) : null,
    billed_amount: billed_amount ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(billed_amount) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(billed_amount)}
      </Typography>
    ) : null,
    third_party_expenses_markup: third_party_expenses_markup ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(third_party_expenses_markup) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(third_party_expenses_markup)}
      </Typography>
    ) : null,
    revenue_markup: revenue_markup ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(revenue_markup) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(revenue_markup)}
      </Typography>
    ) : null,
    profit: profit ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(profit) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(profit)}
      </Typography>
    ) : null,
    costs: costs ? (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(costs) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(costs)}
      </Typography>
    ) : null,
    nte: (nte ?
      <span className={nte && nte_exceeded ? 'text-red-700' : ''}>
        {nte}
        {nte_exceeded && ` (Exceeded)`}
      </span>
       : '-'
    ),
    nte_accuracy: (nte ?
      <span className={nte && nte_exceeded ? 'text-red-700' : 'text-green-500'}>
        {nte_accuracy}%
      </span>
      : '-'
    ),
    status_proper: <AccountingStatus accounting={{status: status}} ignoreNull />,
    progress_created: progress_created ? moment(progress_created).format("MM/DD/YY") : '-',
    progress_invoiced: progress_invoiced ? moment(progress_invoiced).format("MM/DD/YY") : '-',
    progress_payment_received: progress_payment_received ? moment(progress_payment_received).format("MM/DD/YY") : '-',
    progress_member_paid: progress_member_paid ? moment(progress_member_paid).format("MM/DD/YY") : '-',
    progress_tpes_paid: progress_tpes_paid ? moment(progress_tpes_paid).format("MM/DD/YY") : '-',
    progress_completed: progress_completed ? moment(progress_completed).format("MM/DD/YY") : '-',
    progress_voided: progress_voided ? moment(progress_voided).format("MM/DD/YY") : '-',
  };
};

const initialFormData = {
  job_division_type_tid: [],
  status: [],
  job_date_recieved: {
    type: "date-range",
    value: { from: null, through: null },
  },
  job_created: {
    type: "date-range",
    value: { from: null, through: null },
  },
  progress_created: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
  progress_invoiced: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
  progress_payment_received: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
  progress_member_paid: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
  progress_tpes_paid: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
  progress_completed: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
  progress_voided: {
    type: "date-range-string",
    value: { from: null, through: null },
  },
};

const ReportAccounting = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const divisions = useSelector(jobDivisionTypesSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([
    "tax",
    "sub_total",
    "other",
    "consulting",
    "back_charge",
    "third_party",
    "other_revenue",
    "third_party_expenses_markup",
    "revenue_markup",
    "progress_created",
    "progress_invoiced",
    "progress_payment_received",
    "progress_member_paid",
    "progress_tpes_paid",
    "progress_completed",
    "progress_voided",
    "voided",
  ]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/job-accountings", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(
      [
        "customer_names",
        "customer_last_updated",
        "customer_last_updated_by_names",
      ],
      temp
    );
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.job_division_name,
        row.job_division_accounting_title,
        row.job_division_accounting_nid,
        row.job_date_recieved,
        row.job_created,
        row.voided,
        row.customer_name,
        row.customer_location_name,
        row.customer_location_city_state,
        row.parent_customer_location_name,
        row.member_name,
        row.damage_area_group,
        row.damage_area_group_nid,
        row.pre_tax_job_cost,
        row.tax,
        row.provider_fee_percent,
        row.provider_fee,
        row.tech_fee,
        row.file_fee,
        row.other,
        row.sub_total,
        row.subtotal_minus_fees,
        row.member_back_charges,
        row.back_charge,
        row.third_party_expenses,
        row.third_party,
        row.other_revenue,
        row.third_party_expenses_markup,
        row.revenue_markup,
        row.billed_amount,
        row.profit,
        row.costs,
        row.nte,
        row.nte_exceeded,
        row.nte_accuracy,
        row.status,
        row.progress_created,
        row.progress_invoiced,
        row.progress_payment_received,
        row.progress_member_paid,
        row.progress_tpes_paid,
        row.progress_completed,
        row.progress_voided,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
    setHiddenColumns(hiddenColumns.map((column) => column !== name));
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      job_date_recieved: {
        type: "date-range",
        value: { from: null, through: null },
      },
      job_created: {
        type: "date-range",
        value: { from: null, through: null },
      },
      progress_created: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
      progress_invoiced: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
      progress_payment_received: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
      progress_member_paid: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
      progress_tpes_paid: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
      progress_voided: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
      progress_completed: {
        type: "date-range-string",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Accounting Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Accounting</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={divisions.map((division) => ({
                    label: division.name,
                    value: division.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(e, value) => {
                    handleChange(value, "job_division_type_tid");
                  }}
                  value={formData.job_division_type_tid}
                />
                <Autocomplete
                  options={accountingStatusOptions.map((status) => ({
                    label: status.label,
                    value: status.type,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  File Received
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_date_recieved.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "job_date_recieved", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_date_recieved.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "job_date_recieved",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  File Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "job_created", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.job_created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "job_created",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Billing Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_created",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_created",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Invoiced
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_invoiced.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_invoiced",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_invoiced.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_invoiced",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Payment Recevied
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_payment_received.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_payment_received",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_payment_received.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_payment_received",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Provider Paid
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_member_paid.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_member_paid",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_member_paid.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_member_paid",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Third Party Expenses Paid
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_tpes_paid.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_tpes_paid",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_tpes_paid.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_tpes_paid",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Completed
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_completed.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_completed",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_completed.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_completed",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Voided
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_voided.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_voided",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.progress_voided.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "progress_voided",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="progress_created"
          originalOrder="desc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportAccounting.propTypes = {};

ReportAccounting.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportAccounting(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportAccounting);
