import React from "react";
import { CheckboxFormField } from "common/Fields";
import {
  FormHelperText,
  Typography,
} from "@mui/material";

import "common/LinkDropdown/LinkDropdown.scss";

export const ClientSelectorField = ( {clients, errors, label, ...props} ) => {
  let unselected = false;
  let errorText = "";
  if(errors && Object.keys(errors).length > 0){
    Object.entries(errors).forEach(([index, text]) => {
      if(clients.find((client) => `phx_client_nid_${client.nid}` === index)){
        unselected =true;
        errorText = text;
      }
    });
  }

  return (
    <>
    {clients.length > 1 && (
      <>
      {label &&
        <Typography variant="subtitle2">{label}</Typography>
      }
      {clients.map((client) => (
        <CheckboxFormField
          name={`phx_client_nid_${client.nid}`}
          ignoreErrors={true}
          key={client.nid}
          value={client.nid}
          label={client.title}
          {...props}
        />
      ))}
      </>
    )}
    {unselected &&
      <FormHelperText error={true}>
        {errorText}
      </FormHelperText>
    }
    </>
  );
};

ClientSelectorField.propTypes = {};

export default ClientSelectorField;
