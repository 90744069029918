import React from "react";
import styled from "styled-components";
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import FileIcon from "../FileIcon";
import { formatFileSize } from "../../utility";
import constants from "../constants";
import { CheckboxFormField } from "common/Fields";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Details = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background: ${constants.colorGrayLight};
  border-radius: 2px;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 100%;
  background: ${constants.colorGreen};
  transition: width 0.25s;
`;

const Controls = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  & > .MuiFormControl-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const MemberFile = ({ file, options, isSubmitting, formData, setFormData, availableClients, omitExtraInfo }) => {
  const handleCategoryChange = (e) => {
    setFormData({
      ...formData,
      [file.id]: { ...formData[file.id], category: e.target.value },
    });
  };

  const handleDateChange = (value) => {
    setFormData({
      ...formData,
      [file.id]: {
        ...formData[file.id],
        expiration: value ? moment(value).format("YYYY-MM-DD") : null,
      },
    });
  };

  const handleClientChange = (value) => {
    setFormData({
      ...formData,
      [file.id]: {
        ...formData[file.id],
        [value.target.name]: value.target.checked,
      },
    });
  };

  return (
    <Container>
      <FileIcon filename={file.file.name} status={file.status} />
      <Details>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ maxWidth: "215px", lineHeight: "1rem" }}
        >
          {file.file.name}
        </Typography>
        <Typography variant="caption">
          {formatFileSize(file.file.size)}
        </Typography>
      </Details>
      {!omitExtraInfo &&
        <>
        <Controls>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id={`file-category`}>Category</InputLabel>
            <Select
              labelId={`file-category`}
              displayEmpty
              variant="outlined"
              value={formData[file.id] ? formData[file.id].category : ""}
              onChange={handleCategoryChange}
              labelwidth={70}
              disabled={isSubmitting}
            >
              {options.map((category) =>
                category.children.length > 0 ? (
                  [
                  <ListSubheader>{category.name}</ListSubheader>,
                  category.children.map((option) => (
                    <MenuItem key={option.tid} value={option.tid}>
                      {option.name}
                    </MenuItem>
                  ))
                  ]
                ) : (
                  [
                  <MenuItem key={category.tid} value={category.tid}>
                    {category.name}
                  </MenuItem>
                  ]
                )
              )}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              clearable
              fullWidth
              inputVariant="outlined"
              format="MM/DD/YYYY"
              label="Expiration"
              size="small"
              value={formData[file.id]?.expiration ? moment(formData[file.id].expiration) : null}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </Controls>
        </>
      }
      {availableClients.length > 1 && (
        <div className="pl-4">
        {availableClients.map((client) => (
          <CheckboxFormField
            name={`phx_client_nid_${client.nid}`}
            checked={formData?.[file.id]?.[`phx_client_nid_${client.nid}`] ? formData?.[file.id]?.[`phx_client_nid_${client.nid}`] : false}
            ignoreErrors={true}
            key={client.nid}
            // value={client.nid}
            label={client.title}
            onChange={handleClientChange}
            disabled={isSubmitting}
          />
        ))}
        </div>
      )}
      {file.status === 1 && (
        <Progress>
          <Bar style={{ width: `${file.progress}%` }}></Bar>
        </Progress>
      )}
    </Container>
  );
};

MemberFile.propTypes = {};

MemberFile.defaultProps = {
  options: [],
};

export default MemberFile;
