import React, { useEffect, useRef } from "react";
import {
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SendIcon from "@mui/icons-material/Send";
import _, { size } from "lodash";

import ChatAddUsers from "./ChatAddUsers";
import ChatAddEmails from "./ChatAddEmails";
import ButtonLoader from "common/ButtonLoader";
import { Container, TextArea, Actions } from "./Styled";

const getUidOfUsers = (users) => {
  const uids = [];
  if (!users) return uids;
  users.forEach((user) => {
    uids.push({ target_id: Number(user.uid) });
  });

  return uids;
};

const formatExistingUsers = (n, u) => {
  const users = [];
  if (!n || !u) return users;
  const names = n.split(",");
  const uids = u.split(",");

  if (!_.isArray(names) || !_.isArray(names)) return users;
  names.forEach((name, i) => {
    users.push({ name: name, uid: uids[i] });
  });

  return users;
};

const setNativeValue = (element, value) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    "value"
  ).set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
};

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: theme.spacing(1),
  },
}));

const maxTextAreaHeight = 200;

const ChatBar = ({
  handleSubmit,
  stickBottom,
  comment,
  onCancel,
  openFile,
  nid,
  disableEmails,
  roleType,
  assignedUsers,
  divisionJob,
  sentToPilot,
  isFMPilot,
}) => {
  const textArea = useRef(null);
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [saving, setSaving] = React.useState(false);
  const [addUsersAnchorEl, setaddUsersAnchorEl] = React.useState(null);
  const [addEmailsAnchorEl, setaddEmailsAnchorEl] = React.useState(null);
  const [stagedUsers, setStagedUsers] = React.useState([]);
  const [stagedEmails, setStagedEmails] = React.useState([]);
  const [sendToFmPilot, setSendToFmPilot] = React.useState(sentToPilot);
  const [visible, setVisible] = React.useState({
    phx_client: true,
    member: roleType === "phx_client" ? false : true,
    customer: roleType === "phx_client" ? false : true,
  });

  useEffect(() => {
    setVisible({
      phx_client: true,
      member: roleType === "phx_client" ? false : true,
      customer: roleType === "phx_client" ? false : true,
    });
  }, [roleType]);

  useEffect(() => {
    setSaving(false);
    if (comment) {
      setVisible({
        phx_client: comment.visibleTo.includes("phx_client"),
        member: comment.visibleTo.includes("member"),
        customer: comment.visibleTo.includes("customer"),
      });
      setNativeValue(textArea.current, comment.body);
      textArea.current.dispatchEvent(new Event("input", { bubbles: true }));
      setStagedUsers(
        formatExistingUsers(comment.notifyUserNames, comment.notifyUserIds)
      );
      setStagedEmails(
        comment.notifyEmails ? comment.notifyEmails.split(",") : []
      );
    }
  }, []); // eslint-disable-line

  const handleChange = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(
      e.target.scrollHeight,
      maxTextAreaHeight
    )}px`;

    setValue(e.target.value);
  };

  const handleAddUsersClick = (event) => {
    setaddUsersAnchorEl(event.currentTarget);
  };

  const handleAddUsersClose = () => {
    setaddUsersAnchorEl(null);
  };

  const handleAddEmailsClick = (event) => {
    setaddEmailsAnchorEl(event.currentTarget);
  };

  const handleAddEmailsClose = () => {
    setaddEmailsAnchorEl(null);
  };

  const handleChangeUsers = (data) => {
    setStagedUsers(data);
  };

  const handleChangeEmails = (data) => {
    setStagedEmails(data);
  };

  const handleCheck = (e) => {
    setVisible({ ...visible, [e.target.name]: e.target.checked });
  };

  const resetForm = () => {
    setVisible({
      phx_client: true,
      member: roleType === "phx_client" ? false : true,
      customer: roleType === "phx_client" ? false : true,
    });
    setValue("");
    setStagedUsers([]);
    setStagedEmails([]);
    setSendToFmPilot(false);
  };

  const saveComment = async () => {
    setSaving(true);
    const userIds = getUidOfUsers(stagedUsers);
    await handleSubmit({
      cid: comment ? comment.cid : null,
      body: value,
      visible: visible,
      users: userIds,
      emails: stagedEmails,
      sendToFmPilot,
    });
    setSaving(false);
    resetForm();
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (size(value)) {
        saveComment();
      }
    }
  };

  return (
    <Container stickBottom={stickBottom}>
      <TextArea
        onChange={handleChange}
        placeholder="Add to the discussion"
        onKeyDown={onEnterPress}
        value={value}
        ref={textArea}
        disabled={saving}
      />
      <Actions>
        <div>
          {roleType !== "phx_client" && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="phx_client"
                  checked={visible.phx_client}
                  onChange={handleCheck}
                />
              }
              label={divisionJob.field_phoenix_client[0].title[0].value}
            />
          )}
          {roleType !== "member" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="member"
                  size="small"
                  checked={visible.member}
                  onChange={handleCheck}
                />
              }
              label="Member"
            />
          )}
          {roleType !== "customer" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="customer"
                  size="small"
                  checked={visible.customer}
                  onChange={handleCheck}
                />
              }
              label="Customer"
            />
          )}
          {isFMPilot && (
            <FormControlLabel
              control={
                <Checkbox
                  name="sendToFmPilot"
                  size="small"
                  checked={sendToFmPilot}
                  onChange={(e) => setSendToFmPilot(e.target.checked)}
                />
              }
              label="Send to FM Pilot"
            />
          )}
        </div>

        <div>
          {stickBottom && openFile && (
            <IconButton
              aria-label="attach-file"
              size="small"
              className={classes.margin}
              onClick={openFile}
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
          )}
          {!disableEmails && (
            <>
              <IconButton
                aria-label="attach-file"
                size="small"
                className={classes.margin}
                onClick={handleAddEmailsClick}
              >
                <EmailIcon fontSize="inherit" />
              </IconButton>
              <ChatAddEmails
                anchorEl={addEmailsAnchorEl}
                onClose={handleAddEmailsClose}
                onChange={handleChangeEmails}
                stagedEmails={stagedEmails}
              />
            </>
          )}
          <IconButton
            aria-label="attach-file"
            size="small"
            className={classes.margin}
            onClick={handleAddUsersClick}
          >
            <PersonAddIcon fontSize="inherit" />
          </IconButton>
          <ChatAddUsers
            anchorEl={addUsersAnchorEl}
            onClose={handleAddUsersClose}
            nid={nid}
            users={[]}
            assignedUsers={assignedUsers}
            onChange={handleChangeUsers}
            stagedUsers={stagedUsers}
          />
          <IconButton
            aria-label="submit-file"
            size="small"
            onClick={saveComment}
            className={classes.margin}
            disabled={!size(value)}
          >
            <SendIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Actions>
      {!stickBottom && (
        <div style={{ marginBottom: "1rem" }}>
          <ButtonLoader
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            onClick={saveComment}
            isSubmitting={saving}
          >
            Save Changes
          </ButtonLoader>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={() => onCancel(false)}
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </Button>
        </div>
      )}
    </Container>
  );
};

ChatBar.defaultProps = {
  disableEmails: false,
};

export default ChatBar;
