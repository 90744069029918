import React from "react";
import { connect } from "react-redux";
import { Add, MoreHoriz, Launch, Send } from "@mui/icons-material";
import { useRouteMatch, Link } from "react-router-dom";
import {
  Typography,
  Popover,
  IconButton,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import moment from "moment";

import Table from "../../Table";
import Button from "common/Button";
import EmailsForm from "./EmailsForm";
import EmailsArchiveForm from "./EmailsArchiveForm";
import { getCRMEmails } from "../../../actions";
import { ReportSearch } from "../../Reports";
import Email from "./Email";

const columns = [
  { id: "email", label: "Recipients", minWidth: 200 },
  { id: "subject", label: "Subject", minWidth: 200 },
  { id: "sent_time", label: "Date", width: 200 },
  { id: "actions", label: "", width: 50 },
];

const createData = (email, subject, sent_time, actions) => {
  return {
    email,
    subject,
    sent_time: (
      <div>
        <Typography variant="body1">
          {moment.unix(sent_time).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(sent_time).format("h:mm A")}
        </Typography>
      </div>
    ),
    actions,
  };
};

const Actions = ({ pid, url }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <IconButton aria-label="actions" onClick={handleClick} size="large">
      <MoreHoriz />
    </IconButton>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem component={Link} to={`${url}/view/${pid}`}>
        <ListItemIcon>
          <Launch fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Details</Typography>
      </MenuItem>
    </Popover>
  </>;
};

const Emails = ({
  parentEntity,
  loadCRMEmails,
  emails,
  loading,
  pagination,
  shortList,
}) => {
  const { url } = useRouteMatch();
  const { nid } = parentEntity;
  const [searchTerm, setSearchTerm] = React.useState("");

  const rows = React.useMemo(() => {
    if (loading) return [];
    return emails.map((row) =>
      createData(
        row.recipients,
        row.subject,
        row.sent_time,
        <Actions pid={row.nid} url={url} />
      )
    );
  }, [emails, loading, url]);

  return (
    <div>
      <div style={{ padding: "1.25rem" }}>
        <Button
          variant="contained"
          color="tertiary"
          disableElevation
          size="small"
          component={Link}
          to={`${url}/compose`}
          startIcon={<Send />}
        >
          Send
        </Button>
        <Button
          variant="outlined"
          disableElevation
          size="small"
          component={Link}
          to={`${url}/archive`}
          startIcon={<Add />}
          style={{ marginLeft: "1rem" }}
        >
          Add
        </Button>
      </div>
      <ReportSearch setQuery={setSearchTerm} />
      <Table
        columns={columns}
        rows={rows}
        stickyHeader
        loading={loading}
        param={nid}
        hasParam={true}
        pageRequest={loadCRMEmails}
        rowsPerPage={shortList ? 6 : null}
        hideColumns={[]}
        search={searchTerm}
        originalOrderBy="changed"
        originalOrder="desc"
        pagination={pagination}
        size="small"
      />

      <Email url={url} nid={nid} />

      <EmailsForm
        url={url}
        loadCRMEmails={() => loadCRMEmails(nid)}
        nid={nid}
      />
      <EmailsArchiveForm
        url={url}
        loadCRMEmails={() => loadCRMEmails(nid)}
        nid={nid}
      />
    </div>
  );
};

Emails.propTypes = {};

Emails.defaultProps = {
  emails: [],
  pagination: { count: 0, current_page: 0, total_pages: 0 },
};

const mapStateToProps = (state) => ({
  emails: state.app.crmEmails.data.data,
  loading: state.app.crmEmails.loading,
  pagination: state.app.crmEmails.data.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  loadCRMEmails: (nid, params) => dispatch(getCRMEmails(nid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
