import React, { useEffect } from "react";
import { compose } from "redux";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";

import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportWeeklyAccountingDKI, clear } from "../../actions";
import Paper from "../Paper";
import {
  formatFilters,
  removeColumns,
  formatMoney,
  removeCurrencyFormatting,
} from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import { jobDivisionTypesSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import Button from "common/Button";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  job_division_name: { minWidth: 160 },
  accounting_created: { minWidth: 175 },
  voided: { minWidth: 50 },
  job_status_proper: { minWidth: 50 },
  job_category_status: { minWidth: 50 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 300 },
  customer_location_city_state: { minWidth: 200 },
  parent_customer_location_name: { minWidth: 300 },
  member_name: { minWidth: 350 },
  affiliation: { minWidth: 175 },
  office_id: { minWidth: 125 },
  pre_tax_job_cost: { minWidth: 165 },
  sage_billable_total: { minWidth: 125 },
  subcontractor: { minWidth: 125 },
  subcontractor_markup: { minWidth: 250 },
  subcontractor_markup_fee_percent: { minWidth: 250 },
  subcontractor_markup_fee_total: { minWidth: 250 },
  tax: { minWidth: 50 },
  provider_fee_percent: { minWidth: 175 },
  provider_fee: { minWidth: 150 },
  subcontractor_fee: { minWidth: 175 },
  tech_fee: { minWidth: 100 },
  file_fee: { minWidth: 100 },
  other: { minWidth: 50 },
  sub_total: { minWidth: 100 },
  subtotal_minus_fees: { minWidth: 200 },
  third_party_expenses: { minWidth: 200 },
  customer_referred_member_name: { minWidth: 350 },
  third_party: { minWidth: 150 },
  other_revenue: { minWidth: 150 },
  total_tech_fee: { minWidth: 150 },
  tech_fee_diff: { minWidth: 150 },
  total_invoiced: { minWidth: 150 },
  billed_amount: { minWidth: 150 },
  sage_notes: { minWidth: 150 },
  consulting: { minWidth: 100 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  job_division_name,
  accounting_created,
  voided,
  job_status_proper,
  job_category_status,
  customer_name,
  member_name,
  office_id,
  pre_tax_job_cost,
  subcontractor,
  subcontractor_markup,
  subcontractor_markup_fee_percent,
  tax,
  provider_fee,
  subcontractor_fee,
  tech_fee,
  file_fee,
  subcontractor_markup_fee_total,
  other,
  sub_total,
  subtotal_minus_fees,
  third_party_expenses,
  third_party,
  other_revenue,
  total_tech_fee,
  sage_notes,
  sage_billable_total,
  consulting,
  provider_fee_percent,
  customer_location_name,
  customer_location_city_state,
  parent_customer_location_name,
  paths
) => {
  return {
    job_division_name: job_division_name ? (
      <LinkHeader to={paths.job_division} size="small" color="default">
        {job_division_name}
      </LinkHeader>
    ) : null,
    accounting_created: accounting_created ? (
      <div>
        <Typography variant="body1">
          {moment.unix(accounting_created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(accounting_created).format("h:mm A")}
        </Typography>
      </div>
    ) : null,
    voided,
    job_status_proper,
    job_category_status,
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    office_id,
    pre_tax_job_cost: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(pre_tax_job_cost) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(pre_tax_job_cost)}
      </Typography>
    ),
    subcontractor: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(subcontractor) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subcontractor)}
      </Typography>
    ),
    subcontractor_markup: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_markup) < 0
              ? "red"
              : "black",
        }}
      >
        {formatMoney(subcontractor_markup)}
      </Typography>
    ),
    subcontractor_markup_fee_percent: subcontractor_markup_fee_percent
      ? `${subcontractor_markup_fee_percent}%`
      : null,
    tax: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tax) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tax)}
      </Typography>
    ),
    provider_fee: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(provider_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(provider_fee)}
      </Typography>
    ),
    subcontractor_fee: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subcontractor_fee)}
      </Typography>
    ),
    tech_fee: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tech_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tech_fee)}
      </Typography>
    ),
    file_fee: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(file_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(file_fee)}
      </Typography>
    ),
    subcontractor_markup_fee_total: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_markup_fee_total) < 0
              ? "red"
              : "black",
        }}
      >
        {formatMoney(subcontractor_markup_fee_total)}
      </Typography>
    ),
    other: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other)}
      </Typography>
    ),
    sub_total: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(sub_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(sub_total)}
      </Typography>
    ),
    subtotal_minus_fees: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subtotal_minus_fees) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subtotal_minus_fees)}
      </Typography>
    ),
    third_party_expenses,
    third_party: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(third_party) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(third_party)}
      </Typography>
    ),
    other_revenue: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other_revenue) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other_revenue)}
      </Typography>
    ),
    total_tech_fee: total_tech_fee ? formatMoney(total_tech_fee) : null,
    sage_billable_total: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(sage_billable_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(sage_billable_total)}
      </Typography>
    ),
    sage_notes,
    consulting: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(consulting) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(consulting)}
      </Typography>
    ),
    provider_fee_percent: provider_fee_percent
      ? `${provider_fee_percent}%`
      : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    customer_location_city_state,
    parent_customer_location_name: parent_customer_location_name ? (
      <LinkHeader
        to={paths.parent_customer_location}
        size="small"
        color="default"
      >
        {parent_customer_location_name}
      </LinkHeader>
    ) : null,
  };
};

const createTotals = (
  pre_tax_job_cost,
  subcontractor,
  subcontractor_markup,
  tax,
  provider_fee,
  subcontractor_fee,
  tech_fee,
  file_fee,
  subcontractor_markup_fee_total,
  other,
  sub_total,
  subtotal_minus_fees,
  third_party,
  other_revenue,
  total_tech_fee,
  sage_billable_total,
  consulting
) => {
  return {
    pre_tax_job_cost: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(pre_tax_job_cost) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(pre_tax_job_cost)}
      </Typography>
    ),
    subcontractor: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(subcontractor) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subcontractor)}
      </Typography>
    ),
    subcontractor_markup: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_markup) < 0
              ? "red"
              : "black",
        }}
      >
        {formatMoney(subcontractor_markup)}
      </Typography>
    ),
    tax: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tax) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tax)}
      </Typography>
    ),
    provider_fee: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(provider_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(provider_fee)}
      </Typography>
    ),
    subcontractor_fee: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subcontractor_fee)}
      </Typography>
    ),
    tech_fee: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(tech_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(tech_fee)}
      </Typography>
    ),
    file_fee: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(file_fee) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(file_fee)}
      </Typography>
    ),
    subcontractor_markup_fee_total: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subcontractor_markup_fee_total) < 0
              ? "red"
              : "black",
        }}
      >
        {formatMoney(subcontractor_markup_fee_total)}
      </Typography>
    ),
    other: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other)}
      </Typography>
    ),
    sub_total: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(sub_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(sub_total)}
      </Typography>
    ),
    subtotal_minus_fees: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(subtotal_minus_fees) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(subtotal_minus_fees)}
      </Typography>
    ),
    third_party: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(third_party) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(third_party)}
      </Typography>
    ),
    other_revenue: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(other_revenue) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(other_revenue)}
      </Typography>
    ),
    total_tech_fee: total_tech_fee ? formatMoney(total_tech_fee) : null,
    sage_billable_total: (
      <Typography
        variant="body1"
        style={{
          color:
            removeCurrencyFormatting(sage_billable_total) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(sage_billable_total)}
      </Typography>
    ),
    consulting: (
      <Typography
        variant="body1"
        style={{
          color: removeCurrencyFormatting(consulting) < 0 ? "red" : "black",
        }}
      >
        {formatMoney(consulting)}
      </Typography>
    ),
  };
};

const initialFormData = {
  job_division_type_tid: [],
  job_status: [],
  sage_notes: [{ label: "Not Pushed", value: "Not Pushed" }],
  voided: null,
  accounting_created: {
    type: "date-range",
    value: { from: null, through: null },
  },
};

const defaultFilters = {
  sage_notes: [{ label: "Not Pushed", value: "Not Pushed" }],
};

const ReportWeeklyAccountingDKI = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const divisions = useSelector(jobDivisionTypesSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState(defaultFilters);
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/job-accountings/weekly", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns([], temp);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.job_division_name,
        row.accounting_created,
        row.voided,
        row.job_status_proper,
        row.job_category_status,
        row.customer_name,
        row.member_name,
        row.office_id,
        row.pre_tax_job_cost,
        row.subcontractor,
        row.subcontractor_markup,
        row.subcontractor_markup_fee_percent,
        row.tax,
        row.provider_fee,
        row.subcontractor_fee,
        row.tech_fee,
        row.file_fee,
        row.subcontractor_markup_fee_total,
        row.other,
        row.sub_total,
        row.subtotal_minus_fees,
        row.third_party_expenses,
        row.third_party,
        row.other_revenue,
        row.total_tech_fee,
        row.sage_notes,
        row.sage_billable_total,
        row.consulting,
        row.provider_fee_percent,
        row.customer_location_name,
        row.customer_location_city_state,
        row.parent_customer_location_name,
        row._paths
      )
    );
  }, [report, loading]);

  const totals = React.useMemo(() => {
    if (loading || !pagination.row_totals) return [];
    return createTotals(
      pagination.row_totals.pre_tax_job_cost,
      pagination.row_totals.subcontractor,
      pagination.row_totals.subcontractor_markup,
      pagination.row_totals.tax,
      pagination.row_totals.provider_fee,
      pagination.row_totals.subcontractor_fee,
      pagination.row_totals.tech_fee,
      pagination.row_totals.file_fee,
      pagination.row_totals.subcontractor_markup_fee_total,
      pagination.row_totals.other,
      pagination.row_totals.sub_total,
      pagination.row_totals.subtotal_minus_fees,
      pagination.row_totals.third_party,
      pagination.row_totals.other_revenue,
      pagination.row_totals.total_tech_fee,
      pagination.row_totals.sage_billable_total,
      pagination.row_totals.consulting
    );
  }, [pagination.row_totals, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setFormData({
      ...initialFormData,
      accounting_created: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Weekly Accounting Report (DKI) | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Weekly Accounting (DKI)</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={divisions.map((division) => ({
                    label: division.name,
                    value: division.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "job_division_type_tid");
                  }}
                  value={formData.job_division_type_tid}
                />
                <Autocomplete
                  options={[
                    { label: "Invoicing", value: "ar" },
                    { label: "Complete", value: "complete" },
                    { label: "Closed", value: "closed" },
                  ].map((status) => ({
                    label: status.label,
                    value: status.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "job_status");
                  }}
                  value={formData.job_status}
                />
                <Autocomplete
                  options={[
                    { label: "Not Pushed", value: "Not Pushed" },
                    { label: "Manual Entry", value: "Manual Entry" },
                    { label: "Automatic", value: "Automatic" },
                  ].map((status) => ({
                    label: status.label,
                    value: status.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sage Notes"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "sage_notes");
                  }}
                  value={formData.sage_notes}
                />
                <Autocomplete
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ].map((status) => ({
                    label: status.label,
                    value: status.value,
                  }))}
                  fullWidth
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Voided"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "voided");
                  }}
                  value={formData.voided}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.accounting_created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "accounting_created",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.accounting_created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "accounting_created",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="job_division_name"
          originalOrder="asc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
          totals={totals}
        />
      </Paper>
    </div>
  );
};

ReportWeeklyAccountingDKI.propTypes = {};

ReportWeeklyAccountingDKI.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
    row_totals: {},
  },
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportWeeklyAccountingDKI(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportWeeklyAccountingDKI);
