import React from "react";
import { Checkbox, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import truncateString from "@uppy/utils/lib/truncateString";

import { useImageLoad } from "../../hooks";

import { getIconByMime } from "./utils";
import { filesSelectors, jobDivisionFilesSelectors } from "../../features/Files/filesSlice";
import { workAreasSelectors } from "features/WorkAreas/workAreasSlice";
import { chambersSelectors } from "features/Chambers/chambersSlice";
import moment from "moment";
import { FaUnlock, FaLock } from "react-icons/fa";

const renderFileName = (props) => {
  // Take up at most 2 lines on any screen
  let maxNameLength;
  // For very small mobile screens
  if (props.containerWidth <= 352) {
    maxNameLength = 35;
    // For regular mobile screens
  } else if (props.containerWidth <= 576) {
    maxNameLength = 60;
    // For desktops
  } else {
    maxNameLength = 30;
  }

  if (!props.name) return null;
  return (
    <div
      style={{
        fontSize: 14,
        lineHeight: 1.3,
        fontWeight: 500,
        marginBottom: 4,
        wordBreak: "break-all",
        wordWrap: "anywhere",
      }}
      title={props.name}
    >
      {truncateString(props.name, maxNameLength)}
    </div>
  );
};

const renderFileSubtext = (props) => {
  return (
    <div
      style={{
        fontSize: 12,
        lineHeight: 1.3,
        fontWeight: 500,
      }}
    >
      {props.subtext}
    </div>
  );
};

const FileIcon = ({ file }) => {
  const { color, icon } = getIconByMime(file.filemime);

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div
        style={{
          height: "150px",
          maxHeight: "80%",
          position: "relative",
        }}
      >
        <span
          style={{
            width: "25px",
            height: "25px",
            zIndex: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color,
          }}
        >
          {icon}
        </span>
        <svg
          aria-hidden="true"
          focusable="false"
          style={{
            width: "100%",
            height: "100%",
            filter: "drop-shadow(rgba(0,0,0, 0.1) 0px 1px 1px)",
          }}
          width="58"
          height="76"
          viewBox="0 0 58 76"
        >
          <rect fill={color} width="58" height="76" rx="3" fillRule="evenodd" />
        </svg>
      </div>
    </div>
  );
};

const FileInfo = ({
  file,
  fileNode,
  subtext,
  caption,
  popover,
  created,
  dateTime,
  selected,
  referenceNid,
  forceFileOverride,
  published,
  onClickAction,
  uploader,
}) => {
  const area = useSelector(
    (state) =>
    workAreasSelectors.selectById(state, referenceNid)
  );
  const chamber = useSelector(
    (state) =>
    chambersSelectors.selectById(state, referenceNid)
  );

  if (!file && !forceFileOverride) return null;
  if (forceFileOverride) file = forceFileOverride;
  let pubText = '';
  let pubTitle = '';
  if(typeof published !== 'undefined'){
    if(published){
      pubText = <FaUnlock className="text-gray-dark"/>;
      pubTitle = "This file is viewable to anyone with its link.";
    }
    else{
      pubText = <FaLock className="text-gray-dark"/>;
      pubTitle = "This file is only accessible to appropriate Helix users.";
    }
  }

  const _created = (created ? moment.unix(created).format("MMM Do, YYYY") : null);
  const _dateTime = (dateTime ? moment(dateTime).format("MMM Do, YYYY") : null);
  // const published = file?.published;

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      <Typography
        variant="caption"
        color="grey"
        title={pubTitle}
        className="pt-1 pr-2"
      >
        {pubText}
      </Typography>
      <div style={{ flex: 1 }} onClick={onClickAction}>
        <Typography variant="caption">{caption}</Typography>
        {renderFileName({ name: (fileNode ? fileNode?.title : file?.filename) })}
        {renderFileSubtext({ subtext })}
        {area && <Typography variant="caption" component="div">{area.title}</Typography>}
        {chamber && <Typography variant="caption" component="div">{chamber.title}</Typography>}
        {uploader &&
          <Typography variant="caption" component="div">
            {` (Uploaded by ${uploader})`}
          </Typography>
        }
        {(_created || _dateTime ) &&
          <Typography variant="caption">
            {_dateTime ? (
              <>
              <div>{_dateTime}</div>
              {(_created && _dateTime !== _created) && <div>{` (Uploaded ${_created})`}</div>}
              </>
            ) : (
              _created
            )}
          </Typography>
        }
      </div>
      <div>{popover}</div>
    </div>
  );
};

const FilePreview = ({ file }) => {
  const token = useSelector((state) => state.auth.token);
  let url = file.uri.replace("private://", "");
  if (file.thumb) url = file.thumb;
  const [loading, image] = useImageLoad(`/system/files/${url}`, token);

  if (!image || loading) return <FileIcon file={file} />;

  return (
    <img
      className="w-full h-full object-contain"
      alt={file.filename}
      src={image}
    />
  );
};

const FilePreviewContainer = ({
  file,
  inline,
  onClick,
  selected,
  forceFileOverride,
}) => {
  if (!file && !forceFileOverride) return null;
  if (forceFileOverride) file = forceFileOverride;
  const fileType = file.filemime.split("/")[0];

  const selectedClasses = (!selected ? 'bg-gray-light border-gray-middle' : 'bg-blue-lighter border-blue-dark')
  return (
    <div className={`h-[150px] lg:h-[150px] overflow-hidden  rounded-lg border border-solid ${selectedClasses}`} onClick={onClick ? onClick : null}>
      {/* <div
        style={{
          backgroundColor: "white",
          width: inline ? 58 : "100%",
          height: inline ? 76 : "100%",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0 0 2px 0 rgb(0 0 0 / 40%)",
          borderRadius: "3px",
          marginRight: inline ? "1rem" : 0,
          border: selected ? "2px solid var(--color-secondary)" : "none",
        }}
      > */}
        {fileType === "image" ? (
          <FilePreview file={file} />
        ) : (
          <FileIcon file={file} />
        )}
      {/* </div> */}
    </div>
  );
};

const FileItem = ({
  fid,
  nid,
  subtext,
  created,
  caption,
  popover,
  inline,
  onClick,
  onSelect,
  selected,
  referenceNid,
  dateTime,
  forceFileOverride,
  published,
  uploader,
  blocked,
}) => {
  const file = useSelector((state) => filesSelectors.selectById(state, fid));
  const fileNode = useSelector((state) => jobDivisionFilesSelectors.selectById(state, nid));

  return (
    <div
      style={{
        padding: "0.75rem",
        cursor: onClick ? "pointer" : "default",
        borderRadius: 2,
        position: "relative",
        display: inline ? "flex" : "block",
        background: selected
          ? "var(--color-gray-background)"
          : (blocked ? "var(--color-gray-light)" : "transparent"),
        opacity: blocked ? 0.4 : 1,
      }}
    >
      {onSelect &&
        <Checkbox
          size="small"
          className={`absolute -top-2 -left-2 ${!blocked ? 'hover:bg-gray-light' : ''} ${!selected ? 'bg-white' : 'bg-gray-light'}`}
          checked={selected}
          onChange={onSelect ? onSelect : () => {}}
          disabled={blocked}
        />
      }
      <FilePreviewContainer
        file={file}
        inline={inline}
        onClick={onClick}
        selected={selected}
        forceFileOverride={forceFileOverride}
      />
      <div
        style={inline ? { flex: 1 } : { paddingTop: 10 }}
      >
        <FileInfo
          file={file}
          fileNode={fileNode}
          subtext={subtext}
          caption={caption}
          popover={popover}
          dateTime={dateTime}
          referenceNid={referenceNid}
          created={created}
          forceFileOverride={forceFileOverride}
          published={published}
          uploader={uploader}
          onClickAction={(!blocked && onSelect) ? onSelect : () => {}}
        />
      </div>
    </div>
  );
};

FileItem.defaultProps = {
  blocked: false,
};

export default FileItem;
