import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import { Container, Indicator, IndicatorWrapper } from "./Styled";
import constants from "../constants";

const getStatusColor = (status) => {
  const color = constants.statusColors[status]
    ? constants.statusColors[status]
    : constants.colorGray;
  const backgroundColor = chroma(color).alpha(0.25);
  const fontColor = chroma(color).darken(2);

  return [color, backgroundColor, fontColor];
};

const Status = ({ children, type, size, indicator, onClick, loading }) => {
  const [color, backgroundColor, fontColor] = getStatusColor(type);

  return indicator ? (
    <IndicatorWrapper size={size}>
      <Indicator border={color} bkg={backgroundColor} color={fontColor} />
      {children}
    </IndicatorWrapper>
  ) : (
    <Container
      size={size}
      border={color}
      bkg={backgroundColor}
      color={fontColor}
      onClick={onClick ? onClick : null}
      style={{ cursor: onClick ? "pointer" : "inherit" }}
    >
      {loading ? "Loading" : children}
    </Container>
  );
};

const { string, oneOf, bool } = PropTypes;
Status.propTypes = {
  type: string,
  size: oneOf(["small", "large"]),
  indicator: bool,
};

Status.defaultProps = {
  type: "default",
  size: "large",
};

export default Status;
