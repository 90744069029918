import React, { useState } from "react";
import {
  Tooltip,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material";
import Dialog from "common/Dialog";
import { ReactComponent as TimeCardIconRaw } from "assets/images/icons/icon-time-card.svg";
import styled from "styled-components";
import TimeClock from "./TimeClock";
// import "./TimeClock.scss";

const TimeCardIcon = styled(TimeCardIconRaw)`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
`;

const TimeClockDialog = ({divisionHash, serviceHash, title, fullButton, onClose, disabled, children}) => {
  const [open, setOpen] = useState(false);
  const [newServiceHash, setNewServiceHash] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const _title = (
    title ? (title) : (
      <><TimeCardIcon /> Time Card</>
    )
  );

  return (
    <>
    {!children ? (
      isMobile || fullButton ? (
        <MenuItem onClick={() => setOpen(true)}>
            <>
            <ListItemIcon>
              <TimeCardIcon />
            </ListItemIcon>
            <Typography variant="inherit">Time Card</Typography>
            </>
        </MenuItem>
      ) : (
        <Tooltip title="Time Card">
          <IconButton
            size="small"
            onClick={() => setOpen(true)}
            style={{ marginRight: "0.5rem" }}
          >
            <TimeCardIcon />
          </IconButton>
        </Tooltip>
      )
    ) : (
      React.cloneElement(children, {
        onClick: () => {
          if(!disabled){
            setOpen(true)
          }
        },
      })
    )}
      <Dialog
        open={open}
        onClose={() => {
          if(onClose){
            onClose();
          }
          setOpen(false)
        }}
        title={_title}
        paperProps={{className: "time-clock-dialog"}}
      >
        <TimeClock
          divisionHash={divisionHash}
          serviceHash={newServiceHash ? newServiceHash : serviceHash}
          setServiceHash={setNewServiceHash}
          newTimeCard={!Boolean(serviceHash)}
          timeCardOnly
        />
      </Dialog>
    </>
  );
};

TimeClockDialog.propTypes = {};

export default TimeClockDialog;
