import moment from "moment-timezone";
import constants from "components/constants";
import { size } from "lodash";

export const processTimeClock = (timeClock) => {

  localStorage.removeItem("time_clock_service");
  if(timeClock?.service && timeClock.service !== ''){
    localStorage.setItem("time_clock_service", timeClock.service);
  }

  localStorage.removeItem("time_clock_temp_user");
  if(timeClock?.temp_user && timeClock.temp_user !== ''){
    localStorage.setItem("time_clock_temp_user", timeClock.temp_user);
  }

  localStorage.removeItem("time_clock_user_account");
  if(timeClock?.user_account && timeClock.user_account !== ''){
    localStorage.setItem("time_clock_user_account", timeClock.user_account);
  }

  return timeClock;
}

export const parseTimeClockParams = (params) => {

  const serviceHash = localStorage.getItem("time_clock_service");
  if(!params.service && serviceHash && serviceHash !== ''){
    params.service = serviceHash;
  }
  const tempUserHash = localStorage.getItem("time_clock_temp_user");
  if(tempUserHash && tempUserHash !== ''){
    params.temp_user = tempUserHash;
  }
  const userAccount = localStorage.getItem("time_clock_user_account");
  if(userAccount && userAccount !== ''){
    params.user_account = userAccount;
  }

  return params;
}

export const getParamTime = (data, index, breakIndex, dateMoment, _time) => {

  const _date = (!dateMoment ? data.service_date : dateMoment);
  let newTime = moment();
  if(_time){
    newTime = moment(_time);
  }
  else if(data[index]){
    newTime = moment(data[index]);
  }
  else if(index === "service_break_start" || index === "service_break_end"){
    const _index = index.replace(/service_break_/, '');
    data.service_breaks.forEach((breakTime, i) => {
      if(i === breakIndex && breakTime[_index]){
        newTime = moment(breakTime[_index]);
      }
    })
  }
  newTime = newTime
              .set('year', _date.get('year'))
              .set('month', _date.get('month'))
              .set('date', _date.get('date'))
              .format(constants.iso8601);
  let lastTime;
  if(index === "service_end"){
    lastTime = moment(data.service_start);
    if(size(data.service_breaks)){
      data.service_breaks.forEach((breakTime, i) => {
        lastTime = moment(breakTime.start);
        if(breakTime.end && breakTime.end !== ''){
          lastTime = moment(breakTime.end);
        }
      })
    }
  }
  else if(index === "service_break_start"){
    lastTime = moment(data.service_start);
    if(Number(breakIndex) > 0){
      data.service_breaks.forEach((breakTime, i) => {
        if(i < breakIndex){
          lastTime = moment(breakTime.start);
          if(breakTime.end && breakTime.end !== ''){
            lastTime = moment(breakTime.end);
          }
        }
      })
    }
  }
  else if(index === "service_break_end"){
    data.service_breaks.forEach((breakTime, i) => {
      if(i === breakIndex){
        lastTime = moment(breakTime.start);
      }
    })
  }

//  If the last time is the same or after the new time, either
//    check if the last time is within a few minutes of the new time, and make the new time a minute later than the last time
//      (This would be because a user clocked in and out in quick succession)
//    otherwise, the 2 times likely span midnight; so, make the new time the next day
  if(lastTime && lastTime.unix() >= moment(newTime).unix()){
    if(lastTime.unix() <= moment(newTime).add(3, 'minutes').unix()){
      newTime = moment(lastTime).add(1, 'minutes').format(constants.iso8601);
    }
    else{
      const nextDay = moment(_date).add(1, 'd');
      newTime = getParamTime(data, index, breakIndex, nextDay, newTime);
    }
  }
  return newTime;
}

export const parseHours = (hrs) => {
  const hours = Number(hrs);
  if(!hours){
    return '-';
  }
  if(hours !== Math.floor(hours)){
    return `${hours.toFixed(2)}hr${hours > 1 ? 's' : ''}`;
  }
  return `${hours}hr${hours > 1 ? 's' : ''}`;
}

/**
 * Format the location information for a times (clock_in, break_start, etc...)
 *
 * @param object timeClock
 * @param array actions
 * @returns
 */
export const getTimeLocation = (timeClock, actions, index) => {
  if(!timeClock?.service_locations || !size(timeClock.service_locations)){
    return;
  }

  const locations = [];
  actions.forEach((action, i) => {
    let location = null;
    let inc = 0;
    timeClock.service_locations.forEach((_loc) => {
      if(_loc.action === action){
        if(typeof index === 'undefined' || index === inc){
          location = _loc;
        }
        inc++;
      }
    });;
    if(location){
      let loc = null;
      if(typeof location.location === "string"){
        loc = location.location;
      }
      else{
        loc = location.location.formatted;
      }
      if(locations.indexOf(loc) < 0){
        locations.push(loc);
      }
    }
  });
  if(!size(locations)){
    return;
  }

  return (
    <div className="location">
    {`(`} {locations.map((location, i) => `${i > 0 ? ` - ` : ''} ${location}`)} {`)`}
    </div>
  );
}

export const ignoreTempUserEmail = (timeClock) => {
  let ignored = localStorage.getItem("ignored_temp_user_email_reqs");
  if(ignored){
    ignored = JSON.parse(ignored)
                .find((ign) =>
                  ign.hash === timeClock.temp_user
                  && ign.timestamp > moment().subtract(6, 'd').unix()
                );
    if(ignored){
      return true;
    }
  }
  return false;
}

export const removeTempUserFromIgnoreList = (timeClock) => {
  let ignored = localStorage.getItem("ignored_temp_user_email_reqs");
  if(!ignored){
    ignored = [];
  }
  else{
    ignored = JSON.parse(ignored);
  }
  const removed = ignored.filter((ign) => ign.hash !== timeClock.temp_user);

  if(size(removed)){
    localStorage.setItem("ignored_temp_user_email_reqs", JSON.stringify([
      ...removed,
    ]));
  }
  else{
    localStorage.removeItem("ignored_temp_user_email_reqs");
  }
  return removed;
}

export const setIgnoreTempUserEmail = (timeClock) => {
  const removed = removeTempUserFromIgnoreList(timeClock);

  localStorage.setItem("ignored_temp_user_email_reqs", JSON.stringify([
    {hash: timeClock.temp_user, timestamp: moment().unix()},
    ...removed,
  ]));
}
