import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Collapse,
} from "@mui/material";
import styled from "styled-components";
import { size } from "lodash";

import constants from "../constants";
import { useLocalStorage } from "../../hooks";
import Empty from "../Empty";
import { TableSkeleton } from "../Skeletons";
import { LoaderContainer } from "./Styled";
import { formatFilters, formatProximitySearch } from "../../utility";

import "./Table.scss";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const StyledTableRow = styled(TableRow)`
  background: ${({ open }) =>
    open ? constants.colorGrayLightest : "white"} !important;

  &.MuiTableRow-hover:hover {
    background: ${({ open }) =>
      open
        ? constants.colorGrayLightest
        : constants.colorGrayLightest} !important;
  }

  &:nth-child(4n + 1) {
    background: ${constants.colorGrayLightest} !important;
    background: ${({ open }) =>
      open
        ? constants.colorGrayLightest
        : constants.colorGrayLightest} !important;
  }

  &:nth-child(odd) {
    td {
      border-bottom: none !important;
    }
  }
`;

const Body = styled.div`
  padding: 1rem 0;
`;

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  hideColumns,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            width: 30,
          }}
        ></TableCell>
        {headCells.map((headCell) =>
          hideColumns.includes(headCell.id) ? null : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                minWidth: headCell.minWidth ? headCell.minWidth : "auto",
                width: headCell.width ? headCell.width : "auto",
              }}
            >
              {!headCell.notSortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "desc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className="visually-hidden">
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

const { func, oneOf, string, array } = PropTypes;
EnhancedTableHead.propTypes = {
  onRequestSort: func.isRequired,
  order: oneOf(["asc", "desc"]).isRequired,
  orderBy: string.isRequired,
  hideColumns: array,
};

function Row(props) {
  const { row, columns, hideColumns, offset } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        onClick={() => setOpen(!open)}
        open={open}
      >
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            color: "var(--color-gray-dark)",
          }}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return hideColumns.includes(column.id) ? null : (
            <TableCell
              key={column.id}
              align={column.align}
              padding={column.disablePadding ? "none" : "normal"}
            >
              {column.format && typeof value === "number"
                ? column.format(value)
                : value}
            </TableCell>
          );
        })}
      </StyledTableRow>
      <StyledTableRow open={open} hover>
        {offset && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
        )}
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={
            !offset ? size(columns) : size(columns) > 0 ? size(columns) - 1 : 0
          }
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Body>{row.collapsible}</Body>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </>
  );
}

const CollapsibleTable = ({
  columns,
  rows,
  size,
  stickyHeader,
  onRow,
  loading,
  originalOrder,
  originalOrderBy,
  pagination,
  pageRequest,
  filters,
  search,
  hideColumns,
  param,
  hasParam,
  noPagination,
  emptyMessage,
  offset,
  clientFilter,
  org,
  rowsPerPage,
  refreshPage,
}) => {
  const [page, setPage] = React.useState(0);
  const [_rowsPerPage, setRowsPerPage] = useLocalStorage("rowsPerPage", 50);
  rowsPerPage = rowsPerPage ? rowsPerPage : _rowsPerPage;
  const [order, setOrder] = React.useState(originalOrder);
  const [orderBy, setOrderBy] = React.useState(originalOrderBy);
  const [query, setQuery] = React.useState({ page: 0 });
  let perRowOptions = [25, 50, 100, 200];
  if(!perRowOptions.includes(rowsPerPage)){
    perRowOptions.push(rowsPerPage);
  }

  useEffect(() => {
    let querystring = {};
    if(filters?.proximitySettings){
      if(Object.keys(filters.proximitySettings).length){
        const proximitySettings = formatProximitySearch(filters.proximitySettings);
        querystring = {
          ...proximitySettings,
        };
      }
    }
    let _order = order;
    if(filters?.adjustedOrder){
      _order = filters.adjustedOrder;
    }
    let _orderBy = orderBy;
    if(filters?.adjustedOrderBy){
      _orderBy = filters.adjustedOrderBy;
    }
    const filterSettings = formatFilters(filters);

    querystring = {
      ...querystring,
      keywords: search,
      order: _orderBy,
      sort: _order,
      page: page,
      rows_per_page: rowsPerPage,
      ...filterSettings,
    };

    if (org && clientFilter) {
      querystring = {
        ...querystring,
        ...formatFilters({ [clientFilter]: [{ value: org }] }),
      };
    }

    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }

    setQuery(querystring);
    setPage(0);
  }, [filters, param, org, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!query.keywords && !search.length) return;
    const querystring = {
      ...query,
      keywords: search,
    };

    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }

    setQuery(querystring);
    setPage(0);
  }, [search, param]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    const querystring = { ...query, page: newPage, rows_per_page: rowsPerPage };
    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }

    setQuery(querystring);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    const querystring = { ...query, rows_per_page: newRowsPerPage };
    if (pageRequest && param) {
      pageRequest(param, querystring);
    } else if (pageRequest) {
      pageRequest(querystring);
    }

    setRowsPerPage(newRowsPerPage);
    setQuery(querystring);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const querystring = {
      ...query,
      order: property,
      sort: isAsc ? "desc" : "asc",
    };
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQuery(querystring);

    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }
  };

  const { count, current_page } = pagination;
  return (
    <section className="table">
      <TableContainer className="table__wrapper">
        {loading ? (
          <LoaderContainer>
            <TableSkeleton />
          </LoaderContainer>
        ) : !rows.length ? (
          <Empty
            large
            msg={emptyMessage ? emptyMessage : "No data to display."}
          />
        ) : (
          <MaterialTable
            aria-label="Table"
            size={size}
            stickyHeader={stickyHeader}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
              hideColumns={hideColumns}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <Row
                    key={index}
                    columns={columns}
                    row={row}
                    hideColumns={hideColumns}
                    offset={offset}
                  />
                );
              })}
            </TableBody>
          </MaterialTable>
        )}
      </TableContainer>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={(handleChangeRowsPerPage ? perRowOptions : [])}
          component="div"
          count={Number(count)}
          rowsPerPage={rowsPerPage}
          page={current_page ? current_page : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table__pagination"
        />
      )}
    </section>
  );
};

const { bool, object } = PropTypes;
CollapsibleTable.propTypes = {
  columns: array.isRequired,
  rows: array.isRequired,
  onRow: func,
  size: string,
  loading: bool,
  originalOrder: string,
  orginalOrderBy: string,
  pagination: object,
  search: string,
  hideColumns: array,
};

CollapsibleTable.defaultProps = {
  originalOrder: "desc",
  search: "",
  pagination: { count: 0, current_page: 0, total_pages: 0 },
  hideColumns: [],
  hasParam: false,
  offset: false,
};

const mapStateToProps = (state) => ({
  org: state.auth.current_client,
});

export default compose(connect(mapStateToProps), React.memo)(CollapsibleTable);
