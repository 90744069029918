import React from "react";
import { connect, useSelector } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";

import Alert from "common/Alert";
import ButtonDropdown from "common/ButtonDropdown";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportMembers, clear } from "../../actions";
import { getRegions } from "../../actions/taxonomies";
import Paper from "../Paper";
import {
  Actions,
  ActionGroup,
  FilterMenu,
  ButtonGroup,
  Col,
  Cols,
} from "./Styled";
import { ExternalLink } from "../Styled";
import { formatFilters, removeColumns, formatProximitySearch } from "../../utility";
import {
  regionsSelectors,
  memberAffiliationsSelectors,
  statesSelectors,
  jobDivisionTypesSelectors,
  memberStatusSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import ClientAssignedFieldDisplay from "components/ClientAssignedField/ClientAssignedFieldDisplay";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const formatCommaEscapedStringIntoArray = (arr) => {
  try {
    return arr.match(/(?:\\,|[^,])+/g).map((item) => item.replace("\\,", ","));
  } catch {
    return [];
  }
};

const columnSettings = {
  proximity_search_distance: { minWidth: 100 },
  member_name: { minWidth: 400 },
  has_logo: { minWidth: 100 },
  office_id: { minWidth: 150 },
  region: { minWidth: 150 },
  affiliation: { minWidth: 150 },
  is_parent_member: { minWidth: 100, label: "Parent" },
  store_type: { minWidth: 150, label: "Store Type" },
  address_line1: { minWidth: 150, label: "Street" },
  address_line2: { minWidth: 150, label: "Street 2" },
  city: { minWidth: 150 },
  state: { minWidth: 150 },
  postal_code: { minWidth: 150 },
  billing_address_line1: { minWidth: 200, label: "Mailing Street" },
  billing_address_line2: { minWidth: 200, label: "Mailing Street 2" },
  billing_city: { minWidth: 150 },
  billing_state: { minWidth: 150 },
  billing_postal_code: { minWidth: 150 },
  counties_owned: { minWidth: 400 },
  counties_served: { minWidth: 400 },
  phone: { minWidth: 150 },
  phone_after_hours: { minWidth: 200 },
  fax: { minWidth: 150 },
  website: { minWidth: 150 },
  primary_contact: { minWidth: 200 },
  primary_contact_email: { minWidth: 200 },
  secondary_contacts: { minWidth: 200 },
  after_hours_contacts: { minWidth: 200 },
  owner_name: { minWidth: 200 },
  owner_email: { minWidth: 200 },
  phoenix_certified: { minWidth: 200 },
  certification_names: { minWidth: 200 },
  division_types_json: { minWidth: 200 },
  national_contracts: { minWidth: 200 },
  status_proper: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  member_name,
  hasLogo,
  officeId,
  region,
  affiliation,
  isParent,
  storeType,
  street,
  street2,
  city,
  state,
  zip,
  billingStreet,
  billingStreet2,
  billingCity,
  billingState,
  billingZip,
  owned,
  served,
  phone,
  afterHours,
  fax,
  website,
  primary,
  primaryEmail,
  secondary,
  afterHoursContacts,
  ownerName,
  ownerEmail,
  phoenix,
  certs,
  division_types_json,
  contracts,
  status,
  paths,
  statuses,
  proximity_search_distance,
  ownerNids
) => {
  return {
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    has_logo: hasLogo,
    office_id: officeId,
    region: region,
    affiliation: affiliation,
    is_parent_member: isParent,
    store_type: storeType,
    address_line1: street,
    address_line2: street2,
    city,
    state,
    postal_code: zip,
    billing_address_line1: billingStreet,
    billing_address_line2: billingStreet2,
    billing_city: billingCity,
    billing_state: billingState,
    billing_postal_code: billingZip,
    phone: phone,
    phone_after_hours: afterHours,
    fax: fax,
    website: website ? (
      <ExternalLink href={website} target="blank">
        {website}
      </ExternalLink>
    ) : null,
    primary_contact: primary ? (
      <LinkHeader to={paths.primary_contact} size="small" color="default">
        {primary}
      </LinkHeader>
    ) : null,
    primary_contact_email: primaryEmail,
    secondary_contacts:
      secondary && typeof secondary === "string"
        ? secondary.split(",").map((second, index) => (
            <LinkHeader
              to={paths.secondary_contacts[index]}
              size="small"
              color="default"
              key={index}
            >
              {second}
            </LinkHeader>
          ))
        : null,
    after_hours_contacts:
      afterHoursContacts && typeof afterHoursContacts === "string"
        ? afterHoursContacts.split(",").map((after, index) => (
            <LinkHeader
              to={paths.after_hours_contacts[index]}
              size="small"
              color="default"
              key={index}
            >
              {after}
            </LinkHeader>
          ))
        : null,
    owner_name:
      ownerName && typeof ownerName === "string"
        ? ownerName.split(",").map((owner, index) => (
            <LinkHeader
              to={`${paths.member}/connect/employees/view/${ownerNids[index]}`}
              size="small"
              color="default"
              key={index}
              target="_blank"
            >
              {owner}
            </LinkHeader>
          ))
        : null,
    owner_email: ownerEmail,
    phoenix_certified: phoenix,
    status_proper: statuses.find((s) => s.machine_name === status)
      ? statuses.find((s) => s.machine_name === status).label
      : "",
    proximity_search_distance: proximity_search_distance ? (
      `${Number(proximity_search_distance).toFixed(2)}mi`
    ) : null,
    collapsible: (
      <Cols>
        <Col>
          <Typography variant="subtitle1">Certifications</Typography>
          {certs && typeof certs === "string"
            ? certs.split(",").map((cert, index) => (
                <Typography key={index} variant="body1">
                  {cert}
                </Typography>
              ))
            : "No Value"}
        </Col>
        <Col>
          <Typography variant="subtitle1">Services</Typography>
          <ClientAssignedFieldDisplay
            fieldData={division_types_json}
            clientDisplay={(phx_client_title) => <Typography variant="subtitle">{phx_client_title}</Typography>}
            display={(item, isDiffClientItems) => <div className={isDiffClientItems ? "pl-4" : ""}>{item.entity_title}</div>}
            emptyMessage="No Value"
          />
        </Col>
        <Col>
          <Typography variant="subtitle1">Counties Owned</Typography>
          {owned && typeof owned === "string"
            ? formatCommaEscapedStringIntoArray(owned).map((own, index) => (
                <Typography key={index} variant="body1">
                  {own}
                </Typography>
              ))
            : "No Value"}
        </Col>
        <Col>
          <Typography variant="subtitle1">Counties Served</Typography>
          {served && typeof served === "string"
            ? formatCommaEscapedStringIntoArray(served).map((serve, index) => (
                <Typography key={index} variant="body1">
                  {serve}
                </Typography>
              ))
            : "No Value"}
        </Col>
        <Col>
          <Typography variant="subtitle1">National Contracts</Typography>
          {contracts && typeof contracts === "string"
            ? contracts.split(",").map((contract, index) => (
                <Typography key={index} variant="body1">
                  {contract}
                </Typography>
              ))
            : "No Value"}
        </Col>
      </Cols>
    ),
  };
};

const initialFormData = {
  status: [],
  region_tid: [],
  affiliation_tid: [],
  state: [],
  division_type_tids: [],
  store_type: [],
};

const originalOrder = 'desc';
const originalOrderBy = 'created';

const ReportMembers = ({
  loadMembers,
  report,
  exportReport,
  clear,
  loading,
  pagination,
  error,
  org,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [proximityFormData, setProximityFormData] = React.useState({});
  const [order, setOrder] = React.useState(originalOrder);
  const [orderBy, setOrderBy] = React.useState(originalOrderBy);
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const regions = useSelector(regionsSelectors.selectAll);
  const affiliations = useSelector(memberAffiliationsSelectors.selectAll);
  const states = useSelector(statesSelectors.selectAll);
  const services = useSelector(jobDivisionTypesSelectors.selectAll);
  const statuses = useSelector(memberStatusSelectors.selectAll);

  const handleExportReport = async () => {
    let proximitySearch = formatProximitySearch(filters.proximitySettings);
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nids]": org,
      };
    }

    await exportReport("/rest/report/members", {
      _export: "csv",
      keywords: searchTerm,
      order: filters.adjustedOrderBy,
      sort: filters.adjustedOrder,
      ...proximitySearch,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(
      [
        "counties_owned",
        "counties_served",
        "certification_names",
        "division_types_json",
        "national_contracts",
      ],
      temp
    );
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.member_name,
        row.has_logo,
        row.office_id,
        row.region,
        row.affiliation,
        row.is_parent_member,
        row.store_type,
        row.address_line1,
        row.address_line2,
        row.city,
        row.state,
        row.postal_code,
        row.billing_address_line1,
        row.billing_address_line2,
        row.billing_city,
        row.billing_state,
        row.billing_postal_code,
        row.counties_owned,
        row.counties_served,
        row.phone,
        row.phone_after_hours,
        row.fax,
        row.website,
        row.primary_contact,
        row.primary_contact_email,
        row.secondary_contacts,
        row.after_hours_contacts,
        row.owner_name,
        (row.owner_email ? row.owner_email.replace(/,/g, ", ") : ''),
        row.phoenix_certified,
        row.certification_names,
        row.division_types_json,
        row.national_contracts,
        row.status,
        row._paths,
        statuses,
        row.proximity_search_distance,
        (row.owner_nids ? row.owner_nids.split(",") : []),
      )
    );
  }, [report, loading, statuses]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProximityChange = (value, name) => {
    setOrder('asc');
    setOrderBy('proximity_search_distance');
    setProximityFormData({
      ...proximityFormData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({
      ...formData,
      adjustedOrder: order,
      adjustedOrderBy: orderBy,
      proximitySettings: proximityFormData,
    });
  };

  const resetFilters = () => {
    setOrder(originalOrder);
    setOrderBy(originalOrderBy);
    setProximityFormData({});
    setFilters({});
    setFormData(initialFormData);
  };

  return (
    <div>
      <Helmet>
        <title>Members Report | Phoenix Solutions</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Members</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={regions.map((region) => ({
                    label: region.name,
                    value: region.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="HQ Region"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "region_tid");
                  }}
                  value={formData.region_tid}
                />
                <Autocomplete
                  options={affiliations.map((affiliation) => ({
                    label: affiliation.name,
                    value: affiliation.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Affiliations"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "affiliation_tid");
                  }}
                  value={formData.affiliation_tid}
                />
                <Autocomplete
                  options={[
                    {
                      label: "Primary",
                      value: "Primary",
                    },
                    {
                      label: "Branch",
                      value: "Branch",
                    },
                    {
                      label: "County Agreement",
                      value: "County Agreement",
                    },
                  ]}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store Type"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "store_type");
                  }}
                  value={formData.store_type}
                />
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "state");
                  }}
                  value={formData.state}
                />
                <Autocomplete
                  options={services.map((service) => ({
                    label: service.name,
                    value: service.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Services"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "division_type_tids");
                  }}
                  value={formData.division_type_tids}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.label,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Proximity Search
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Address/Zip"
                  onChange={(e) => {
                    handleProximityChange(e.target.value, "address");
                  }}
                  fullWidth
                  value={proximityFormData.address ?? ""}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                />
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="proximity-search-radius-label">Radius</InputLabel>
                  <Select
                    labelId="proximity-search-radius-label"
                    label="Radius"
                    size="small"
                    value={proximityFormData.radius ?? ""}
                    displayEmpty
                    onChange={(e) => {
                      handleProximityChange(e.target.value, "radius");
                    }}
                    >
                      <MenuItem value={10}>10 Miles</MenuItem>
                      <MenuItem value={25}>25 Miles</MenuItem>
                      <MenuItem value={50}>50 Miles</MenuItem>
                      <MenuItem value={100}>100 Miles</MenuItem>
                      <MenuItem value={200}>200 Miles</MenuItem>
                  </Select>
                </FormControl>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          clientFilter={"phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadMembers}
          filters={filters}
          originalOrderBy={originalOrderBy}
          originalOrder={originalOrder}
          pagination={pagination}
          search={searchTerm}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportMembers.propTypes = {};

ReportMembers.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  statuses: [],
  regions: [],
  affiliations: [],
  states: [],
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  statuses: state.app.memberStatus.data,
  regions: state.app.regions.data,
  affiliations: state.app.affiliations.data,
  states: state.app.states.data,
  services: state.app.jobDivisionTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadMembers: (params) => dispatch(getReportMembers(params)),
  loadRegions: () => dispatch(getRegions()),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMembers);
