import React from "react";
import styled from "styled-components";
import {
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import FileIcon from "../FileIcon";
import { formatFileSize } from "../../utility";
import constants from "../constants";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
`;

const FormGroup = styled.div`
  padding: 1.25rem;
`;

const Details = styled.div`
  flex: 1;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background: ${constants.colorGrayLight};
  border-radius: 2px;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 100%;
  background: ${constants.colorGreen};
  transition: width 0.25s;
`;

const DivisionFile = ({
  file,
  options,
  isSubmitting,
  role,
  formData,
  setFormData,
  divisionJob,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAmountChange = (e) => {
    setFormData({
      ...formData,
      [file.id]: { ...formData[file.id], amount: e.target.value },
    });
  };

  const handleCategoryChange = (e) => {
    setFormData({
      ...formData,
      [file.id]: { ...formData[file.id], category: e.target.value },
    });
  };

  const handleCheck = (e) => {
    setFormData({
      ...formData,
      [file.id]: {
        ...formData[file.id],
        visible: {
          ...formData[file.id].visible,
          [e.target.name]: e.target.checked,
        },
      },
    });
  };

  const showAmountField = (data, options) => {
    if (!data) return false;
    const invoice = options.find((option) => option.name === "Invoice");
    const estimate = options.find((option) => option.name === "Estimate");

    if (
      data.category &&
      (estimate.tid === data.category || invoice.tid === data.category)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <FileIcon filename={file.file.name} status={file.status} />
      <Details>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ maxWidth: "245px", lineHeight: "1rem" }}
        >
          {file.file.name}
        </Typography>
        <Typography variant="caption">
          {formatFileSize(file.file.size)}
        </Typography>
      </Details>
      <FormControl variant="outlined" size="small" style={{ width: "100px" }}>
        <InputLabel id={`file-category`}>Category</InputLabel>
        <Select
          labelId={`file-category`}
          displayEmpty
          variant="outlined"
          value={formData[file.id] ? formData[file.id].category : ""}
          onChange={handleCategoryChange}
          labelwidth={70}
          disabled={isSubmitting || file.status === 2}
        >
          {options.map((option) => (
            <MenuItem key={option.tid} value={option.tid}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton
        size="small"
        style={{ marginLeft: "1rem" }}
        disabled={isSubmitting || file.status === 2}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Popover
        id={`file-options-${file.id}`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {showAmountField(formData[file.id], options) && (
          <FormGroup>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel htmlFor="amount">Amount</InputLabel>
              <OutlinedInput
                id="amount"
                disabled={isSubmitting || file.status === 2}
                value={formData[file.id] ? formData[file.id].amount : ""}
                onChange={handleAmountChange}
                labelwidth={60}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </FormGroup>
        )}
        <FormGroup style={{ width: "300px" }}>
          {role !== "phx_client" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="phx_client"
                  size="small"
                  checked={
                    formData[file.id]
                      ? formData[file.id].visible.phx_client
                      : true
                  }
                  onChange={handleCheck}
                />
              }
              label={divisionJob.field_phoenix_client[0].title[0].value}
            />
          )}
          {role !== "member" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="member"
                  size="small"
                  checked={
                    formData[file.id] ? formData[file.id].visible.member : true
                  }
                  onChange={handleCheck}
                />
              }
              label="Member"
            />
          )}
          {role !== "customer" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="customer"
                  size="small"
                  checked={
                    formData[file.id]
                      ? formData[file.id].visible.customer
                      : true
                  }
                  onChange={handleCheck}
                />
              }
              label="Customer"
            />
          )}
        </FormGroup>
      </Popover>
      {file.status === 1 && (
        <Progress>
          <Bar style={{ width: `${file.progress}%` }}></Bar>
        </Progress>
      )}
    </Container>
  );
};

DivisionFile.propTypes = {};

DivisionFile.defaultProps = {
  options: [],
};

export default DivisionFile;
