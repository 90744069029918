import * as Yup from "yup";
import moment from "moment";

export const serviceValidationSchema = Yup.object().shape({
  breakHours: Yup.number().typeError("Must be a number").nullable(),
  perdiem: Yup.number().typeError("Must be a number").nullable(),
  "service-billable": Yup.number().typeError("Must be a number").nullable(),
  "travel-billable": Yup.number().typeError("Must be a number").nullable(),
  "overtime-billable": Yup.number().typeError("Must be a number").nullable(),
  "after-billable": Yup.number().typeError("Must be a number").nullable(),
  "service-rate": Yup.number().typeError("Must be a number").nullable(),
  "travel-rate": Yup.number().typeError("Must be a number").nullable(),
  "overtime-rate": Yup.number().typeError("Must be a number").nullable(),
  "after-rate": Yup.number().typeError("Must be a number").nullable(),
  "service-hours": Yup.number().typeError("Must be a number").nullable(),
  "service-total": Yup.number().typeError("Must be a number").nullable(),
  "travel-hours": Yup.number().typeError("Must be a number").nullable(),
  "travel-total": Yup.number().typeError("Must be a number").nullable(),
  "overtime-hours": Yup.number().typeError("Must be a number").nullable(),
  "overtime-total": Yup.number().typeError("Must be a number").nullable(),
  "after-hours": Yup.number().typeError("Must be a number").nullable(),
  "after-total": Yup.number().typeError("Must be a number").nullable(),
});

const testFormats = [
  "YYYY-MM-DD LT",
  "YYYY-MM-DD h:mm:ss A",
  "YYYY-MM-DD h:mm:ssA",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH:mm:ss A",
  "YYYY-MM-DD HH:mm:ssA",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD HH:mmA",
  "YYYY-MM-DD H:mm",
  "YYYY-MM-DD H:mm A",
  "YYYY-MM-DD H:mmA",
];
export const serviceBatchValidationSchema = Yup.object().shape({
  entries: Yup.array().of(
    Yup.object().shape({
      field_ds_start_time: Yup.string()
        .required("Required")
        .test("is-time", "ex 8:00 AM", function (value) {
          if (!value) return true;
          return moment(
            `2014-12-13 ${value.trim()}`,
            testFormats,
            true
          ).isValid();
        }),
      field_ds_end_time: Yup.string()
        .required("Required")
        .test("is-time", "ex 5:00 PM", function (value) {
          if (!value) return true;
          return moment(
            `2014-12-13 ${value.trim()}`,
            testFormats,
            true
          ).isValid();
        })
        /*.test("is-after", "Too early", function (value) {
          if (!value) return true;
          const { field_ds_start_time } = this.parent;
          if (!field_ds_start_time) return true;
          var beginningTime = moment(field_ds_start_time, "h:mma");
          var endTime = moment(value.trim(), "h:mma");
          return endTime.isAfter(beginningTime);
        })*/,
      field_ds_breaks_start_time: Yup.string()
        .test("is-time", "ex 12:00 PM", function (value) {
          if (!value) return true;
          return moment(
            `2014-12-13 ${value.trim()}`,
            testFormats,
            true
          ).isValid();
        })
        /*.test("is-after", "Too early", function (value) {
          if (!value) return true;
          const { field_ds_start_time } = this.parent;
          if (!field_ds_start_time) return true;
          var beginningTime = moment(field_ds_start_time.trim(), "h:mma");
          var endTime = moment(value.trim(), "h:mma");
          return endTime.isAfter(beginningTime);
        })*/,
      field_ds_breaks_end_time: Yup.string()
        .test("is-time", "ex 1:00 PM", function (value) {
          if (!value) return true;
          return moment(
            `2014-12-13 ${value.trim()}`,
            testFormats,
            true
          ).isValid();
        })
        /*.test("is-after", "Too early", function (value) {
          if (!value) return true;
          const { field_ds_breaks_start_time } = this.parent;
          if (!field_ds_breaks_start_time) return true;
          var beginningTime = moment(
            field_ds_breaks_start_time.trim(),
            "h:mma"
          );
          var endTime = moment(value.trim(), "h:mma");
          return endTime.isAfter(beginningTime);
        })*/
        .test("is-before", "Too late", function (value) {
          if (!value) return true;
          const { field_ds_end_time } = this.parent;
          if (!field_ds_end_time) return true;
          var endTime = moment(field_ds_end_time.trim(), "h:mma");
          var beginningTime = moment(value.trim(), "h:mma");
          return beginningTime.isBefore(endTime);
        }),
      field_ds_travel_billable_hrs: Yup.number().typeError("Not a Number"),
      field_ds_user: Yup.object()
        .shape({
          entity_id: Yup.string(),
          entity_type: Yup.string(),
          name: Yup.string(),
        })
        .typeError("Required"),
      field_ds_labor_type_text: Yup.string().required("Required"),
    })
  ),
});

export const serviceBatchMilitaryValidationSchema = Yup.object().shape({
  entries: Yup.array().of(
    Yup.object().shape({
      field_ds_start_time: Yup.string()
        .required("Required")
        .test("is-time", "ex 8:00 AM", function (value) {
          if (!value) return true;
          return moment(`2014-12-13 ${value.trim()}`, testFormats, true).isValid();
        }),
      field_ds_end_time: Yup.string()
        .required("Required")
        .test("is-time", "ex 5:00 PM", function (value) {
          if (!value) return true;
          return moment(`2014-12-13 ${value.trim()}`, testFormats, true).isValid();
        })
        /*.test("is-after", "Too early", function (value) {
          if (!value) return true;
          const { field_ds_start_time } = this.parent;
          if (!field_ds_start_time) return true;
          var beginningTime = moment(field_ds_start_time, "h:mma");
          var endTime = moment(value.trim(), "h:mma");
          return endTime.isAfter(beginningTime);
        })*/,
      field_ds_breaks_start_time: Yup.string()
        .test("is-time", "ex 12:00 PM", function (value) {
          if (!value) return true;
          return moment(`2014-12-13 ${value.trim()}`, testFormats, true).isValid();
        })
        /*.test("is-after", "Too early", function (value) {
          if (!value) return true;
          const { field_ds_start_time } = this.parent;
          if (!field_ds_start_time) return true;
          var beginningTime = moment(field_ds_start_time.trim(), "h:mma");
          var endTime = moment(value.trim(), "h:mma");
          return endTime.isAfter(beginningTime);
        })*/,
      field_ds_breaks_end_time: Yup.string()
        .test("is-time", "ex 1:00 PM", function (value) {
          if (!value) return true;
          return moment(`2014-12-13 ${value.trim()}`, testFormats, true).isValid();
        })
        /*.test("is-after", "Too early", function (value) {
          if (!value) return true;
          const { field_ds_breaks_start_time } = this.parent;
          if (!field_ds_breaks_start_time) return true;
          var beginningTime = moment(
            field_ds_breaks_start_time.trim(),
            "h:mma"
          );
          var endTime = moment(value.trim(), "h:mma");
          return endTime.isAfter(beginningTime);
        })*/
        .test("is-before", "Too late", function (value) {
          if (!value) return true;
          const { field_ds_end_time } = this.parent;
          if (!field_ds_end_time) return true;
          var endTime = moment(field_ds_end_time.trim(), "h:mma");
          var beginningTime = moment(value.trim(), "h:mma");
          return beginningTime.isBefore(endTime);
        }),
      field_ds_travel_billable_hrs: Yup.number().typeError("Not a Number"),
      field_ds_user: Yup.object()
        .shape({
          entity_id: Yup.string(),
          entity_type: Yup.string(),
          name: Yup.string(),
        })
        .typeError("Required"),
      field_ds_labor_type_text: Yup.string().required("Required"),
    })
  ),
});

export const expenseValidationSchema = Yup.object().shape({
  type: Yup.string().typeError("Required").required("Required"),
  cost: Yup.number().typeError("Must be a number").required("Required"),
  markup: Yup.number().typeError("Must be a number").nullable(),
  description: Yup.string().nullable(),
});
