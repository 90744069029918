import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Route, useRouteMatch } from "react-router-dom";
import {
  CircularProgress,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import moment from "moment";

import { IsNew, CellSubheading, CellHeading } from "components/Table/Styled";
import Information from "./components/Information";
import LinkHeader from "common/LinkHeader";
import { jobsColumns } from "schemas/tables/jobs";
import { MuiTable, FilterMenu, SelectColumnFilter } from "common/Table";
import { useDebounce, useLocalStorage } from "hooks";
import Layout from "common/Layout";
import Status from "components/Status";
import CollapsibleIcon from "common/CollapsibleIcon";
import { getDivisionTypeIndicator } from "features/Job/utils";
import DivisionActions from "./components/DivisionActions";
import styled from "styled-components";
import TimeDisplayCell from "components/TimeDisplayCell";
import { ImFilesEmpty } from "react-icons/im";

import {
  fetchJobs,
  divisionsSelectors,
  getDivisionsLoadingSelector,
  getDivisionsPaginationSelector,
  fetchMyActiveJobs,
} from "./divisionsSlice";
import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { parseJobSource } from "features/Job/utils";
import ProgressIndicator from "features/JobProgress/components/ProgressIndicator";

// const jobsIcon = `${process.env.PUBLIC_URL}/images/icons/icon-jobs--large.svg`;

// const icon = {
//   src: jobsIcon,
//   alt: "Jobs Icon",
// };

const ActionStyles = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  border-radius: 2px;

  &:hover {
    background: var(--color-gray-dark);
  }
`;

const JobStatus = ({ machine_name, name }) => {
  return (
    <Status type={machine_name} indicator>
      {name}
    </Status>
  );
};

const formatFilterArray = (arr, field) => {
  const filters = {};
  arr.forEach((filter, i) => {
    filters[`filter[${field}][${i}]`] = filter.value;
  });

  return filters;
};

const TimeFilter = ({ dateFilter, setDateFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const options = useMemo(
    () => [
      {
        label: "All",
        start: null,
        end: null,
      },
      {
        label: "Today",
        start: moment().clone().startOf("day").format("YYYY-MM-DD"),
        end: moment().clone().endOf("day").format("YYYY-MM-DD"),
      },
      {
        label: "This Week",
        start: moment().clone().startOf("week").format("YYYY-MM-DD"),
        end: moment().clone().endOf("week").format("YYYY-MM-DD"),
      },
      {
        label: "This Month",
        start: moment().clone().startOf("month").format("YYYY-MM-DD"),
        end: moment().clone().endOf("month").format("YYYY-MM-DD"),
      },
      {
        label: "This Year",
        start: moment().clone().startOf("year").format("YYYY-MM-DD"),
        end: moment().clone().endOf("year").format("YYYY-MM-DD"),
      },
    ],
    []
  );

  const handleClose = (index) => (e) => {
    if (options[index]) {
      setDateFilter(options[index]);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <ActionStyles onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Typography style={{ marginRight: "0.5rem" }}>
          {dateFilter.label}
        </Typography>
        {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
      </ActionStyles>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose(null)}
      >
        <MenuItem onClick={handleClose(0)}>All</MenuItem>
        <MenuItem onClick={handleClose(1)}>Today</MenuItem>
        <MenuItem onClick={handleClose(2)}>This Week</MenuItem>
        <MenuItem onClick={handleClose(3)}>This Month</MenuItem>
        <MenuItem onClick={handleClose(4)}>This Year</MenuItem>
      </Menu>
    </>
  );
};

const Divisions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const divisions = useSelector(divisionsSelectors.selectAll);
  const loading = useSelector(getDivisionsLoadingSelector);
  const pagination = useSelector(getDivisionsPaginationSelector);
  const clientNid = useSelector((state) => state.auth.current_client);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const browser = useSelector((state) => state.browser);

  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query, 250);
  const [order, setOrder] = useState("changed");
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]); // eslint-disable-line no-unused-vars
  const [ntp, setNtp] = useState([]);
  const [jobSource, setJobSource] = useState([]);
  const [myJobs, setMyJobs] = useLocalStorage("my_jobs_toggle", false);
  const [dateFilter, setDateFilter] = useState({
    label: "All",
    start: null,
    end: null,
  });
  const _jobSourceOptions =  useSelector(
    (state) => state.auth.user.data._processed.job_source_options
  );
  let jobSourceOptions = parseJobSource(_jobSourceOptions, null, true);

  useEffect(() => {
    let params = {
      keywords: searchQuery,
      page,
      order,
      sort,
      rows_per_page: rowsPerPage,
      ...formatFilterArray(type, "job_division_type_tid"),
      ...formatFilterArray(status, "status_tid"),
      ...formatFilterArray(category, "category"),
      ...formatFilterArray(ntp, "nte_is_set"),
      ...formatFilterArray(jobSource, "job_source"),
    };

    if (dateFilter.start) {
      params = {
        ...params,
        "filter[created][0][DATE BETWEEN][0]": dateFilter.start,
        "filter[created][0][DATE BETWEEN][1]": dateFilter.end,
      };
    }

    if (clientNid) {
      params["filter[phx_client_nid]"] = clientNid;
    }

    let promise = null;
    if (myJobs) {
      promise = dispatch(fetchMyActiveJobs(params));
    } else {
      promise = dispatch(fetchJobs(params));
    }

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    searchQuery,
    order,
    sort,
    clientNid,
    type,
    status,
    category,
    ntp,
    jobSource,
    page,
    myJobs,
    rowsPerPage,
    dateFilter,
  ]);

  const data = useMemo(
    () =>
      divisions.map((division) => {
        const { icon: TypeIcon, color } = getDivisionTypeIndicator(
          division.job_division_type
        );
        return {
          id: division.division_nid,
          changed: division.saving ? (
            <CircularProgress size={14} />
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IsNew isNew={Number(division.is_new) === 1}>
                <div hidden>{division.changed}</div>
              </IsNew>
              <TimeDisplayCell time={division.changed} style={{ marginLeft: "1rem" }} />
            </div>
          ),
          icon: (
            <CollapsibleIcon
              collapse={true}
              color={color}
              tooltip={division.job_division_type}
            >
              {<TypeIcon />}
            </CollapsibleIcon>
          ),
          title: (
            <div>
              <LinkHeader
                to={division._paths.division}
                size="medium"
                color="primary"
              >
                {division.title}
              </LinkHeader>
              <CellSubheading>{division.job_division_type}</CellSubheading>
              {browser.is.extraSmall && (
                <div style={{ marginTop: "1rem" }}>
                  <div>
                    <LinkHeader
                      to={division._paths.customer}
                      size="medium"
                      noWrap
                      width={300}
                    >
                      {division.customer_name}
                    </LinkHeader>
                  </div>
                  <LinkHeader
                    to={division._paths.customer_location}
                    size="small"
                    color="secondary"
                    noWrap
                    width={300}
                  >
                    {division.customer_location_name}
                  </LinkHeader>
                </div>
              )}
            </div>
          ),
          status: (
            <JobStatus
              machine_name={division.status}
              name={division.status_proper}
            />
          ),
          milestone_progress: (
            <ProgressIndicator
              completed={division.milestones_completed}
              required={division.milestones_required}
              colorScheme="light"
              inJobListing
            />
          ),
          reference_number: (
            <Tooltip
              title={division.reference_number ? division.reference_number : ""}
            >
              <CellHeading noWrap style={{ width: 100 }}>
                {division.reference_number}
              </CellHeading>
            </Tooltip>
          ),
          created: <TimeDisplayCell time={division.created} />,
          nte_is_set: (
            <CellHeading
              uppercase
              style={{
                marginTop: "5px",
                color:
                  division.nte_is_set === "Yes"
                    ? "var(--color-green)"
                    : "var(--color-red)",
              }}
            >
              {division.nte_is_set === "Yes" ? (
                <AssignmentTurnedInIcon />
              ) : (
                <AssignmentLateIcon />
              )}
            </CellHeading>
          ),
          category: <CellHeading uppercase>{division.category}</CellHeading>,
          customer_name: (
            <div>
              <div>
                <LinkHeader
                  to={division._paths.customer}
                  size="medium"
                  noWrap
                  width={300}
                >
                  {division.customer_name}
                </LinkHeader>
              </div>
              <LinkHeader
                to={division._paths.customer_location}
                size="small"
                color="secondary"
                noWrap
                width={300}
              >
                {division.customer_location_name}
              </LinkHeader>
            </div>
          ),
          member_name: (
            <LinkHeader
              to={division._paths.member}
              size="medium"
              color="default"
              noWrap
              width={300}
            >
              {division.member_name}
            </LinkHeader>
          ),
          job_source: (
            <CellHeading noWrap style={{ width: 75 }}>
              {division.job_source_proper}
            </CellHeading>
          ),
          actions: <DivisionActions nid={division.division_nid} url={url} />,
        };
      }),
    [divisions, url, browser]
  );

  const filters = [
    {
      component: <SelectColumnFilter />,
      props: {
        filterValue: type,
        setFilter: setType,
        label: "Job Type",
        id: "job-type",
        filterOptions: types.map((obj) => ({
          label: obj.name,
          value: obj.tid,
        })),
        multiple: true,
      },
    },
    {
      component: <SelectColumnFilter />,
      props: {
        filterValue: status,
        setFilter: setStatus,
        label: "Status",
        id: "job-status",
        filterOptions: statuses.map((obj) => ({
          label: obj.name,
          value: obj.tid,
        })),
        multiple: true,
      },
    },
    {
      component: <SelectColumnFilter />,
      props: {
        filterValue: jobSource,
        setFilter: setJobSource,
        label: "Job Source",
        id: "job-source",
        filterOptions: (jobSourceOptions ? jobSourceOptions : []),
        multiple: true,
      },
    },
    // {
    //   component: <SelectColumnFilter />,
    //   props: {
    //     filterValue: category,
    //     setFilter: setCategory,
    //     label: "Category",
    //     id: "job-category",
    //     filterOptions: [
    //       {
    //         label: "CS",
    //         value: "cs",
    //       },
    //       {
    //         label: "G2",
    //         value: "g2",
    //       },
    //     ],
    //     multiple: true,
    //   },
    // },
    {
      component: <SelectColumnFilter />,
      props: {
        filterValue: ntp,
        setFilter: setNtp,
        label: "NTP",
        id: "job-ntp",
        filterOptions: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
        multiple: true,
      },
    },
  ];
  let _filters = filters;
  let _jobsColumns = jobsColumns;
//  Omit the Job Source column if the user has only one option
  if(jobSourceOptions && jobSourceOptions.length < 2) {
    _filters = _filters.filter((fil) => fil.props.id !== "job-source");
    _jobsColumns = _jobsColumns.filter((col) => col.id !== "job_source");
  }

  return (
    <Layout
      title="Helix | Jobs"
      name="Jobs"
      meta="Jobs"
      titleMenu={
        <div
          style={{
            // background: "var(--color-gray-background)",
            display: "flex",
            alignItems: "center",
            flex: "1 0 auto",
          }}
        >
          <Typography style={{ marginRight: "1rem", flex: "1 0 auto" }} variant="h6">
            <ImFilesEmpty className="header-icon" /> Jobs
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={myJobs}
                onChange={() => setMyJobs(!myJobs)}
                name="my-jobs"
                color="primaryInverse"
              />
            }
            label="My Active Jobs"
          />
          <TimeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
        </div>
      }
      subHeaderComponent={
        <>
          <FilterMenu
            fillBackground
            search={{
              query,
              setQuery,
            }}
            filters={_filters}
          />
        </>
      }
    >
      <>
        <MuiTable
          rows={data}
          columns={_jobsColumns}
          loading={loading}
          size="medium"
          stickyHeader
          flex
          pagination={pagination}
          sort={sort}
          setSort={setSort}
          order={order}
          setOrder={setOrder}
          handleChangePage={(e, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
        />
        <Route
          path={`${url}/:id`}
          render={() => {
            return <Information onClose={() => history.push(url)} />;
          }}
        />
      </>
    </Layout>
  );
};

Divisions.propTypes = {};

export default Divisions;
