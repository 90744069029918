import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Popper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import { BiCalendarPlus } from "react-icons/bi";
import moment from "moment";

import { times } from "utility";
import {
  AutocompleteFormField,
  AutocompleteFormFieldStatic,
} from "common/Fields";
import { DateFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import {
  usersSelectors,
  getUsersLoadingSelector,
  fetchMemberUsers,
} from "features/Users/usersSlice";
import { getDivisionDataSelector } from "features/Job/jobSlice";
import { postCrew } from "../crewSlice";
import { toArray } from "lodash";
import { getMemberDataSelector } from "features/Member/memberSlice";

const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const Create = ({ nid, children, assignedOnly }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const loading = useSelector(getUsersLoadingSelector);
  const users = useSelector(usersSelectors.selectAll);
  const member = useSelector(getMemberDataSelector);

  const [open, setOpen] = useState(false);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsers({
          id: member.nid,
          onlyAssigned: assignedOnly ? 1 : 0,
          params: {
            keywords: query,
          },
        })
      );
    },
    [member, assignedOnly, dispatch]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (data) => {
    const arrivalStart = moment(
      moment(data.arrival).format("YYYY-MM-DD") + "T" + data.arrivalFrom.value
    );
    const arrivalEnd = moment(
      moment(data.arrival).format("YYYY-MM-DD") + "T" + data.arrivalTo.value
    );

    const params = {
      field_crew_chief_user: [{ target_id: data.field_crew_chief_user.uid }],
      field_crew_work_schedule: [
        {
          value: arrivalStart.format("YYYY-MM-DD"),
          end_value: arrivalEnd.format("YYYY-MM-DD"),
        },
      ],
      field_crew_member_users: data.field_crew_member_users.map((user) => ({
        target_id: user.uid,
      })),
      field_crew_arrival_time_range: [
        {
          value: arrivalStart.format(),
          end_value: arrivalEnd.format(),
        },
      ],
    };

    await dispatch(
      postCrew({
        id: division.nid,
        params,
        start: arrivalStart.format(),
        end: arrivalEnd.format(),
      })
    );

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen} style={{ paddingLeft: "2rem" }}>
          <ListItemIcon>
            <BiCalendarPlus />
          </ListItemIcon>
          <ListItemText primary="Crew" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Schedule Crew"
        initialValues={{
          field_crew_chief_user: null,
          notes: "",
          arrival: moment(),
          arrivalFrom: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
          arrivalTo: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
          field_crew_member_users: [],
        }}
        disablePadding
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <AutocompleteFormField
              name="field_crew_chief_user"
              label="Crew Chief"
              margin="normal"
              disabled={isSubmitting}
              options={toArray(users).map((user) => ({
                ...user,
                name: user.name,
                uid: user.uid,
              }))}
              loading={loading}
              fetchData={getUsers}
              variant="filled"
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xxs={4}>
                <DateFormField
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="arrival"
                  label="Arrival Date"
                  margin="normal"
                  name="arrival"
                  datePicker
                  KeyboardButtonProps={{
                    "aria-label": "change arrival date",
                  }}
                  inputVariant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={4}>
                <AutocompleteFormFieldStatic
                  name="arrivalFrom"
                  htmlFor="arrivalFrom"
                  margin="normal"
                  label="From"
                  disabled={isSubmitting}
                  disableClearable
                  options={times}
                  PopperComponent={CustomPopper}
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={4}>
                <AutocompleteFormFieldStatic
                  name="arrivalTo"
                  htmlFor="arrivalTo"
                  margin="normal"
                  label="To"
                  disabled={isSubmitting}
                  disableClearable
                  PopperComponent={CustomPopper}
                  options={times}
                  variant="filled"
                  size="small"
                />
              </Grid>
            </Grid>
            <AutocompleteFormField
              name="field_crew_member_users"
              label="Crew Members"
              margin="normal"
              disabled={isSubmitting}
              options={toArray(users).map((user) => ({
                name: user.name,
                uid: user.uid,
              }))}
              multiple
              loading={loading}
              fetchData={getUsers}
              variant="filled"
              size="small"
            />
          </div>
        )}
      </DialogForm>
    </>
  );
};

Create.propTypes = {};

export default Create;
