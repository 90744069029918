import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import MemberNoteAddEdit from "./MemberNoteAddEdit";
import Empty from "../Empty";
import { useDebounce } from "../../hooks";
import Alert from "common/Alert";
import { ChatSkeleton } from "../Skeletons";
import { getMemberNotes } from "../../actions";
import { Container, ActionBar, Actions, Filters } from "./Styled";
import { MemberNote } from "../Note";
import { hasPermission } from "../../utility";
import { memberNoteTypesSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import { specialMemberNoteTypes } from "./utils";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { clientsSelectors } from "features/Clients/clientsSlice";
import { reportSelectedClients } from "components/ClientSelector/utils";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const isEditable = (u, n, roles) => {
  try {
    const user = Number(u);
    const note = Number(n);

    return (
      user === note &&
      hasPermission(roles, [
        "admin",
        "phx_sub_admin",
        "phx_client_admin",
        "operations",
        "accounting",
        "compliance_admin",
      ])
    );
  } catch (e) {
    return false;
  }
};

const MemberNotes = ({
  loadMemberNotes,
  nid,
  memberLoading,
  notes,
  loading,
  error,
  showSnackbar,
  media,
  roles,
  typeTid,
  title,
  shortList,
}) => {
  const noteTypes = useSelector(memberNoteTypesSelectors.selectAll);
  const userFields = useSelector((state) => state.auth.user.data);
  const classes = useStyles();
  const [type, setType] = useState("");
  const [queryString, setQueryString] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [comment, setComment] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const typeName = (typeTid && noteTypes ? noteTypes.find(( type ) => type.tid === typeTid)?.name : "");
  const omitExtraInfo = Boolean(specialMemberNoteTypes.find(( type ) => type === typeName)?.length);
  const member = useSelector(getMemberDataSelector);
  const org = useSelector((state) => state.auth.current_client);
  const userClients = useSelector(clientsSelectors.selectAll);
  const _title = (title ? title : typeName + ' Notes');

  useEffect(() => {
    if (!memberLoading) {
      if(typeTid){
        queryString[`filter[note_type_tid]`] = typeTid;
      }
      else if(!type && noteTypes){
      //  Omit Coverage notes from the list
        noteTypes.forEach((type, i) => {
          if(specialMemberNoteTypes.includes(type.name)){
            queryString[`filter[note_type_tid][${i}][<>]`] = type.tid;
          }
        });
      }
      if(org){
        queryString[`filter[phx_client_nids]`] = org;
      }
      else{
        delete queryString[`filter[phx_client_nids]`];
      }
      if(shortList){
        queryString[`rows_per_page`] = 6;
      }
      loadMemberNotes(nid, { ...queryString, keywords: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm, loadMemberNotes, nid, memberLoading, org, queryString]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setType(event.target.value);
    const eventType = {};
    if (event.target.value) {
      eventType["filter[note_type_tid]"] = event.target.value;
    }

    setQueryString({ keywords: debouncedSearchTerm, ...eventType });
  };

  const handleDelete = async (data) => {
    // if (!data.cid) return;
    // const params = {
    //   _links: {
    //     type: {
    //       href: `${config.api_url}/rest/type/comment/job_division`,
    //     },
    //   },
    //   status: [{ value: 0 }],
    // };
    // await updateComment(data.cid, params, "patch");
    // loadJobNotes(nid);
  };

  const handleOpenEdit = (note) => {
    setOpenEdit(true);
    setComment(note);
  };

  return (
    <>
      <Container className={`${typeTid ? 'border-t-2 pt-4 mt-0' : ''}`}>
        <ActionBar>
         {!typeTid ? (
          <Filters>
            <TextField
              id="search"
              label="Search"
              variant="outlined"
              size="small"
              style={{
                marginRight: media.medium ? 0 : "1rem",
                marginBottom: media.medium ? "1rem" : 0,
                width: media.medium ? "100%" : 200,
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={memberLoading}
            />
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: media.medium ? "100%" : 200 }}
              disabled={memberLoading}
            >
              <InputLabel id="filter-type-label">Type</InputLabel>
              <Select
                labelId="filter-type-label"
                id="filter-type"
                labelwidth={60}
                onChange={handleChange}
                value={type}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {noteTypes.map((type) => (
                  !specialMemberNoteTypes.includes(type.name) && (
                    <MenuItem key={type.tid} value={type.tid}>
                      {type.name}
                    </MenuItem>
                  )
                ))}
              </Select>
            </FormControl>
          </Filters>
         ) : (
          <Typography variant="h5">{_title}</Typography>
         )}
          <Actions
            style={{
              order: media.medium ? -1 : 1,
              marginBottom: media.medium ? "1rem" : 0,
            }}
          >
            <Button
              size="small"
              startIcon={<Add />}
              className={classes.button}
              onClick={() => setOpenAdd(true)}
            >
              Add
            </Button>
          </Actions>
        </ActionBar>
        {error && <Alert kind="negative">{error.message}</Alert>}
      </Container>
      {loading ? (
        <ChatSkeleton />
      ) : !notes.length ? (
        <Empty msg="No notes for this Member" large />
      ) : (
        notes.map((note) => (
          <MemberNote
            editable={isEditable(userFields.uid, note.uid, roles)}
            handleEdit={() => handleOpenEdit(note)}
            handleDelete={handleDelete}
            key={note.cid}
            name={`${note.first_name} ${note.last_name}`}
            timestamp={note.created}
            status={(!omitExtraInfo ? note.note_status : null)}
            type={(!omitExtraInfo ? note.note_type : null)}
            subject={(!omitExtraInfo ? note.subject : null)}
            body={note.comment}
            clients={reportSelectedClients(note, member, userClients)}
            avatar={note._files.profile_pic}
          />
        ))
      )}
      <MemberNoteAddEdit
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        nid={nid}
        showSnackbar={showSnackbar}
        loadNotes={() =>
          loadMemberNotes(nid, {
            ...queryString,
            keywords: debouncedSearchTerm,
          })
        }
        typeTid={typeTid}
      />
      <MemberNoteAddEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        nid={nid}
        showSnackbar={showSnackbar}
        loadNotes={() =>
          loadMemberNotes(nid, {
            ...queryString,
            keywords: debouncedSearchTerm,
          })
        }
        comment={comment}
        typeTid={typeTid}
      />
    </>
  );
};

MemberNotes.propTypes = {};

MemberNotes.defaultProps = {
  notes: [],
};

const mapStateToProps = (state) => ({
  notes: state.app.memberNotes.data.data,
  loading: state.app.memberNotes.loading,
  error: state.app.memberNotes.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadMemberNotes: (nid, params) => dispatch(getMemberNotes(nid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberNotes);
