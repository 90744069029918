import React, { useState, useEffect } from "react";
import {
  Clock1,
  Clock2,
  Clock3,
  Clock4,
  Clock5,
  Clock6,
  Clock7,
  Clock8,
  Clock9,
  Clock10,
  Clock11,
  Clock12,
} from "lucide-react";

import { useDebounce } from "hooks";

const Components = {
  1: Clock1,
  2: Clock2,
  3: Clock3,
  4: Clock4,
  5: Clock5,
  6: Clock6,
  7: Clock7,
  8: Clock8,
  9: Clock9,
  10: Clock10,
  11: Clock11,
  12: Clock12,
};

const ClockIcon = ({
  run,
  props,
}) => {
  const [inc, setInc] = useState(1);
  const debouncedInc = useDebounce(inc, 250);
  // if(run){
    // setTimeout(() => {
    //   setInc(inc <= 12 ? inc + 1 : 1);
    // }, 500);
  // }

  useEffect(() => {
    if(run){
      setInc(debouncedInc < 12 ? debouncedInc + 1 : 1);
    }
  }, [run, debouncedInc, setInc]);

  return (
    React.createElement(Components[inc], { ...props })
  );
};

ClockIcon.propTypes = {};

export default ClockIcon;
