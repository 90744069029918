import React, { useEffect } from "react";
import { compose } from "redux";
import Helmet from "react-helmet";
import { connect, useSelector } from "react-redux";
import { Typography, Button, TextField } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import moment from "moment";
import { Autocomplete } from "@mui/material";

import ButtonDropdown from "common/ButtonDropdown";
import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportAgreements, clear } from "../../actions";
import Paper from "../Paper";
import { formatFilters, getUtcOffset } from "../../utility";
import { Actions, FilterMenu, ButtonGroup, ActionGroup } from "./Styled";
import {
  regionsSelectors,
  memberAffiliationsSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import constants from "components/constants";

const offSet = getUtcOffset(); // Remember, do not use this for times, (date only)

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  member_name: { minWidth: 450 },
  member_status_proper: { minWidth: 150 },
  office_id: { minWidth: 150 },
  member_address_line1: { minWidth: 300 },
  member_address_line2: { minWidth: 200 },
  member_city: { minWidth: 150 },
  member_state: { minWidth: 200 },
  member_postal_code: { minWidth: 200 },
  region: { minWidth: 150 },
  affiliation: { minWidth: 150 },
  _opening_date: { minWidth: 200 },
  _effective_date: { minWidth: 200 },
  _expiration_date: { minWidth: 200 },
  term_monthly_fee: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  member_name,
  member_status_proper,
  office_id,
  member_address_line1,
  member_address_line2,
  member_city,
  member_state,
  member_postal_code,
  region,
  affiliation,
  _opening_date,
  _effective_date,
  _expiration_date,
  term_monthly_fee,
  _paths
) => {
  return {
    member_name: member_name ? (
      <LinkHeader to={_paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    member_status_proper,
    office_id,
    member_address_line1,
    member_address_line2,
    member_city,
    member_state,
    member_postal_code,
    region,
    affiliation,
    _opening_date: _opening_date && (
      <div>
        <Typography variant="body1">
          {moment.unix(_opening_date).utcOffset(offSet).format("MMM Do, YYYY")}
        </Typography>
      </div>
    ),
    _effective_date: _effective_date && (
      <div>
        <Typography variant="body1">
          {moment
            .unix(_effective_date)
            .utcOffset(offSet)
            .format("MMM Do, YYYY")}
        </Typography>
      </div>
    ),
    _expiration_date: _expiration_date && (
      <div>
        <Typography variant="body1">
          {moment
            .unix(_expiration_date)
            .utcOffset(offSet)
            .format("MMM Do, YYYY")}
        </Typography>
      </div>
    ),
    term_monthly_fee,
  };
};

const initialFormData = {
  region_tid: [],
  affiliation_tid: [],
};

const ReportAgreements = ({
  report,
  clear,
  pagination,
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const regions = useSelector(regionsSelectors.selectAll);
  const affiliations = useSelector(memberAffiliationsSelectors.selectAll);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    await exportReport("/rest/report/member/agreements", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    // removeColumns(["zip_codes_owned"], temp);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.member_name,
        row.member_status_proper,
        row.office_id,
        row.member_address_line1,
        row.member_address_line2,
        row.member_city,
        row.member_state,
        row.member_postal_code,
        row.region,
        row.affiliation,
        row._opening_date,
        row._effective_date,
        row._expiration_date,
        row.term_monthly_fee,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData(initialFormData);
  };

  return (
    <div>
      <Helmet>
        <title>Agreements Report | Phoenix Solutions</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Agreements</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={regions.map((region) => ({
                    label: region.name,
                    value: region.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="HQ Region"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      autoComplete="off"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "region_tid");
                  }}
                  value={formData.region_tid}
                />
                <Autocomplete
                  options={affiliations.map((affiliation) => ({
                    label: affiliation.name,
                    value: affiliation.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Affiliations"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      autoComplete="off"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "affiliation_tid");
                  }}
                  value={formData.affiliation_tid}
                />
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="override_created"
          originalOrder="desc"
          search={searchTerm}
          pagination={pagination}
          size="small"
          filters={filters}
        />
      </Paper>
    </div>
  );
};

ReportAgreements.propTypes = {};

ReportAgreements.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  regions: state.app.regions.data,
  affiliations: state.app.affiliations.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportAgreements(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportAgreements);
