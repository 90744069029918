import { first, size } from "lodash";
import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getTaxonomyFields,
  getUserFields,
  getFileFields,
} from "../../utility";
import {
  GiWaterSplash,
  GiDamagedHouse,
  GiBroom,
  GiElectric,
  GiRolledCloth,
  GiWoodenCrate,
  GiHazmatSuit,
  GiTeePipe,
  GiMagnifyingGlass,
  GiBoxUnpacking,
  GiFruitTree,
} from "react-icons/gi";
import { AiTwotoneReconciliation, AiFillFormatPainter } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";
import { FaBiohazard, FaHouseDamage, FaDumpster, FaTruckMoving } from "react-icons/fa";
import { FcInspection, FcDam } from "react-icons/fc";
import { IoIosPeople } from "react-icons/io";
import { GiGrass } from "react-icons/gi";
import { GoPackage } from "react-icons/go";
import { GrDocumentTime, GrServices, GrTest } from "react-icons/gr";
import {
  MdRestorePage,
  MdDeck,
  MdCleaningServices,
  MdDesignServices,
  MdHandyman,
  MdHvac,
  MdThermostat,
  MdSecurity,
  MdHouseSiding,
} from "react-icons/md";
import { RiVirusFill, RiScan2Fill } from "react-icons/ri";
import { SiGoogleadsense } from "react-icons/si";
import { TbFence, TbWindow, TbVirusOff } from "react-icons/tb";
import { WiRaindrop } from "react-icons/wi";

export const getDivisionTypeIndicator = (type) => {
  const types = {
    "Asbestos Abatement": {
      icon: FaBiohazard,
      color: "#6e44ff",
    },
    "Biohazard Cleanup": {
      icon: FaBiohazard,
      color: "#52b788",
    },
    "Cleaning/Maid Service": {
      icon: MdCleaningServices,
      color: "#184e77",
    },
    Clerking: {
      icon: GrDocumentTime,
      color: "#184e77",
    },
    Consulting: {
      icon: IoIosPeople,
      color: "#184e77",
    },
    Contents: {
      icon: GoPackage,
      color: "#184e77",
    },
    "Debris Removal": {
      icon: GiDamagedHouse,
      color: "#680001",
    },
    Deck: {
      icon: MdDeck,
      color: "#52b788",
    },
    Designer: {
      icon: MdDesignServices,
      color: "#52b788",
    },
    "Document Restoration": {
      icon: MdRestorePage,
      color: "#184e77",
    },
    "Duct Cleaning": {
      icon: GiBroom,
      color: "#184e77",
    },
    Dumpster: {
      icon: FaDumpster,
      color: "#184e77",
    },
    "Electrical Restoration": {
      icon: GiElectric,
      color: "#184e77",
    },
    Electrician: {
      icon: GiElectric,
      color: "#184e77",
    },
    "Fabric Restoration": {
      icon: GiRolledCloth,
      color: "#184e77",
    },
    Fencing: {
      icon: TbFence,
      color: "#184e77",
    },
    Flooring: {
      icon: GiWoodenCrate,
      color: "#184e77",
    },
    "General Services": {
      icon: GrServices,
      color: "#184e77",
    },
    Gutters: {
      icon: WiRaindrop,
      color: "#184e77",
    },
    Handyman: {
      icon: MdHandyman,
      color: "#52b788",
    },
    Hazmat: {
      icon: GiHazmatSuit,
      color: "#52b788",
    },
    HVAC: {
      icon: MdHvac,
      color: "#52b788",
    },
    "Home Inspection": {
      icon: FcInspection,
      color: "#184e77",
    },
    Insulation: {
      icon: MdThermostat,
      color: "#184e77",
    },
    Landscaping: {
      icon: GiGrass,
      color: "#184e77",
    },
    "Lead Abatement": {
      icon: SiGoogleadsense,
      color: "#184e77",
    },
    "Mold": {
      icon: RiVirusFill,
      color: "#52b788",
    },
    "Mold Remediation": {
      icon: TbVirusOff,
      color: "#52b788",
    },
    "Mold Testing": {
      icon: GrTest,
      color: "#52b788",
    },
    "Moving Services": {
      icon: FaTruckMoving,
      color: "#52b788",
    },
    Painting: {
      icon: AiFillFormatPainter,
      color: "#184e77",
    },
    Plumbing: {
      icon: GiTeePipe,
      color: "#184e77",
    },
    "Pressure Washing": {
      icon: GiWaterSplash,
      color: "#184e77",
    },
    "Property Scan": {
      icon: RiScan2Fill,
      color: "#184e77",
    },
    Recon: {
      icon: AiTwotoneReconciliation,
      color: "#184e77",
    },
    Roofing: {
      icon: FaHouseDamage,
      color: "#184e77",
    },
    Security: {
      icon: MdSecurity,
      color: "#184e77",
    },
    Siding: {
      icon: MdHouseSiding,
      color: "#184e77",
    },
    "Site Inspection": {
      icon: GiMagnifyingGlass,
      color: "#184e77",
    },
    "Small Bio": {
      icon: FaBiohazard,
      color: "#52b788",
    },
    Starc: {
      icon: GiBoxUnpacking,
      color: "#52b788",
    },
    Steramist: {
      icon: RiVirusFill,
      color: "#52b788",
    },
    "Structural Cleaning": {
      icon: GiBroom,
      color: "#184e77",
    },
    "Temporary Services": {
      icon: BsClockHistory,
      color: "#184e77",
    },
    "Tree Removal": {
      icon: GiFruitTree,
      color: "#680001",
    },
    Water: {
      icon: GiWaterSplash,
      color: "#023e8a",
    },
    Waterproofing: {
      icon: FcDam,
      color: "#023e8a",
    },
    Windows: {
      icon: TbWindow,
      color: "#023e8a",
    },
  };


  if (!type || !types[type]){
    return {
      icon: GrDocumentTime,
      color: "#184e77",
    };
  }
  else{
    return types[type];
  }
};

export const formatJobFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/job",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_customer: null,
    field_customer_location: null,
    field_customer_name: formatField(d, "field_customer_name"),
    field_date: formatField(d, "field_date"),
    field_job_divisions: [],
    field_loss_category: formatField(d, "field_loss_category"),
    field_job_source: formatField(d, "field_job_source"),
    field_note: formatField(d, "field_note"),
    field_phoenix_client: first(
      d.field_phoenix_client.map((c) => formatClientFields(c))
    ),
    field_member_owned: d?.field_member_owned
      ? first(d.field_member_owned.map((m) => ({
          nid: formatField(m, "nid"),
          title: formatField(m, "title"),
        })))
      : {},
    field_phx_client_rep: first(getUserFields(d, "field_phx_client_rep")),
    field_reported_by: formatField(d, "field_reported_by"),
    field_mby_service_request_id: formatField(d, "field_mby_service_request_id"),
    _meta: d?._meta,
  };
};

export const formatCustomerFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/customer",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_customer_email: formatField(d, "field_customer_email"),
    field_phone: formatField(d, "field_phone"),
    field_billing_address: formatField(d, "field_billing_address"),
    field_customer_invoice_terms: formatField(
      d,
      "field_customer_invoice_terms"
    ),
    field_customer_status: formatField(d, "field_customer_status"),
    field_street_address: formatField(d, "field_street_address"),
    field_referred_by_text: formatField(d, "field_referred_by_text"),
    field_referred_by_cmpny: formatField(d, "field_referred_by_cmpny"),
    field_referred_by_phone: formatField(d, "field_referred_by_phone"),
    field_is_fm_pilot_client: formatField(d, "field_is_fm_pilot_client"),
    field_logo: first(getFileFields(d, "field_logo")),
    field_mem_job_status_override: formatFieldByValue(d, "field_mem_job_status_override", "target_id", true),
    field_res_com: formatField(d, "field_res_com"),
    _meta: d?._meta,
  };
};

export const formatLocationFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/customer_location",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_phone: formatField(d, "field_phone"),
    field_phone_after_hours: formatField(d, "field_phone_after_hours"),
    field_cl_email: formatField(d, "field_cl_email"),
    field_pref_contact_method: formatField(d, "field_pref_contact_method"),
    field_cl_year_built: formatField(d, "field_cl_year_built"),
    field_street_address: formatField(d, "field_street_address"),
    field_street_address_geo: d?.field_street_address_geo?.[0],
  };
};

export const formatDivisionFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/job_division",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_price_list: formatField(d, "field_price_list"),
    field_public_hash: formatField(d, "field_public_hash"),
    field_loss_date: formatField(d, "field_loss_date"),
    field_details: formatField(d, "field_details"),
    field_jd_eta_range: {
      start: d?.field_jd_eta_range?.[0]?.value,
      end: d?.field_jd_eta_range?.[0]?.end_value,
    },
    field_jd_inspection: formatField(d, "field_jd_inspection"),
    field_completion_date: formatField(d, "field_completion_date"),
    field_start_date: formatField(d, "field_start_date"),
    field_assigned_member: null,
    field_assigned_users: null,
    // field_audit_file_categories: [],
    field_daily_sheets: [],
    field_damage_areas: [],
    field_ds_custom_overrides: [],
    field_ds_cust_validation_toggle: formatField(
      d,
      "field_ds_cust_validation_toggle"
    ),
    field_eta_datetime: formatField(d, "field_eta_datetime"),
    field_file_fee: formatField(d, "field_file_fee"),
    field_jd_accepted: formatField(d, "field_jd_accepted"),
    field_job_division_status: formatField(d, "field_job_division_status"),
    field_jd_status: getTaxonomyFields(d, "field_jd_status"),
    field_job_division_type: getTaxonomyFields(d, "field_job_division_type"),
    field_last_updated: formatField(d, "field_last_updated"),
    field_last_updated_by: null,
    field_nte: formatField(d, "field_nte"),
    field_nte_total: formatField(d, "field_nte_total"),
    field_reference_num: formatField(d, "field_reference_num"),
    field_source_loss: getTaxonomyFields(d, "field_source_loss"),
    field_work_area_tax: formatField(d, "field_work_area_tax"),
    field_coordinator: d?.field_coordinator
      ? formatUserFields(first(d?.field_coordinator))
      : null,
    field_estimator: d?.field_estimator
      ? formatUserFields(first(d?.field_estimator))
      : null,
    field_jd_mf_caller: formatField(d, "field_jd_mf_caller"),
    field_jd_mf_caller_phone: formatField(d, "field_jd_mf_caller_phone"),
    field_cause_select: formatField(d, "field_cause_select"),
    field_cause: formatField(d, "field_cause"),
    field_run_time: formatField(d, "field_run_time"),
    field_mf_hw_wet: formatField(d, "field_mf_hw_wet"),
    field_mf_hw_wet_sqft: formatField(d, "field_mf_hw_wet_sqft"),
    field_mf_effected_level_qty: formatField(d, "field_mf_effected_level_qty"),
    field_hold_timestamp: formatField(d, "field_hold_timestamp"),
    field_hold_reason: formatField(d, "field_hold_reason"),
    field_hold_user: getUserFields(d, "field_hold_user"),
    field_property_scan: d?.field_property_scan?.[0]?.uri,
    field_fm_pilot_work_order_number: formatField(
      d,
      "field_fm_pilot_work_order_number"
    ),
    field_invoice_package_file_order:
      d && d.field_invoice_package_file_order
        ? d.field_invoice_package_file_order.map((t) => t.value)
        : [],
    field_file_followup_date: formatField(d, "field_file_followup_date"),
    field_tm_terms: JSON.parse(formatFieldByValue(
      d,
      "field_tm_terms",
      "value"
    )),
    field_jd_incident_date: formatField(d, "field_jd_incident_date"),
    field_jd_contacted_date: formatField(d, "field_jd_contacted_date"),
    field_jd_trgt_start_date: formatField(d, "field_jd_trgt_start_date"),
    field_jd_intro_prod_date: formatField(d, "field_jd_intro_prod_date"),
    field_jd_mjrty_completion_date: formatField(
      d,
      "field_jd_mjrty_completion_date"
    ),
    field_jd_closed: formatField(d, "field_jd_closed"),
    field_jd_work_complete: formatField(d, "field_jd_work_complete"),
    field_jd_personnel: d?.field_jd_personnel ? d?.field_jd_personnel : [],
    field_ds_override: (d?.field_ds_override ? d.field_ds_override.map((override) => override.value) : []),
    _processed: d._processed,
  };
};

export const formatMemberFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/member",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_affiliation: getTaxonomyFields(d, "field_affiliation"),
    field_allow_askaime_access: formatField(d, "field_allow_askaime_access"),
    field_billing_address: formatField(d, "field_billing_address"),
    field_fax: formatField(d, "field_fax"),
    field_hours: formatField(d, "field_hours"),
    field_office_id: formatField(d, "field_office_id"),
    field_phone: formatField(d, "field_phone"),
    field_phone_after_hours: formatField(d, "field_phone_after_hours"),
    field_region: getTaxonomyFields(d, "field_region"),
    field_status: formatField(d, "field_status"),
    field_logo: first(getFileFields(d, "field_logo")),
    field_street_address: formatField(d, "field_street_address"),
    field_primary_poc: d?.field_primary_poc
      ? d.field_primary_poc.map((poc) => formatUserFields(poc))
      : [],
    field_secondary_pocs: d?.field_secondary_pocs
      ? d.field_secondary_pocs.map((poc) => formatUserFields(poc))
      : [],
    field_after_hours_pocs: d?.field_after_hours_pocs
      ? d.field_after_hours_pocs.map((poc) => formatUserFields(poc))
      : [],
  };
};

export const formatUserFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "user/user",
    uid: formatField(d, "uid"),
    path: formatFieldByValue(d, "path", "alias"),
    status: formatField(d, "status"),
    field_first_name: formatField(d, "field_first_name"),
    field_last_name: formatField(d, "field_last_name"),
    field_title_position: formatField(d, "field_title_position"),
    field_phone: formatField(d, "field_phone"),
    field_cell: formatField(d, "field_cell"),
    name: formatField(d, "name"),
    profile_pic: d?._files?.profile_pic,
    _processed: (d?._processed ? {...d._processed} : {}),
  };
};

export const formatJobDivisions = (d) => {
  const member = {};
  if(size(d.field_assigned_member)){
    member.nid = formatField(d.field_assigned_member[0], "nid");
    member.title = formatField(d.field_assigned_member[0], "title");
  }
  return {
    self: getPathFromLinksSelf(d),
    type: "node/job_division",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    path: formatFieldByValue(d, "path", "alias"),
    member: member,
  };
};

export const formatInsuranceFields = (d) => {
  return {
    nid: formatField(d, "nid"),
    field_jdi_carrier: formatField(d, "field_jdi_carrier"),
    field_jdi_claim_number: formatField(d, "field_jdi_claim_number"),
    field_jdi_policy_number: formatField(d, "field_jdi_policy_number"),
    field_jdi_deductible: formatField(d, "field_jdi_deductible"),
    field_jdi_adjuster_email: formatField(d, "field_jdi_adjuster_email"),
    field_jdi_adjuster_fax: formatField(d, "field_jdi_adjuster_fax"),
    field_jdi_adjuster_name: formatField(d, "field_jdi_adjuster_name"),
    field_jdi_adjuster_phone: formatField(d, "field_jdi_adjuster_phone"),
    field_jdi_agent_email: formatField(d, "field_jdi_agent_email"),
    field_jdi_agent_fax: formatField(d, "field_jdi_agent_fax"),
    field_jdi_agent_name: formatField(d, "field_jdi_agent_name"),
    field_jdi_agent_phone: formatField(d, "field_jdi_agent_phone"),
  };
};

export const formatClientFields = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_pc_address: formatField(d, "field_pc_address"),
    // field_pc_default_member: formatField(d, "field_pc_default_member"),
    // field_pc_domain: formatField(d, "field_pc_domain"),
    field_pc_logo: formatField(d, "field_pc_logo"),
    field_pc_phone: formatField(d, "field_pc_phone"),
    field_pc_proper_name: formatField(d, "field_pc_proper_name"),
    _processed: d?._processed,
  };
};

export const formatOverrideLogFields = (d) => {
  return {
    field_approved_by: first(getUserFields(d, "field_approved_by")),
    field_override_reason: formatField(d, "field_override_reason"),
    field_override_type: formatField(d, "field_override_type"),
    id: formatField(d, "id"),
  };
};

export const formatMemberNameForProximity = (member) => {
  if (!member) return null;
  const { member_name, /*county_owned, county_served, affiliation,*/ proximity } =
    member;
  let memberName = `${member_name}${/*Number(county_owned) === 1 ? " *" :*/ ""} ${
    proximity ? `${Number(proximity).toFixed(2)}mi` : ""
  }`;
  // if (affiliation === "Affiliate") memberName = <em>{memberName}</em>;
  // if (Number(county_served) === 1) return <strong>{memberName}</strong>;

  return memberName;
};

export const formatMemberforDetail = (member) => {
  let primaryPOC = [];
  member.primary_poc_json.forEach((poc, i) => {
    const _path = (member._paths.primary_pocs ? member._paths.primary_pocs[i] : '');
    primaryPOC.push({
      self: _path,
      ...poc
    });
  });
  let secondaryPOC = [];
  member.secondary_pocs_json.forEach((poc, i) => {
    const _path = (member._paths.secondary_pocs ? member._paths.secondary_pocs[i] : '');
    secondaryPOC.push({
      self: _path,
      ...poc
    });
  });
  let afterHoursPOC = [];
  member.after_hours_pocs_json.forEach((poc, i) => {
    const _path = (member._paths.after_hours_pocs ? member._paths.after_hours_pocs[i] : '');
    afterHoursPOC.push({
      self: _path,
      ...poc
    });
  });
  return {
    self: member._paths.member,
    title: formatMemberNameForProximity(member),
    field_street_address: {
      address_line1: member.address_line1,
      address_line2: member.address_line2,
      postal_code: member.postal_code,
      locality: member.city,
      administrative_area: member.state,
      country_code: member.country,
    },
    field_phone: member.phone,
    field_phone_after_hours: member.after_hours_phone,
    field_primary_poc: primaryPOC,
    field_secondary_pocs: secondaryPOC,
    field_after_hours_pocs: afterHoursPOC,
  };
};

/**
 *  Replace the client index label with the client title
 *  - Parameter to include a client option is missing
 *
 * @param array jobSources
 * @param object client
 * @param bool includeClientOption
 * @returns
 */
export const parseJobSource = (jobSources, client, includeClientOption) => {
  let newSources = (jobSources ? jobSources : []);
  if(size(newSources.filter((source) => source.value === 'client')) === 0 && includeClientOption){
    newSources = [...newSources, {
      value: 'client',
      label: 'Client',
    }];
  }
  if(!newSources || size(newSources) === 0) return [{
    value: '',
    label: '',
  }];
  return newSources.map((source) => {
    if(source?.value === 'client' || source?.machine_name === 'client'){
      return {
        ...source,
        value: (source?.value ? source.value : source.machine_name),
        label: (client /*&& client.title.indexOf('Helix') < 0*/ ? client?.title : source.label),
      };
    }
    return source;
  });
};
