import React from "react";
import PropTypes from "prop-types";

import { Container, Image, Body } from "./Styled";

const EmptyIcon = `${process.env.PUBLIC_URL}/images/icons/icon-noresults.svg`;

const Empty = ({ msg, fillBkg, large, action }) => {
  return (
    <Container fillBkg={fillBkg} large={large}>
      <Image src={EmptyIcon} alt="Empty" />
      <Body className="empty__body">{msg}</Body>
      {action && <div style={{ marginTop: "1.25rem" }}>{action}</div>}
    </Container>
  );
};

const { string, bool } = PropTypes;
Empty.propTypes = {
  msg: string,
  fillBkg: bool,
  large: bool,
};

Empty.defaultProps = {
  large: false,
};

export default Empty;
