import React from "react";
import { Avatar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { Container } from "./Styled";

const useStyles = makeStyles((theme) => ({
  small: { width: "30px", height: "30px", fontSize: "0.875rem" },
  default: {},
  large: { width: "100px", height: "100px", fontSize: "2rem" },
}));

const AvatarGroup = ({ children, max, onClick, size, type }) => {
  const classes = useStyles();
  const numChildren = children.length + 1;
  const numLeft = children.length - max;
  return (
    <Container onClick={onClick}>
      {React.Children.map(children.slice(0, max), (child, index) =>
        React.cloneElement(child, {
          style: { ...child.props.style, zIndex: numChildren - index },
        })
      )}
      {numLeft > 0 && <Avatar className={classes[size]} type={type}>+{numLeft}</Avatar>}
    </Container>
  );
};

AvatarGroup.propTypes = {};

AvatarGroup.defaultProps = {
  max: 5,
  size: "default",
  type: "user",
};

export default AvatarGroup;
