import React from "react";
import { connect, useSelector } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FilterList } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { GetApp } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";

import Alert from "common/Alert";
import ButtonDropdown from "common/ButtonDropdown";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, clear } from "../../actions";
import { getRegions } from "../../actions/taxonomies";
import Paper from "../Paper";
import {
  Actions,
  ActionGroup,
  FilterMenu,
  ButtonGroup,
} from "./Styled";
import { formatFilters, removeColumns } from "../../utility";
import {
  statesSelectors,
  memberStatusSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import { getReportMemberAssignmentAppearance } from "actions/reports";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  member_name: { minWidth: 400 },
  appearance_total: { minWidth: 200},
  appearance_selected_total: { minWidth: 200},
  appearance_not_selected_total: { minWidth: 200},
  appearance_avg_rank: { minWidth: 200},
  appearance_avg_proximity: { minWidth: 200},
  status_proper: { minWidth: 150 },
  city: { minWidth: 150 },
  state: { minWidth: 150 },
  postal_code: { minWidth: 150 },
  address_line1: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  member_name,
  appearance_total,
  appearance_selected_total,
  appearance_not_selected_total,
  appearance_avg_rank,
  appearance_avg_proximity,
  appearance_first_time,
  appearance_last_time,
  status_proper,
  city,
  state,
  postal_code,
  address_line1,
  paths
) => {
  return {
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    appearance_total,
    appearance_selected_total,
    appearance_not_selected_total,
    appearance_avg_rank,
    appearance_avg_proximity,
    status_proper,
    city,
    state,
    postal_code,
    address_line1,
  };
};

const initialFormData = {
  status: [],
  state: [],
  appearance_first_time: {
    type: "date-range-field-start",
    value: null,
  },
  appearance_last_time: {
    type: "date-range-field-end",
    value: null,
  },
};

const ReportMemberAssignmentAppearances = ({
  loadMembers,
  report,
  exportReport,
  clear,
  loading,
  pagination,
  error,
  org,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const states = useSelector(statesSelectors.selectAll);
  const statuses = useSelector(memberStatusSelectors.selectAll);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nids]": org,
      };
    }

    await exportReport("/rest/report/members", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(["other_member_proximities"], temp);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.member_name,
        row.appearance_total,
        row.appearance_selected_total,
        row.appearance_not_selected_total,
        row.appearance_avg_rank,
        row.appearance_avg_proximity,
        row.appearance_first_time,
        row.appearance_last_time,
        row.status_proper,
        row.city,
        row.state,
        row.postal_code,
        row.address_line1,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    // const dateValue = formData[name].value;
    // dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      appearance_first_time: {
        type: "date-range-field-start",
        value: null,
      },
      appearance_last_time: {
        type: "date-range-field-end",
        value: null,
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Member Assignment Appearance Report</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Members Assignment Appearance Report</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Range
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.appearance_first_time.value}
                        onChange={(value) =>
                          handleDateRangeChange(value, "appearance_first_time", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change start date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.appearance_last_time.value}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "appearance_last_time",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change start date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "state");
                  }}
                  value={formData.state}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.label,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <ButtonGroup>
                  <Button
                    color="default"
                    disableElevation
                    size="small"
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadMembers}
          originalOrderBy="member_name"
          originalOrder="asc"
          filters={filters}
          pagination={pagination}
          search={searchTerm}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportMemberAssignmentAppearances.propTypes = {};

ReportMemberAssignmentAppearances.defaultProps = {
  report: [],
  pagination: {
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  statuses: [],
  regions: [],
  affiliations: [],
  states: [],
};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  statuses: state.app.memberStatus.data,
  regions: state.app.regions.data,
  affiliations: state.app.affiliations.data,
  states: state.app.states.data,
  services: state.app.jobDivisionTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadMembers: (params) => dispatch(getReportMemberAssignmentAppearance(params)),
  loadRegions: () => dispatch(getRegions()),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMemberAssignmentAppearances);
