import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import constants from "components/constants";

import { getPercentage, getProgressColor } from "../../utility";
import "./Visualizations.scss";

const Container = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
`;

const RoundProgress = ({
  obtained,
  total,
  size,
  hideInner,
  invertColor,
  disableInnerCircle,
  ...props
}) => {
  const percentage = getPercentage(obtained, total);
  const color = invertColor
    ? getProgressColor(percentage, constants.colorRed, constants.colorGreen)
    : getProgressColor(percentage, constants.colorGreen, constants.colorRed);

  return (
    <Tooltip title={`${percentage}%`}>
      <Container size={size} {...props}>
        <svg
          viewBox="0 0 36 36"
          style={{
            display: "block",
            maxHeight: "250px",
          }}
        >
          <path
            style={{
              fill: "none",
              stroke: constants.colorGray,
              strokeWidth: "3.8",
            }}
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: "2.8",
              strokeLinecap: "round",
              animation: "progress 1s ease-out forwards",
            }}
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          {!disableInnerCircle && (
            <>
              <circle r={12} cx={18} cy={18} fill={constants.colorBlueDark} />
              <text
                x="18"
                y="23"
                style={{
                  fill: "white",
                  textAnchor: "middle",
                  fontSize: "1rem",
                  fontFamily: "Source Sans Pro",
                }}
              >
                %
              </text>
            </>
          )}
        </svg>
      </Container>
    </Tooltip>
  );
};

const { number } = PropTypes;
RoundProgress.propTypes = {
  obtained: number,
  total: number,
};

RoundProgress.defaultProps = {
  obtained: 0,
  total: 0,
  size: 36,
  hideInner: false,
  invertColor: false,
};

export default RoundProgress;
