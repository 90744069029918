import React, { useEffect } from "react";
import { Tabs } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { TabSkeleton } from "../Skeletons";
import constants from "../constants";
import LinkTab from "../LinkTab";
import Indicators from "common/Indicators";

const a11yProps = (index, identifier) => {
  return {
    id: `${identifier}-tab-${index}`,
    "aria-controls": `${identifier}-tab-panel-${index}`,
  };
};

const getCurrentPath = (baseUrl, pathname, routes) => {
  let arr = pathname.split("/");
  arr.shift();
  let hasRun = false;
  routes.forEach((route) => {
    const index = arr.indexOf(route.path);
    if (index >= 0) {
      arr.splice(index + 1, arr.length);
      hasRun = true;
    }
  });
  if(!hasRun){
    routes.forEach((route) => {
      if(route?.default){
        const paramArr = pathname.replace(baseUrl, '').split("/");
        paramArr.shift();
        if(paramArr.length > 0){
          const baseArr = baseUrl.split("/");
          baseArr.shift();
          arr = [
            ...baseArr,
            ...[route.path],
          ]
        }
        else if(route.path){
          arr.push(route.path);
        }
        hasRun = true;
      }
    });
  }

  if (hasRun) {
    return ["", ...arr].join("/");
  } else {
    return baseUrl;
  }
};

const filterOutRoutesByPermissons = (routes, permissions) => {
  if (permissions) {
    const data = [];
    for (let index in routes) {
      const path = routes[index].path;
      if (permissions[path] !== false) {
        data.push(routes[index]);
      }
    }

    return data;
  } else {
    return routes;
  }
};

const StyledTabs = withStyles((theme) => ({
  root: {
    padding: "1.25rem",
    backgroundColor: ({ fillBkg }) =>
      fillBkg ? constants.colorGrayLighter : "white",
    borderTop: ({ fillBkg }) =>
      fillBkg ? `1px solid ${constants.colorGray}` : "none",
    borderBottom: ({ fillBkg }) =>
      fillBkg ? `1px solid ${constants.colorGray}` : "none",

    "&.slim": {
      paddingBottom: "0",
    }
  },
  indicator: {
    // height: "29.5px",
    // zIndex: 0,
    // borderRadius: "3px",
    // backgroundColor: "white",
    // border: `1px solid ${theme.palette.secondary.main}`,
    display: "none",
  },
}))(({ fillBkg, ...rest }) => <Tabs {...rest} />);

const RouteTabs = ({
  url,
  pathname,
  routes,
  ariaLabel,
  nested,
  permissions,
  fillBkg,
  orientation,
  justify,
  loading,
  textColor,
  className,
  ...props
}) => {
  const [value, setValue] = React.useState(
    getCurrentPath(url, pathname, routes)
  );

  useEffect(() => {
    setValue(getCurrentPath(url, pathname, routes));
  }, [url, pathname, routes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const authRoutes = filterOutRoutesByPermissons(routes, permissions);

  let pathValue = null;
  authRoutes.forEach((route) => {
    if(value.indexOf(route.path) >= 0) {
      pathValue = value
    }
  });

  const _color = textColor ? textColor : "inherit";
  const indicatorColor = textColor ? textColor : "primary";
  const wrapperClasses = (className ? className : '') + ' wrapper-color-' + _color;
  const tabClasses = 'tab-color-' + _color;

  return loading ? (
    <div style={{ padding: "0 1.25rem" }}>
      <TabSkeleton />
    </div>
  ) : nested ? (
    <StyledTabs
      value={pathValue}
      onChange={handleChange}
      indicatorColor={indicatorColor}
      textColor={_color}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      aria-label={ariaLabel}
      fillBkg={fillBkg}
      className={wrapperClasses}
      sx={{
        '& .MuiTabs-flexContainer': {
          justifyContent: (justify ? justify : "space-between")
        }
      }}
      {...props}
    >
      {authRoutes.map((route, index) => (

          <LinkTab
            key={route.path}
            value={`${url}${route.path ? `/${route.path}` : ""}`}
            label={
              <Indicators marginRightStart="-0.4" {...route?.indicatorInfo}>
                {route.label}
              </Indicators>
            }
            // tooltip={route?.tooltip} {# Can't get it to work #}
            to={`${url}${route.path ? `/${route.path}` : ""}`}
            style={route.style}
            className={`${tabClasses}${(justify && justify.includes('flex-') ? 'mx-2' : '')}`}
            // startIcon={route.startIcon}
            nested
            {...a11yProps(index, route.identifier)}
          />

      ))}
    </StyledTabs>
  ) : (
    <Tabs
      value={(value ? value : 0)}
      onChange={handleChange}
      indicatorColor={indicatorColor}
      textColor={_color}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      aria-label={ariaLabel}
      orientation={orientation}
      className={props?.className + " " + wrapperClasses}
      sx={{
        '& .MuiTabs-flexContainer': {
          justifyContent: (justify ? justify : "space-between")
        }
      }}
      {...props}
    >
      {authRoutes.map((route, index) => (
          <LinkTab
            key={route.path}
            value={`${url}${route.path ? `/${route.path}` : ""}`}
            className={tabClasses}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Indicators red={route.badge} {...route?.indicatorInfo}>
                  {route.label}
                </Indicators>
                {route.badge ? (
                  <div
                    style={{
                      display: "flex",
                      background: constants.colorPrimary,
                      borderRadius: "50%",
                      color: "white",
                      height: "18px",
                      minWidth: "18px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "12px",
                      lineHeight: "1",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {route.badge}
                  </div>
                ) : null}
              </div>
            }
            to={`${url}${route.path ? `/${route.path}` : ""}`}
            {...a11yProps(index, route.identifier)}
          />
      ))}
    </Tabs>
  );
};

RouteTabs.propTypes = {};

RouteTabs.defaultProps = {
  orientation: "horizontal",
};

export default RouteTabs;
