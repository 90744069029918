import React from "react";
import { Typography } from "@mui/material";
import { HorizontalBar } from "react-chartjs-2";
import chroma from "chroma-js";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import constants from "components/constants";

import { ChartSkeleton } from "../Skeletons";
import { Loader } from "./Styled";
const analyticsIcon = `${process.env.PUBLIC_URL}/images/icons/icon-analytics--large.svg`;

const createGraphOptions = (nte) => ({
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: constants.colorGrayLightest,
    titleFontColor: constants.colorGrayDarker,
    bodyFontColor: constants.colorGrayDark,
    bodySpacing: 4,
    xPadding: 12,
    mode: "point",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function (tooltipItems, data) {
        const value = Number(
          data.datasets[tooltipItems.datasetIndex].data[0]
        ).toFixed(2);
        const label = data.datasets[tooltipItems.datasetIndex].label;
        return `${label}: $${value}`;
      },
    },
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        display: false,
        gridLines: {
          drawBorder: false,
          color: constants.colorGrayMedium + "1a",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: constants.colorGrayMedium,
        },
        stacked: true,
      },
    ],

    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color : constants.colorBlueLight + "1a",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: constants.colorGrayMedium,
          suggestedMax: 200,
          callback: function (value, index, values) {
            return "$" + value;
          },
        },
        stacked: true,
      },
    ],
  },
  annotation: {
    annotations: [
      {
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: nte,
        borderColor: constants.colorOrange,
        borderDash: [2, 2],
        label: {
          content: "NTP",
          enabled: true,
          position: "top",
          xAdjust: 20,
          backgroundColor: constants.colorGrayLightest,
          fontColor: constants.colorGrayDark,
          cornerRadius: 2,
        },
      },
    ],
  },
});

const StackedHorizBar = ({ graphData, nte, loading }) => {
  const options = createGraphOptions(nte);

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const numObjects = graphData.labels.length;
    const colorStopOne = chroma
      .scale([constants.colorBlueDarker + "33", constants.colorOrange + "33"])
      .colors(numObjects + 1);
    const colorStopTwo = chroma
      .scale([constants.colorBlueDarker + "1a", constants.colorOrange + "1a"])
      .colors(numObjects + 1);
    const borderColor = chroma
      .scale([constants.colorBlueAlt, constants.colorOrange])
      .colors(numObjects + 1);

    return {
      labels: ["Work Areas"],
      datasets: graphData.labels.map((label, i) => {
        const gradient = ctx.createLinearGradient(0, 230, 0, 50);
        gradient.addColorStop(1, colorStopOne[i]);
        gradient.addColorStop(0.8, colorStopTwo[i]);
        gradient.addColorStop(0, constants.colorBlue + "00");
        return {
          label: label,
          fill: true,
          backgroundColor: gradient,
          borderColor: borderColor[i],
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: [graphData.totals[i]],
          datalabels: {
            display: "auto",
            labels: {
              title: {
                color: constants.colorBlueDark,
                font: {
                  weight: "bold",
                },
              },
            },
            formatter: function (value, context) {
              if (graphData.labels.length > 5) return "";
              return label;
            },
          },
        };
      }),
    };
  };

  return loading ? (
    <Loader>
      <ChartSkeleton />
    </Loader>
  ) : !graphData.labels.length ? (
    <Loader>
      <img src={analyticsIcon} alt="No Data to display" />
      <Typography variant="subtitle1" color="textSecondary">
        No Data to Display
      </Typography>
    </Loader>
  ) : (
    <HorizontalBar data={data} options={options} />
  );
};

StackedHorizBar.propTypes = {};

export default StackedHorizBar;
