import { toArray } from "lodash";
import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  formatMoney,
  getUserFields,
} from "../../utility";

function formatResource(resources) {
  return resources.map((resource) => ({
    ...resource,
    type: "Equipment",
    category: "Equipment",
    real_category: resource.category,
    rate: `${resource.duration} / ${formatMoney(resource.price)}`,
    qty: resource.duration * resource.qty,
    summary: resource.name,
    user: resource.username,
  }));
}

function formatInvoiceItems(resources, services, expenses, separateCategories) {
  const r = resources ? toArray(resources) : [];
  const s = services ? toArray(services) : [];
  const e = expenses ? toArray(expenses) : [];
  const m = separateCategories ? toArray(separateCategories) : [];

  let combinedResources = [];
  r.forEach((date) => {
    const arr = toArray(date);
    combinedResources = [...combinedResources, ...arr];
  });

  let combinedServices = [];
  s.forEach((date) => {
    const arr = toArray(date);
    combinedServices = [...combinedServices, ...arr];
  });

  let combinedExpenses = [];
  e.forEach((date) => {
    const arr = toArray(date);
    combinedExpenses = [...combinedExpenses, ...arr];
  });

  let combinedCategories = [];
  m.forEach((category) => {
    Object.entries(category.nodes).forEach((arr) => {
      arr[1].children.forEach((child) => {
        combinedCategories.push({
          ...child,
          nid: arr[0],
          date: arr[1].date,
          rate: `${child.duration} / ${formatMoney(child.price)}`,
          summary: child.name,
          taxable: arr[1].taxable,
          user: arr[1].user,
        });
      });
    });
  });

  return [
    ...formatResource(combinedResources),
    ...combinedServices,
    ...combinedExpenses,
    ...combinedCategories,
  ];
}

// const categories = {
//   ds_equipment: "Equipment",
//   ds_service: "Labor",
//   ds_expense: "Expense",
// };

// const formatInvoiceSheetReferences = (d) => {
//   const data = [];
//   if (size(d) > 0) {
//     d.forEach((sheet) => {
//       data.push({
//         daily_sheet_nid: formatField(sheet, "nid"),
//         ds_user_full_name: formatField(sheet, "ds_user_full_name"),
//         date: moment(formatField(sheet, "field_ds_date")).format("X"),
//         category: categories[formatField(sheet, "type")],
//         summary: formatField(sheet, "summary"),
//         total: formatField(sheet, "field_ds_total"),
//       });
//     });
//   }

//   return data;
// };

export const formatInvoice = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "ds_expense",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_invoice_subtotal: formatField(d, "field_invoice_subtotal"),
    field_invoice_bill_to: formatField(d, "field_invoice_bill_to"),
    field_invoice_total_discount: formatField(
      d,
      "field_invoice_total_discount"
    ),
    field_invoice_total_tax: formatField(d, "field_invoice_total_tax"),
    field_invoice_total: formatField(d, "field_invoice_total"),
    field_invoice_job_division: d.field_invoice_job_division.map((div) => div.target_id),
    field_invoicing_entity: formatField(d, "field_invoicing_entity"),
    field_invoice_date_start: formatField(d, "field_invoice_date_start"),
    field_invoice_date_through: formatField(d, "field_invoice_date_through"),
    field_invoice_status: formatField(d, "field_invoice_status"),
    field_invoice_paid_status: formatField(d, "field_invoice_paid_status"),
    field_invoice_paid_amount: formatField(d, "field_invoice_paid_amount"),
    field_invoice_tax_rate: formatField(d, "field_invoice_tax_rate"),
    field_invoice_in_reference_to: formatField(
      d,
      "field_invoice_in_reference_to"
    ),
    field_invoice_terms: formatField(d, "field_invoice_terms"),
    field_invoice_discount_amount: formatField(
      d,
      "field_invoice_discount_amount"
    ),
    field_invoice_discount_percent: formatField(
      d,
      "field_invoice_discount_percent"
    ),
    field_invoice_notes: formatField(d, "field_invoice_notes"),
    field_invoice_date: formatField(d, "field_invoice_date"),
    field_invoice_due_date: formatField(d, "field_invoice_due_date"),
    // field_invoice_items_ent_ref: formatInvoiceSheetReferences(
    //   d?.field_invoice_items_ent_ref
    // ),
    field_invoice_include_receipts: formatField(
      d,
      "field_invoice_include_receipts"
    ),
    field_invoice_discount: formatField(d, "field_invoice_discount"),
    field_quickbooks_invoice_id: formatField(d, "field_quickbooks_invoice_id"),
    field_void_reason: formatField(d, "field_void_reason"),
    field_void_timestamp: formatField(d, "field_void_timestamp"),
    field_discount_order: formatField(d, "field_discount_order"),
    field_pl_update_review: formatField(d, "field_pl_update_review"),
    field_void_user: getUserFields(d, "field_void_user"),
    _processed: d?._processed,
    invoiceItems: d._processed
      ? formatInvoiceItems(
          d._processed.resources,
          d._processed.service_items,
          d._processed.expense_items,
          d._processed.separate_categories
        )
      : [],
  };
};
