import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Ri24HoursLine } from "react-icons/ri";
import { MdArrowDropDown } from "react-icons/md";
import styled from "styled-components";
import { size } from "lodash";

import { getTodaysHours, getHours } from "./utils";
import IconTextItem from "@phx/common/IconTextItem";

const Styles = styled.div``;

const Hours = ({ data }) => {
  const [showHoursDetails, setShowHoursDetails] = useState(false);
  const { today, time } = getTodaysHours(data);
  const hours = getHours(data);

  const hourListing = hours.reduce((listing, hour) => {
    if(hour.hours){
      listing.push(`${hour.day}: ${hour.hours}`);
    }
    return listing;
  }, []);

  return time && (
    <Styles>
      <IconTextItem icon={<Ri24HoursLine />}>
        <Typography
          variant="subtitle2"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          Hours
        </Typography>
        {showHoursDetails ? (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => setShowHoursDetails(false)}
          >
            {size(hourListing) > 0 ? (
              hourListing.map((text, key) => (
                <Typography
                  variant="body1"
                  style={{ textTransform: "capitalize" }}
                  key={key}
                >{text}</Typography>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                Hours have not been set
              </Typography>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setShowHoursDetails(true)}
          >
            <Typography
              variant="body1"
              style={{ textTransform: "capitalize" }}
            >{`${today}: ${time}`}</Typography>
            <MdArrowDropDown />
          </div>
        )}
      </IconTextItem>
    </Styles>
  );
};

Hours.propTypes = {};

export default Hours;
