import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch, } from "react-router-dom";
import { Typography,
  Divider,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Button from "common/Button";
import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import {
  getIsAuthenticatedSelector,
} from "features/Auth/authSlice";
import { TextFormField, SelectFormField } from "common/Fields";
import { size, first } from "lodash";
import { ClockIcon } from "common/ClockIcon";
import Indicators from "common/Indicators";
import { BsEnvelope } from "react-icons/bs";
import { validateEmail } from "utility";
import { setIgnoreTempUserEmail, removeTempUserFromIgnoreList, ignoreTempUserEmail } from "../utils";

const TimeClockUser = ({timeClock, isSubmitting, setFieldValue, values, submitForm, timeCardOnly, message, errors}) => {
  const { url } = useRouteMatch();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const [switching, setSwitching] = useState(false);
  const memberUsersOptions = [];
  const memberUsers = [];

  let openDefault = false;
  if(!openDefault){
    openDefault = Boolean(timeClock?.temp_user_email_needed) && !ignoreTempUserEmail(timeClock)
                 && !Boolean(timeClock?.service_activity_needed) && !Boolean(timeClock?.labor_type_needed);
  }
  if(!Boolean(timeClock?.temp_user_email_needed)){
    removeTempUserFromIgnoreList(timeClock);
  }
  const [open, setOpen] = React.useState(openDefault);

//  Turn-off the switching menu when done submitting
  useEffect(() => {
    if(!isSubmitting && switching){
      setSwitching(false);
    }
  }, [isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  if(size(timeClock.member_users)){
    if(size(timeClock.member_users?.accounts)){
      memberUsers.push(<li key="member_users" className="sub-heading">Member Users</li>);
      timeClock.member_users.accounts.forEach((user) => {
        const id = 'uid-' + user.uid;
        memberUsersOptions.push({
          value: id,
          label: user.name,
        });
        memberUsers.push(<MenuItem key={id} value={id}>
          {user.name}
        </MenuItem>);
      });
    }
    if(size(timeClock.member_users?.temp_users)){
      memberUsers.push(<Divider key="divider"/>)
      memberUsers.push(<li key="temp_users" className="sub-heading">Offline/Temporary Users</li>);
      timeClock.member_users.temp_users.forEach((user) => {
        const id = 'nid-' + user.nid;
        memberUsersOptions.push({
          value: id,
          label: user.name,
        });
        memberUsers.push(<MenuItem key={id} value={id}>
          {user.name}
        </MenuItem>);
      });
    }
  }

  const handleUserChange = (e) => {
    const _user = timeClock.user_default_service_types.find((user) =>
      user.name === e.target.value
      || (user?.id && user.id === e.target.value)
    );
    let _type = '';
    if(_user && (!values.service_type || values.service_type === '')){
      _type = first(_user.service_types);
    }
    setFieldValue("service_type", _type);
  }

  return (
    <>
    <div className={`user-select ${timeCardOnly ? 'bg-banner-black' : ''}`}>
      {(timeClock?.temp_user_name || timeClock?.user_name) ? (
        <div className="heading">
          <div className="clock-icon">
            <ClockIcon
              run={timeClock.clock_status === 'clocked_in'}
            />
          </div>
          <Typography variant="h6" className="user-name">
            {values.temp_user_name ? (
              timeClock?.temp_user_email_needed ? (
                <Indicators orange={true} marginRightStart="-0.5">
                  <Tooltip title="Enter an email to receive alerts and reminders">
                    <span className="set-email" onClick={() => setOpen(true) }>
                      {values.temp_user_name}
                    </span>
                  </Tooltip>
                </Indicators>
              ) : (
                values.temp_user_name
              )
            ) : (
              timeClock.user_name
            )}
          </Typography>
        </div>
      ) : (
        (!isAuthenticated /*&& !switching*/) ? (
          <>
          <TextFormField
            fullWidth
            htmlFor="temp_user_name"
            name="temp_user_name"
            label="Your Name"
            variant="filled"
            size="small"
            disabled={isSubmitting}
            onChange={handleUserChange}
          />
          <div className="login-button">
            <div className="or">or</div>
            <Button
              variant="contained"
              size="small"
              component={Link}
              to={
                {
                  pathname: '/login',
                  state: { from: url },
                }
              }
              // startIcon={<Edit />}
              color="primary"
              disabled={isSubmitting}
            >
              Log into your Helix Account
            </Button>
          </div>
          </>
        ) : (
          <div className="change-user-wrapper">
            <TextFormField
              fullWidth
              htmlFor="temp_user_name"
              name="temp_user_name"
              label="Temporary/Offline User Name"
              variant="filled"
              size="small"
              disabled={isSubmitting}
              onChange={handleUserChange}
            />
            <div className="login-button">
              <div className="or">or</div>
              <SelectFormField
                fullWidth
                name="selected_user"
                label="Select an Existing User"
                margin="normal"
                // required
                disabled={isSubmitting}
                options={memberUsersOptions}
                nullable
                handleChange={handleUserChange}
              >
                {memberUsers}
              </SelectFormField>
            </div>
          </div>
        )
      )}
    </div>
    <Dialog
      open={open}
      // onClose={onClose}
      aria-labelledby="time-clock-temp-user-email-dialog-title"
      aria-describedby="time-clock-temp-user-email-dialog-description"
      fullWidth
      maxWidth="xs"
      className="time-clock time-clock-dialog"
    >
      <DialogTitle id="time-clock-temp-user-email-dialog-title" className="dialog-title"><BsEnvelope className="edit-icon large" /> Please enter an Email to receive alerts and reminders</DialogTitle>
      <DialogContent>
        {message && <Alert kind={message.id}>{message.msg}</Alert>}
        <div id="time-clock-temp-user-email-dialog-description" className="dialog-content">
          <TextFormField
            type="email"
            fullWidth
            htmlFor="temp_user_email"
            name="temp_user_email"
            label="Email"
            variant="filled"
            size="small"
            disabled={isSubmitting}
            required={true}
            error={errors?.temp_user_email}
            inputProps={{ maxLength: 150 }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIgnoreTempUserEmail(timeClock);
            setOpen(false);
          }}
          disabled={isSubmitting}
        >
          Not Now
        </Button>
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          disableElevation
          size="small"
          onClick={() => {
            submitForm('temp_user_email');
            setOpen(false);
          }}
        >
          Submit
        </ButtonLoader>
      </DialogActions>
    </Dialog>
    </>
  );
};

TimeClockUser.propTypes = {};

export default TimeClockUser;


export const timeClockValidateUser = (errors, values, setMessage, isAuthenticated) => {
  if(!isAuthenticated && (!values.temp_user_name || values.temp_user_name === '') && !values.select_time_card && !values.selecting_time_card){
    errors['temp_user_name'] = '';
    setMessage({
      id: "negative",
      msg: 'Please enter your name or Login',
    });
  }
  else if(isAuthenticated && (!values.temp_user_name || values.temp_user_name === '') && values.selected_user === ''){
    errors['temp_user_name'] = '';
    errors['selected_user'] = '';
    setMessage({
      id: "negative",
      msg: 'Please enter a Temporary/Offline user or select an existing user name',
    });
  }
  if(values.temp_user_email !== '' && !validateEmail(values.temp_user_email)){
    errors['temp_user_email'] = 'Please enter a valid email';
    setMessage({
      id: "negative",
      msg: errors['temp_user_email'],
    });
  }

  return errors;
}
