import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useLocation, useRouteMatch, Switch, Route } from "react-router-dom";

import RouteTabs from "../../RouteTabs";
import Calls from "./Calls";
import Tasks from "../Tasks";
import Emails from "./Emails";
import { getMemberNoteStatus } from "../../../actions/taxonomies";
import { memberNoteTypesSelectors, memberFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { MemberNotes, MemberDocs, } from "components/Member";

const ROUTES = [
  {
    label: "Calls",
    path: "calls",
    identifier: "contact-history-calls",
    default: true,
  },
  {
    label: "Emails",
    path: "emails",
    identifier: "contact-history-emails",
  },
  {
    label: "Tasks",
    path: "tasks",
    identifier: "contact-history-tasks",
  },
  {
    label: "Notes",
    path: "notes",
    identifier: "contact-history-notes",
  },
  {
    label: "Documents",
    path: "documents",
    identifier: "contact-history-documents",
  },
];

const CRM = ({
  memberFields,
  loadMemberNoteStatus,
  statuses,
  loadingStatus,
  media,
  roles,
  showSnackbar,
  processed,
  refreshMember,
  nested,
  shortList,
}) => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const types = useSelector(memberNoteTypesSelectors.selectAll);
  const categories = useSelector(memberFileCategoriesSelectors.selectAll);
  const { nid, links } = memberFields;

  useEffect(() => {
    loadMemberNoteStatus();
  }, [loadMemberNoteStatus]);

  return (
    <div>
      <RouteTabs
        nested={nested}
        pathname={pathname}
        url={url}
        routes={ROUTES}
        permissions={{}}
        // fillBkg={media.medium ? true : false}
        justify="flex-start"
        color={!nested ? "primaryInverse" : "primary"}
        textColor={!nested ? "primaryInverse" : null}
        className={!nested ? "bg-gradient-black" : null}
      />
      <Switch>
        <Route path={`${url}/emails`}>
          <Emails
            parentEntity={memberFields}
            shortList={shortList}
          />
        </Route>
        <Route path={`${url}/tasks`}>
          <Tasks parentEntity={memberFields} />
        </Route>
        <Route path={`${url}/notes`}>
          <MemberNotes
            memberLoading={false}
            nid={nid}
            showSnackbar={showSnackbar}
            media={media}
            roles={roles}
            typeTid={types.find(( type ) => type.name === 'CRM Note')?.tid}
            title="Notes"
            shortList={shortList}
          />
        </Route>
        <Route path={`${url}/documents`}>
          <MemberDocs
            loading={false}
            links={links}
            nid={nid}
            showSnackbar={showSnackbar}
            roles={roles}
            canAdd={processed?.user_add_access}
            reloadMember={refreshMember}
            categoryTid={categories.find(( type ) => type.name === 'General')?.tid}
          />
        </Route>
        <Route path={[`${url}`, `${url}/calls`]}>
          <Calls
            parentEntity={memberFields}
            statuses={statuses}
            loadingStatus={loadingStatus}
            types={types}
            shortList={shortList}
          />
        </Route>
      </Switch>
    </div>
  );
};

CRM.propTypes = {};

CRM.defaultProps = {
  statuses: [],
};

const mapStateToProps = (state) => ({
  statuses: state.app.memberNoteStatus.data,
  loadingStatus: state.app.memberNoteStatus.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadMemberNoteStatus: () => dispatch(getMemberNoteStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRM);
