import React from "react";
import { useSelector, connect } from "react-redux";
import {
  IconButton,
  Popover,
  MenuItem,
  Typography,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GetAppIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
import styled from "styled-components";
import moment from "moment";
import chroma from "chroma-js";

import { patchJobDivisionFile } from "../../actions/paragraphs";
import ConfirmationDialog from "../ConfirmationDialog";
import constants from "../constants";
import FileIcon from "../FileIcon";
import { Details, Heading, Subheading, Actions } from "./Styled";
import { downloadFile } from "../../actions/files/fileActions";
import { formatFileSize } from "../../utility";
import config from "../../config";

const highlightColor = chroma(constants.colorGreen).alpha(0.15);

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 1.25rem;
  border-bottom: ${({ theme }) =>
    theme.highlight
      ? `1px solid ${constants.colorGreen}`
      : `1px solid ${constants.colorGray}`};
  transition: background-color 0.25s;

  &:nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.highlight ? highlightColor : "white"};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) =>
      theme.highlight ? highlightColor : constants.colorGrayLightest};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.highlight ? highlightColor : constants.colorGrayLighter};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const ProfileFile = ({
  name,
  type,
  url,
  visibleTo,
  getFile,
  onEdit,
  updateFile,
  file,
  showSnackbar,
  onDelete,
  isSubmitting,
  editable,
  size,
  created,
  responseType,
  highlight,
  downloadToken,
  viewUrl,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const token = useSelector((state) => state.auth.token);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    setDownloading(true);
    await getFile(url, name, responseType, downloadToken);
    setDownloading(false);
  };

  // const handleDelete = async () => {
  //   setIsSubmitting(true);
  //   const params = {
  //     _links: {
  //       type: file._links.type,
  //     },
  //     status: [{ value: 0 }],
  //   };

  //   const response = await updateFile(formatField(file, "id"), params);
  //   setIsSubmitting(false);
  //   setOpenDelete(false);
  //   if (response.status == 200) {
  //     showSnackbar({
  //       msg: "File removed successfully.",
  //       kind: "positive",
  //     });
  //   } else {
  //     const errorMessage = formatError(response);
  //     const newMessage = formatErrorResponseString(errorMessage.msg);
  //     errorMessage.msg = newMessage;
  //     showSnackbar(errorMessage);
  //   }
  // };

  return (
    <Container theme={{ highlight }}>
      <FileIcon filename={name} />
      <Details>
        {visibleTo && (
          <Typography variant="caption" color="textSecondary">
            Visible to {visibleTo}
          </Typography>
        )}
        <Heading>{name}</Heading>
        {size || created ? (
          <Typography variant="caption" color="textSecondary">
            {size ? formatFileSize(size) : null}
            {size ? " | " : null}
            {created ? moment(created).format("MMM Do, YYYY h:mm A") : null}
          </Typography>
        ) : null}
        <Subheading>{type}</Subheading>
      </Details>
      <Actions>
        <IconButton onClick={handleClick} size="large">
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={`file-actions-menu`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            component="a"
            target="_blank"
            href={`${config.api_url}${viewUrl}?token=${downloadToken}&oauth_token=${token}`}
          >
            <ListItemIcon>
              <LaunchIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">View</Typography>
          </MenuItem>
          <MenuItem onClick={handleDownload}>
            <ListItemIcon>
              {downloading ? (
                <CircularProgress size={14} style={{ marginLeft: "3px" }} />
              ) : (
                <GetAppIcon fontSize="small" />
              )}
            </ListItemIcon>
            <Typography variant="inherit">Download</Typography>
          </MenuItem>
          {editable && (
            <MenuItem onClick={onEdit}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Edit</Typography>
            </MenuItem>
          )}
          {onDelete && (
            <MenuItem onClick={() => setOpenDelete(true)}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Delete</Typography>
            </MenuItem>
          )}
        </Popover>
      </Actions>
      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onSubmit={() => onDelete(setOpenDelete)}
        title="Are you sure?"
        body="Are you sure you want to delete this item?"
        isSubmitting={isSubmitting}
        submitButton="Delete"
      />
    </Container>
  );
};

ProfileFile.propTypes = {};

ProfileFile.defaultProps = {
  editable: true,
  highlight: false,
};

const mapDispatchToProps = (dispatch) => ({
  getFile: (path, name, responseType, token) =>
    dispatch(downloadFile(path, name, responseType, token)),
  updateFile: (id, params) => dispatch(patchJobDivisionFile(id, params)),
});

export default connect(null, mapDispatchToProps)(ProfileFile);
